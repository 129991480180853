/* eslint-disable import/prefer-default-export */
export const COOKIES = [
  '_ga',
  '_gid',
  '_gat',
  '__utma',
  '__utmt',
  '__utmb',
  '__utmc',
  '__utmz',
  '__utmv',
  'gtm_debug',
  'gtm_auth',
  'ANID',
  'SEARCH_SAMESITE',
  'S',
  '__Secure-',
  'SAPISID',
  'SSID',
  'APISID',
  'HSID',
  'SIDCC',
  'SID',
  '1P_JAR',
  'NID',
];

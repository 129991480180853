import GTM from '@utils/gtm';
import type {
  ShopifyCart,
  ShopifyLineEdges,
  ItemBody,
} from '@framework/api/cart';
import type { GaCartItem } from './types';
import type { CartSource } from '../../amplitude/types';

type TrackAddToCartArgs = {
  cart: ShopifyCart;
  remove?: boolean;
  removeId?: string | string[];
  addedIds?: ItemBody[];
  lastCart?: ShopifyCart;
  source?: CartSource;
};

type CreateProductsArgs = {
  products: ShopifyLineEdges[];
};

export const createProducts = ({
  products,
}: CreateProductsArgs): GaCartItem[] => {
  const productArray = products.map((product, i) => {
    const regPrice = parseFloat(product?.node?.merchandise?.priceV2?.amount);
    const discount = parseFloat(
      product?.node?.discountAllocations[0]?.discountedAmount?.amount ?? '0'
    );

    const productQuant = product?.node.quantity || 1;
    const discountPrice = regPrice - discount / productQuant;

    const gaItem: GaCartItem = {
      item_id: product?.node.merchandise.sku,
      item_name: product?.node.merchandise.product.title,
      discount,
      index: i,
      item_brand: product?.node.merchandise.product.vendor,
      item_category: product?.node.merchandise.product.productType,
      item_variant: product?.node.merchandise.title,
      price: discountPrice,
      quantity: product?.node.quantity,
    };

    return gaItem;
  });

  return productArray.filter(Boolean);
};

const trackAddToCart = ({
  cart,
  remove = false,
  removeId,
  addedIds,
  lastCart,
}: TrackAddToCartArgs): void => {
  if (cart) {
    let items = createProducts({
      products: cart.lines.edges,
    });

    let total = parseFloat(cart.estimatedCost.subtotalAmount.amount);

    if (addedIds) {
      const ids = addedIds.map(
        i => `gid://shopify/ProductVariant/${i.shopify_variants_id}`
      );

      const addedLines = cart.lines.edges.filter(e =>
        ids.includes(e.node.merchandise.id)
      );

      items = createProducts({
        products: addedLines,
      });

      total = addedLines.reduce((prev, curr) => {
        const regPrice = parseFloat(curr?.node?.merchandise?.priceV2?.amount);
        const discount = parseFloat(
          curr?.node?.discountAllocations[0]?.discountedAmount?.amount ?? '0'
        );
        const productQuant = curr?.node.quantity || 1;
        const discountPrice = regPrice - discount / productQuant;
        const lineTot = discountPrice * productQuant;

        return prev + lineTot;
      }, 0);
    }

    if (remove) {
      const removedItem = lastCart.lines.edges.find(
        e => e.node.id === removeId
      );

      items = createProducts({
        products: [removedItem],
      });

      const regPrice = parseFloat(
        removedItem?.node?.merchandise?.priceV2?.amount
      );
      const discount = parseFloat(
        removedItem?.node?.discountAllocations[0]?.discountedAmount?.amount ??
          '0'
      );
      const productQuant = removedItem?.node.quantity || 1;
      const discountPrice = regPrice - discount / productQuant;

      total = discountPrice * productQuant;
    }

    const event = remove ? 'remove_from_cart' : 'add_to_cart';

    GTM.dataLayer({
      dataLayer: {
        event,
        ecommerce: {
          currency: cart?.estimatedCost?.subtotalAmount?.currencyCode,
          value: total,
          items,
        },
      },
    });
  }
};

export default trackAddToCart;

// Route string
export const ROUTE_CATEGORY = 'category';
export const ROUTE_COLLECTION = 'collection';
export const ROUTE_PRODUCT = 'product';
export const ROUTE_ABOUT = 'about';
export const ROUTE_REFURBISHED = 'refurbished';
export const ROUTE_LEGAL = 'legal';
export const ROUTE_NEWS = 'news';
export const ROUTE_NEWS_CATEGORY = 'news/category';
export const ROUTE_SOFTWARE = 'software';
export const ROUTE_RETAILERS = 'retailers';
export const ROUTE_CAREERS = 'careers';
export const ROUTE_ROOT = '';
export const ROUTE_BUILD = 'build';
export const ROUTE_CART = 'cart';
export const ROUTE_BUILD_KEYBOARD = 'keyboard';
export const ROUTE_BUILD_PC = 'pc';
export const ROUTE_BUILD_SUMMARY = 'summary';
export const ROUTE_BUILD_LOCKED = 'locked';
export const ROUTE_ORDER_TRACKING = 'order-tracking';
export const ROUTE_SEARCH = 'search';
export const ROUTE_SUPPORT = 'support';
export const ROUTE_USER = 'user';
export const ROUTE_USER_SAVED_BLD = 'saved-bld';
export const ROUTE_REVIEWS = 'reviews';
export const ROUTE_HOME = 'home';
export const ROUTE_AUTH = 'auth';
export const ROUTE_CAM = 'cam';
export const ROUTE_NOTICE = 'notice';
export const ROUTE_PARTNER = 'partner';
export const ROUTE_SALE = 'sale';
export const ROUTE_PARTNER_PROGRAM = 'partner-program';
export const ROUTE_SUBSCRIPTION = 'flex';
export const ROUTE_LOCKED_BUILD = `${ROUTE_BUILD}/${ROUTE_BUILD_LOCKED}`;

// Content type/model string
export const CONTENT_TYPE_CATEGORY = 'category';
export const CONTENT_TYPE_COLLECTION = 'collection';
export const CONTENT_TYPE_PRODUCT = 'product';
export const CONTENT_TYPE_SUBSCRIPTION = 'subscription';
export const CONTENT_TYPE_PAGE = 'page';
export const CONTENT_TYPE_LEGAL = 'legal';
export const CONTENT_TYPE_LEGAL_HUB = 'legal_hub';
export const CONTENT_TYPE_NEWS = 'news';
export const CONTENT_TYPE_NEWS_CATEGORY = 'news_category';
export const CONTENT_TYPE_NOTICE = 'notice';

// Page types
export const PAGE_TYPE_ABOUT = 'about';
export const PAGE_TYPE_SOFTWARE = 'software';
export const PAGE_TYPE_RETAILERS = 'retailers';
export const PAGE_TYPE_CAREERS = 'careers';
export const PAGE_TYPE_ROOT = 'root';
export const PAGE_TYPE_BUILD = 'build';
export const PAGE_TYPE_SUPPORT = 'support';
export const PAGE_TYPE_USER = 'user';
export const PAGE_TYPE_NEWS = 'news';
export const PAGE_TYPE_HOME = 'home';
export const PAGE_TYPE_REFURBISHED = 'refurbished';
export const PAGE_TYPE_REVIEWS = 'reviews';
export const PAGE_TYPE_LEGAL = 'legal';
export const PAGE_TYPE_NOTICE = 'notice';
export const PAGE_TYPE_COLLECTION = 'collection';
export const PAGE_TYPE_PARTNER = 'partner';
export const PAGE_TYPE_SALE = 'sale';
export const PAGE_TYPE_PARTNER_PROGRAM = 'partner-program';
export const PAGE_TYPE_LOCKED_BUILD = 'lockedBuild';

// External Page
export const NZXT_S3_BUCKET = 'https://nzxt-app.nzxt.com';
export const CAM_DOWNLOAD_PATH = 'NZXT-CAM-Setup.exe';

export const CONTENT_TYPE_ROUTE_MAP = {
  [CONTENT_TYPE_CATEGORY]: ROUTE_CATEGORY,
  [CONTENT_TYPE_COLLECTION]: ROUTE_COLLECTION,
  [CONTENT_TYPE_PRODUCT]: ROUTE_PRODUCT,
  [CONTENT_TYPE_SUBSCRIPTION]: ROUTE_SUBSCRIPTION,
  rent: ROUTE_SUBSCRIPTION,
  [CONTENT_TYPE_NEWS]: ROUTE_NEWS,
  [CONTENT_TYPE_LEGAL]: ROUTE_LEGAL,
  [CONTENT_TYPE_LEGAL_HUB]: ROUTE_LEGAL,
  [CONTENT_TYPE_NOTICE]: ROUTE_NOTICE,
  [CONTENT_TYPE_NEWS_CATEGORY]: ROUTE_NEWS_CATEGORY,
  [PAGE_TYPE_ABOUT]: ROUTE_ABOUT,
  [PAGE_TYPE_HOME]: ROUTE_HOME,
  [PAGE_TYPE_SOFTWARE]: ROUTE_SOFTWARE,
  [PAGE_TYPE_RETAILERS]: ROUTE_RETAILERS,
  [PAGE_TYPE_BUILD]: ROUTE_BUILD,
  [PAGE_TYPE_SUPPORT]: ROUTE_SUPPORT,
  [PAGE_TYPE_USER]: ROUTE_USER,
  // [PAGE_TYPE_NEWS]: ROUTE_NEWS, // Duplicate of CONTENT_TYPE_NEWS
  [PAGE_TYPE_CAREERS]: ROUTE_CAREERS,
  [PAGE_TYPE_ROOT]: ROUTE_ROOT,
  [PAGE_TYPE_REFURBISHED]: ROUTE_REFURBISHED,
  [PAGE_TYPE_REVIEWS]: ROUTE_REVIEWS,
  /// [PAGE_TYPE_COLLECTION]: ROUTE_COLLECTION, // Duplicate of CONTENT_TYPE_COLLECTION
  [PAGE_TYPE_PARTNER]: ROUTE_PARTNER,
  [PAGE_TYPE_SALE]: ROUTE_SALE,
  [PAGE_TYPE_LOCKED_BUILD]: ROUTE_LOCKED_BUILD,
};

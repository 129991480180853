export const MAX_FPS = 130;
export const MIN_REVIEWS = 5;
export const DEFAULT_VARIANT_REGION = 'variantsNorthernamerica';
export const FPS_BUTTON_LABEL = [
  { label: '1080', value: '1080' },
  { label: '1440', value: '1440' },
];
export const FPS_BUTTON_LABEL4K = [
  { label: '1080', value: '1080' },
  { label: '1440', value: '1440' },
  { label: '4K', value: '4k' },
];
export const PRICE_OPTIONS_LABEL = [
  { label: '1000', value: '1000' },
  { label: '1500', value: '1500' },
  { label: '2000', value: '2000' },
  { label: '2500', value: '2500' },
];
export const PREBUILD_PARENT_CATS = [
  'prebuilt-hybrid',
  'prebuild',
  'prebuilds',
  'prebuilt',
  'prebuilts',
  'prebuild-hybrid',
];
export const HYBRID_CART_CATS = [
  ...PREBUILD_PARENT_CATS,
  'air',
  'services',
  'rgbs',
  'lighting',
  'extend service contract',
];

import {
  DEFAULT_LOCALE,
  MAXIFY_DEFAULT_REGION,
  MAXIFY_REGION_MAP,
} from '@constants';

export default function getRegion(locale: string): string {
  const parsedLocale = locale
    ? locale.split('-')[1]
    : DEFAULT_LOCALE.split('-')[1];

  return (
    MAXIFY_REGION_MAP[parsedLocale?.toUpperCase()] || MAXIFY_DEFAULT_REGION
  );
}

/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable prefer-destructuring */
// import { SCRIPT_SRC } from './constants';

// TODO - commenting out the lines below to allow for fbq to be started via GTM
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onAccept = (): void => {
  if (typeof window !== 'undefined') {
    // (function (f, b, e, v, n, t, s) {
    //   if (f.fbq) return;
    //   n = f.fbq = function () {
    //     n.callMethod
    //       ? n.callMethod.apply(n, arguments)
    //       : n.queue.push(arguments);
    //   };
    //   if (!f._fbq) f._fbq = n;
    //   n.push = n;
    //   n.loaded = !0;
    //   n.version = '2.0';
    //   n.queue = [];
    //   t = b.createElement(e);
    //   t.async = !0;
    //   t.src = v;
    //   s = b.getElementsByTagName(e)[0];
    //   s.parentNode.insertBefore(t, s);
    // })(window, document, 'script', SCRIPT_SRC);

    // window.fbq('init', facebookId);
    if (window.fbq) {
      window.fbq('track', 'PageView');
      window.fbq('consent', 'grant');
    }
  }
};

export default onAccept;

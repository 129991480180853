/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type { SWRResponse } from 'swr';
import type {
  HookInput,
  HookFetcher,
  HookFetcherOptions,
} from '../../utils/types';
import useData, { SwrOptions } from '../../utils/use-data-cart';

export type CartResponse<Result> = SWRResponse<Result, Error> & {
  isEmpty: boolean;
  count: number;
  isLoading?: boolean;
};

export type CartInput = {
  cartId: string | undefined;
};

export default function useCart<Result>(
  options: HookFetcherOptions,
  input: HookInput,
  fetcherFn: HookFetcher<Result, CartInput>,
  swrOptions?: SwrOptions<Result, CartInput>
) {
  const response = useData(options, input, fetcherFn, swrOptions);

  return Object.assign(response, {
    isEmpty: true,
    count: 0,
  }) as CartResponse<Result>;
}

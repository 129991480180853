import warn from './warn';

export type SnippetArgs = {
  id: string;
  events: Record<string, unknown>;
  serverUrl: string;
  dataLayer?: Record<string, unknown>;
  dataLayerName: string;
  preview: string;
  auth: string;
};

// Basic script/noscript implementation:
// https://developers.google.com/tag-manager/quickstart

const Snippets = {
  tags({
    id,
    events,
    serverUrl,
    dataLayer,
    dataLayerName,
    preview,
    auth,
  }: SnippetArgs): { iframe: string; script: string; dataLayerVar: string } {
    if (!id) {
      warn('GTM Id is required');
    }

    const gtmAuth = `&gtm_auth=${auth}`;
    const gtmPreview = `&gtm_preview=${preview}`;

    const iframe = `
      <iframe src="${serverUrl}/ns.html?id=${id}${gtmAuth}${gtmPreview}&gtm_cookies_win=x"
        height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;

    const script = `
      (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(
          events
        ).slice(1, -1)}});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='${serverUrl}/gtm.js?id='+i+dl+'${gtmAuth}${gtmPreview}&gtm_cookies_win=x';
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','${dataLayerName}','${id}');`;

    const dataLayerVar = this.dataLayer(dataLayer, dataLayerName);

    return {
      iframe,
      script,
      dataLayerVar,
    };
  },
  dataLayer(dataLayer: Record<string, unknown>, dataLayerName: string): string {
    return `
      window.${dataLayerName} = window.${dataLayerName} || [];
      window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`;
  },
};

export default Snippets;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */

import { getRegion } from '@framework/api/utils/maxify';
import { KLAVIYO_CREDS_REGION_MAP } from '@constants';
import { SCRIPT_SRC } from './constants';
import onLoad from './on-load';

const onAccept = (locale: string): void => {
  if (typeof window !== 'undefined') {
    const scriptEl = document.getElementById('dw-klaviyost');

    if (!scriptEl) {
      const region = getRegion(locale);
      const regionalApiKey = KLAVIYO_CREDS_REGION_MAP[region].companyId;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = SCRIPT_SRC + regionalApiKey;
      script.id = 'dw-klaviyost';
      script.onload = () => onLoad();

      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);
    }
  }
};

export default onAccept;

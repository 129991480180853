import { DEFAULT_COUNTRY_CODE } from '@constants';
import setShopifyCartCookie from './set-shopify-cart-cookie';

const fetchAndSetShopifyCartCookie = async (
  country: string = DEFAULT_COUNTRY_CODE
): Promise<void> => {
  if (typeof window !== 'undefined') {
    try {
      const cartId = await fetch(`/api/maxify/cart`, {
        method: 'PATCH',
      }).then(r => r.json());

      const { data } = cartId;
      setShopifyCartCookie(country, data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
};

export default fetchAndSetShopifyCartCookie;

/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment */
import { useEffect, FC } from 'react';
import { useRouter } from 'next/router';

type ThreadAiEventItem = {
  item_id: string;
  price?: number;
  currency?: string;
  discount?: number;
};

type ThreadAiEventProps =
  | {
      eventType: 'view_item';
      eventData: {
        items: ThreadAiEventItem[];
      };
    }
  | {
      eventType: 'begin_checkout';
      eventData: {
        cart_id?: string;
        items: ThreadAiEventItem[];
      };
    }
  | {
      eventType: 'purchase';
      eventData: {
        cart_id?: string;
        transaction_id: string;
        items: ThreadAiEventItem[];
      };
    }
  | {
      eventType: 'sign_up';
      eventData: {
        method?: string;
      };
    }
  | {
      eventType: 'login';
      eventData: {
        method?: string;
      };
    };

export const ThreadAiPixel: FC = () => {
  const router = useRouter();
  const { pathname, query } = router;

  const searchParams = query;

  useEffect(() => {
    (window as any)['TAI']('event', 'page_view');
  }, [pathname, searchParams]);

  return null;
};

export const ThreadAiEvent: FC = (props: ThreadAiEventProps) => {
  useEffect(() => {
    (window as any)['TAI']('event', props.eventType, props.eventData);
  }, []);

  return null;
};

export function clientThreadAiLibrary(...args: any[]): void {
  if (typeof window !== 'undefined') {
    (window as any)['TAI'](...args);
  }
}

import { FC, useState, useEffect, memo } from 'react';
import startCookieControl, {
  CookieControlOptions,
} from './start-cookie-control';

const CookieControl: FC<CookieControlOptions> = ({ ...rest }) => {
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    if (!hasInitialized) {
      startCookieControl(rest);

      setHasInitialized(true);
    }
  }, [hasInitialized, rest]);

  return null;
};

export default memo(CookieControl);

import create from 'zustand';
import { devtools } from 'zustand/middleware';
import type { SetState } from 'zustand';
import { setSessionStorage } from '@utils/sessionStorage';
import { ProductDataProps } from '@framework/api/types/build';
import type {
  State,
  BldOptions,
  BldPeripherals,
  BldHelpInfo,
  InfoModals,
} from './types';

const initialState = {
  bldOptions: {
    image: '',
    id: 0,
    name: '',
    description: '',
    short_description: '',
    price: 0,
    discountedPrice: 0,
    optionsData: [],
  },
  bldPeripherals: {
    monitors: [],
    lighting: [],
    extras: [],
    peripherals: [],
    colors: [],
    buildData: null,
    isInvalid: false,
  },
  bldEntryVariantId: null,
  bldMoreInfoVariantId: null,
  bldMoreInfoProductData: null,
  bldHelpInfo: {
    category: null,
  },
  infoModals: [],
  prevPath: null,
  currentPath: null,
  viewedSection: '',
};

const buildStore = (set: SetState<State>): State => ({
  ...initialState,
  // Build Actions
  setBLDOptions: (options: BldOptions) =>
    set(() => {
      const bldOptions = Array.isArray(options.optionsData)
        ? options
        : { ...options, optionsData: [options.optionsData] };

      return { bldOptions };
    }),
  setBldPeripherals: (bldPeripherals: BldPeripherals) =>
    set(() => ({ bldPeripherals })),
  setBLDEntryVariantId: (variantId: string | number) =>
    set(() => ({ bldEntryVariantId: variantId })),
  setBLDMoreInfoVariantId: (variantId: string | number) =>
    set(() => ({ bldMoreInfoVariantId: variantId })),
  setBLDMoreInfoProductData: (productData: ProductDataProps, isLockedItem) =>
    set(() => ({
      bldMoreInfoProductData: {
        productData,
        isLockedItem,
      },
    })),
  setBLDHelpInfo: (bldHelpInfo: BldHelpInfo) => set(() => ({ bldHelpInfo })),
  setInfoModals: (infoModals: InfoModals) => set(() => ({ infoModals })),
  setPrevPath: (pathname: string) =>
    set(() => {
      setSessionStorage('prevPath', pathname);
      return { prevPath: pathname };
    }),
  setCurrentPath: (pathname: string) =>
    set(() => {
      setSessionStorage('currentPath', pathname);
      return { currentPath: pathname };
    }),
  setViewedSection: (section: string) =>
    set(() => ({ viewedSection: section })),
});

const useBuildStore =
  process.env.NODE_ENV === 'development'
    ? create<State>(devtools(buildStore))
    : create<State>(buildStore);

export default useBuildStore;

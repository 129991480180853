import type { Geo } from '@utils/geoip-cookie';
import LOCATIONS_CONST from '../config/locations';

// TODO - clean up these placeholders, for the footer component
export const DEFAULT_COUNTRY_CODE = 'US';
export const CANADA_COUNTRY_CODE = 'CA';
export const GERMANY_COUNTRY_CODE = 'DE';
export const UNITED_KINGDOM_COUNTRY_CODE = 'GB';
export const BELGIUM_COUNTRY_CODE = 'BE';
export const AUSTRIA_COUNTRY_CODE = 'AT';
export const FRANCE_COUNTRY_CODE = 'FR';
export const ITALY_COUNTRY_CODE = 'IT';
export const LUXEMBOURG_COUNTRY_CODE = 'LU';
export const NETHERLANDS_COUNTRY_CODE = 'NL';
export const SPAIN_COUNTRY_CODE = 'ES';
export const AUSTRALIA_COUNTRY_CODE = 'AU';
export const NEW_ZEALAND_COUNTRY_CODE = 'NZ';
export const JAPAN_COUNTRY_CODE = 'JP';

// Default locale, needs to match default provided in next.config.js
export const DEFAULT_LOCALE = 'en-US';

// Supported locales. Must be kept in sync with locales in next.config.js
export const SUPPORTED_LOCALES = LOCATIONS_CONST;

// Locales used to fetch nav data for external consumption (Zendesk)
export const EXTERNAL_NAV_LOCALES = [
  'en-US',
  'de-US',
  'nl-US',
  'fr-US',
  'it-US',
  'en-ES',
  'en-GB', // TODO (@ravikadaboina) [2022-03-25]: Drop the static file in static bucket.
  'en-NZ',
  'es-US',
  ...(process.env.NEXT_PUBLIC_FEATURE_ENABLE_JAPAN === 'true' ? ['ja-JP'] : []),
];

// Region values accepted by Maxify, controls underlying Shopify instance
export const MAXIFY_REGION_NORTHERN_AMERICA = 'northernAmerica';
export const MAXIFY_REGION_EUROPE = 'europe';
export const MAXIFY_REGION_OCEANIA = 'oceania';
export const MAXIFY_REGION_UNITED_KINGDOM = 'unitedKingdom';
export const MAXIFY_REGION_NEW_ZEALAND = 'newZealand';
export const MAXIFY_REGION_ASIA = 'asia';
// NOTE - the below regions are not yet populated with data in Maxify
// export const REGION_CENTRAL_AMERICA = 'centralAmerica';
// export const REGION_SOUTH_AMERICA = 'southAmerica';
// export const REGION_AFRICA = 'africa';
// export const REGION_ASIA = 'asia';

export const MAXIFY_DEFAULT_REGION = MAXIFY_REGION_NORTHERN_AMERICA;

// TODO - add all countries that should map to a region
export const MAXIFY_REGION_MAP = {
  [DEFAULT_COUNTRY_CODE]: MAXIFY_REGION_NORTHERN_AMERICA,
  [AUSTRALIA_COUNTRY_CODE]: MAXIFY_REGION_OCEANIA,
  [BELGIUM_COUNTRY_CODE]: MAXIFY_REGION_EUROPE,
  [AUSTRIA_COUNTRY_CODE]: MAXIFY_REGION_EUROPE,
  [GERMANY_COUNTRY_CODE]: MAXIFY_REGION_EUROPE,
  [FRANCE_COUNTRY_CODE]: MAXIFY_REGION_EUROPE,
  [ITALY_COUNTRY_CODE]: MAXIFY_REGION_EUROPE,
  [LUXEMBOURG_COUNTRY_CODE]: MAXIFY_REGION_EUROPE,
  [NETHERLANDS_COUNTRY_CODE]: MAXIFY_REGION_EUROPE,
  [CANADA_COUNTRY_CODE]: MAXIFY_REGION_NORTHERN_AMERICA,
  [SPAIN_COUNTRY_CODE]: MAXIFY_REGION_EUROPE,
  [UNITED_KINGDOM_COUNTRY_CODE]: MAXIFY_REGION_UNITED_KINGDOM,
  [NEW_ZEALAND_COUNTRY_CODE]: MAXIFY_REGION_NEW_ZEALAND,
  [JAPAN_COUNTRY_CODE]: MAXIFY_REGION_ASIA,
};

export const GEOIP_CONTINENT_NORTH_AMERICA = 'NA';
export const GEOIP_CONTINENT_EUROPE = 'EU';
export const GEOIP_CONTINENT_OCEANIA = 'OC';
export const GEOIP_CONTINENT_ASIA = 'AS';
export const GEOIP_CONTINENT_SOUTH_AMERICA = 'SA';
export const GEOIP_CONTINENT_AFRICA = 'AF';
export const GEOIP_CONTINENT_ANTARCTICA = 'AN';

// maps the continent result from the geoip service to a location code
export const GEOIP_CONTINENT_REGION_TO_LOCATION_MAP = {
  [GEOIP_CONTINENT_NORTH_AMERICA]: DEFAULT_COUNTRY_CODE,
  [GEOIP_CONTINENT_EUROPE]: GERMANY_COUNTRY_CODE,
  [`${GEOIP_CONTINENT_EUROPE}-GB`]: UNITED_KINGDOM_COUNTRY_CODE,
  [GEOIP_CONTINENT_OCEANIA]: AUSTRALIA_COUNTRY_CODE,
  [GEOIP_CONTINENT_ASIA]: JAPAN_COUNTRY_CODE,
  [GEOIP_CONTINENT_SOUTH_AMERICA]: DEFAULT_COUNTRY_CODE,
  [GEOIP_CONTINENT_AFRICA]: DEFAULT_COUNTRY_CODE,
  [GEOIP_CONTINENT_ANTARCTICA]: DEFAULT_COUNTRY_CODE,
  BE: 'BE',
  FR: 'FR',
  IT: 'IT',
  AT: 'AT',
  LU: 'LU',
  NL: 'NL',
  NZ: 'NZ',
  ES: 'ES',
};

export const GEOIP_CONTINENT_TO_LOCATION_MAP = ({
  continentCode,
  countryCode,
}: Geo): string => {
  const continentRegionKey =
    countryCode === 'GB' ? `${continentCode}-GB` : continentCode;

  return (
    GEOIP_CONTINENT_REGION_TO_LOCATION_MAP[countryCode] ||
    GEOIP_CONTINENT_REGION_TO_LOCATION_MAP[continentRegionKey]
  );
};

export const SUPPORTED_LOCATION = {
  US: 'US',
  CA: 'CA',
  BE: 'BE',
  FR: 'FR',
  IT: 'IT',
  AT: 'AT',
  DE: 'DE',
  LU: 'LU',
  NL: 'NL',
  ES: 'ES',
  AU: 'AU',
  GB: 'GB',
  NZ: 'NZ',
  ...(process.env.NEXT_PUBLIC_FEATURE_ENABLE_JAPAN === 'true'
    ? { JP: 'JP' }
    : {}),
};

export const GEOIP_COUNTRY_TO_LOCATION_MAP = ({
  continentCode,
  countryCode,
}: Geo): string => {
  const continentRegionKey =
    countryCode === 'GB' ? `${continentCode}-GB` : continentCode;

  return GEOIP_CONTINENT_REGION_TO_LOCATION_MAP[continentRegionKey];
};

// pretty names for regions
export const REGION_NAME_EUROPE = 'Europe';
export const REGION_NAME_NORTH_AMERICA = 'North America';
export const REGION_NAME_OCEANIA = 'Oceania';
export const REGION_NAME_UNITED_KINGDOM = 'United Kingdom';
export const REGION_NAME_ASIA = 'ASIA';

export const COUNTRY_NAME_UNITED_STATES = 'United States';
export const COUNTRY_NAME_UNITED_KINGDOM = 'United Kingdom';
export const COUNTRY_NAME_BELGIUM = 'Belgium';
export const COUNTRY_NAME_FRANCE = 'France';
export const COUNTRY_NAME_ITALY = 'Italy';
export const COUNTRY_NAME_GERMANY = 'Germany';
export const COUNTRY_NAME_LUXEMBOURG = 'Luxembourg';
export const COUNTRY_NAME_AUSTRALIA = 'Australia';
export const COUNTRY_NAME_NEW_ZEALAND = 'New Zealand';
export const COUNTRY_NAME_SPAIN = 'Spain';
export const COUNTRY_NAME_NETHERLANDS = 'Netherlands';
export const COUNTRY_NAME_CANADA = 'Canada';
export const COUNTRY_NAME_AUSTRIA = 'Austria';
export const COUNTRY_NAME_JAPAN = 'Japan';

// values for the region selector
export const LOCATIONS = [
  {
    label: REGION_NAME_NORTH_AMERICA,
    value: DEFAULT_COUNTRY_CODE,
  },
  {
    label: REGION_NAME_EUROPE,
    value: GERMANY_COUNTRY_CODE,
  },
  {
    label: REGION_NAME_OCEANIA,
    value: AUSTRALIA_COUNTRY_CODE,
  },
  {
    label: REGION_NAME_UNITED_KINGDOM,
    value: UNITED_KINGDOM_COUNTRY_CODE,
  },
  {
    label: REGION_NAME_ASIA,
    value: JAPAN_COUNTRY_CODE,
  },
];

// values for the country selector
export const NORTHERN_AMERICA = [
  {
    label: COUNTRY_NAME_UNITED_STATES,
    value: DEFAULT_COUNTRY_CODE,
  },
  {
    label: COUNTRY_NAME_CANADA,
    value: CANADA_COUNTRY_CODE,
  },
];

export const EUROPE = [
  {
    label: COUNTRY_NAME_AUSTRIA,
    value: AUSTRIA_COUNTRY_CODE,
  },
  {
    label: COUNTRY_NAME_BELGIUM,
    value: BELGIUM_COUNTRY_CODE,
  },
  {
    label: COUNTRY_NAME_FRANCE,
    value: FRANCE_COUNTRY_CODE,
  },
  {
    label: COUNTRY_NAME_ITALY,
    value: ITALY_COUNTRY_CODE,
  },
  {
    label: COUNTRY_NAME_GERMANY,
    value: GERMANY_COUNTRY_CODE,
  },
  {
    label: COUNTRY_NAME_LUXEMBOURG,
    value: LUXEMBOURG_COUNTRY_CODE,
  },
  {
    label: COUNTRY_NAME_NETHERLANDS,
    value: NETHERLANDS_COUNTRY_CODE,
  },
  {
    label: COUNTRY_NAME_UNITED_KINGDOM,
    value: UNITED_KINGDOM_COUNTRY_CODE,
  },
  {
    label: COUNTRY_NAME_SPAIN,
    value: SPAIN_COUNTRY_CODE,
  },
];

export const OCEANIA = [
  {
    label: COUNTRY_NAME_AUSTRALIA,
    value: AUSTRALIA_COUNTRY_CODE,
  },
  {
    label: COUNTRY_NAME_NEW_ZEALAND,
    value: NEW_ZEALAND_COUNTRY_CODE,
  },
];

export const ASIA = [
  ...(process.env.NEXT_PUBLIC_FEATURE_ENABLE_JAPAN === 'true'
    ? [
        {
          label: COUNTRY_NAME_JAPAN,
          value: JAPAN_COUNTRY_CODE,
        },
      ]
    : []),
];

// maps country code to pretty region names
export const COUNTRY_CODE_TO_REGION_NAME_MAP = {
  [DEFAULT_COUNTRY_CODE]: REGION_NAME_NORTH_AMERICA,
  [AUSTRALIA_COUNTRY_CODE]: REGION_NAME_OCEANIA,
  [UNITED_KINGDOM_COUNTRY_CODE]: REGION_NAME_UNITED_KINGDOM,
  [AUSTRIA_COUNTRY_CODE]: REGION_NAME_EUROPE,
  [BELGIUM_COUNTRY_CODE]: REGION_NAME_EUROPE,
  [FRANCE_COUNTRY_CODE]: REGION_NAME_EUROPE,
  [ITALY_COUNTRY_CODE]: REGION_NAME_EUROPE,
  [GERMANY_COUNTRY_CODE]: REGION_NAME_EUROPE,
  [LUXEMBOURG_COUNTRY_CODE]: REGION_NAME_EUROPE,
  [NETHERLANDS_COUNTRY_CODE]: REGION_NAME_EUROPE,
  [CANADA_COUNTRY_CODE]: REGION_NAME_NORTH_AMERICA,
  [NEW_ZEALAND_COUNTRY_CODE]: REGION_NAME_OCEANIA,
  [SPAIN_COUNTRY_CODE]: REGION_NAME_EUROPE,
  ...(process.env.NEXT_PUBLIC_FEATURE_ENABLE_JAPAN === 'true'
    ? { [JAPAN_COUNTRY_CODE]: REGION_NAME_ASIA }
    : {}),
};

// maps country code to pretty country names
export const COUNTRY_CODE_TO_COUNTRY_NAME_MAP = {
  [DEFAULT_COUNTRY_CODE]: COUNTRY_NAME_UNITED_STATES,
  [AUSTRALIA_COUNTRY_CODE]: COUNTRY_NAME_AUSTRALIA,
  [UNITED_KINGDOM_COUNTRY_CODE]: COUNTRY_NAME_UNITED_KINGDOM,
  [AUSTRIA_COUNTRY_CODE]: COUNTRY_NAME_AUSTRIA,
  [BELGIUM_COUNTRY_CODE]: COUNTRY_NAME_BELGIUM,
  [FRANCE_COUNTRY_CODE]: COUNTRY_NAME_FRANCE,
  [ITALY_COUNTRY_CODE]: COUNTRY_NAME_ITALY,
  [GERMANY_COUNTRY_CODE]: COUNTRY_NAME_GERMANY,
  [LUXEMBOURG_COUNTRY_CODE]: COUNTRY_NAME_LUXEMBOURG,
  [NETHERLANDS_COUNTRY_CODE]: COUNTRY_NAME_NETHERLANDS,
  [CANADA_COUNTRY_CODE]: COUNTRY_NAME_CANADA,
  [SPAIN_COUNTRY_CODE]: COUNTRY_NAME_SPAIN,
  [NEW_ZEALAND_COUNTRY_CODE]: COUNTRY_NAME_NEW_ZEALAND,
  ...(process.env.NEXT_PUBLIC_FEATURE_ENABLE_JAPAN === 'true'
    ? { [JAPAN_COUNTRY_CODE]: COUNTRY_NAME_JAPAN }
    : {}),
};

// maps country name to country code
export const COUNTRY_TO_COUNTRY_CODE_MAP = {
  [COUNTRY_NAME_UNITED_STATES]: DEFAULT_COUNTRY_CODE,
  [COUNTRY_NAME_AUSTRALIA]: AUSTRALIA_COUNTRY_CODE,
  [COUNTRY_NAME_UNITED_KINGDOM]: UNITED_KINGDOM_COUNTRY_CODE,
  [COUNTRY_NAME_AUSTRIA]: AUSTRIA_COUNTRY_CODE,
  [COUNTRY_NAME_BELGIUM]: BELGIUM_COUNTRY_CODE,
  [COUNTRY_NAME_FRANCE]: FRANCE_COUNTRY_CODE,
  [COUNTRY_NAME_ITALY]: ITALY_COUNTRY_CODE,
  [COUNTRY_NAME_GERMANY]: GERMANY_COUNTRY_CODE,
  [COUNTRY_NAME_LUXEMBOURG]: LUXEMBOURG_COUNTRY_CODE,
  [COUNTRY_NAME_NETHERLANDS]: NETHERLANDS_COUNTRY_CODE,
  [COUNTRY_NAME_CANADA]: CANADA_COUNTRY_CODE,
  [COUNTRY_NAME_NEW_ZEALAND]: NEW_ZEALAND_COUNTRY_CODE,
  [COUNTRY_NAME_SPAIN]: SPAIN_COUNTRY_CODE,
  ...(process.env.NEXT_PUBLIC_FEATURE_ENABLE_JAPAN === 'true'
    ? { [COUNTRY_NAME_JAPAN]: JAPAN_COUNTRY_CODE }
    : {}),
};

// maps region code to pretty region names
export const REGION_TO_REGION_NAME_MAP = {
  [MAXIFY_REGION_NORTHERN_AMERICA]: REGION_NAME_NORTH_AMERICA,
  [MAXIFY_REGION_EUROPE]: REGION_NAME_EUROPE,
  [MAXIFY_REGION_OCEANIA]: REGION_NAME_OCEANIA,
  [MAXIFY_REGION_UNITED_KINGDOM]: REGION_NAME_UNITED_KINGDOM,
};

// Locale values accepted by Maxify, these map to the language of the content returned
export const MAXIFY_LOCALE_MAP = {
  af: 'af_ZA',
  ar: 'ar_SA',
  az: 'az_AZ',
  be: 'be_BY',
  bg: 'bg_BG',
  bn: 'bn_BD',
  bs: 'bs_BA',
  ca: 'ca_ES',
  cs: 'cs_CZ',
  cy: 'cy_GB',
  da: 'da_DK',
  de: 'de_DE',
  el: 'el_GR',
  en: 'en_US',
  eo: 'eo',
  es: 'es_ES',
  et: 'et_EE',
  eu: 'eu_ES',
  fa: 'fa_IR',
  fi: 'fi_FI',
  fr: 'fr_FR',
  gl: 'gl_ES',
  he: 'he_IL',
  hi: 'hi_IN',
  hr: 'hr_HR',
  hu: 'hu_HU',
  id: 'id_ID',
  is: 'is_IS',
  it: 'it_IT',
  ja: 'ja_JP',
  km: 'km_KH',
  kn: 'kn_IN',
  ko: 'ko_KR',
  lo: 'lo_LA',
  lt: 'lt_LT',
  lv: 'lv_LV',
  mk: 'mk_MK',
  mn: 'mn_MN',
  ms: 'ms_MY',
  nb: 'nb_NO',
  ne: 'ne_NP',
  nl: 'nl_NL',
  nn: 'nn_NO',
  or: 'or_IN',
  pl: 'pl_PL',
  pt: 'pt_BR',
  rm: 'rm_CH',
  ro: 'ro_RO',
  ru: 'ru_RU',
  sk: 'sk_SK',
  sl: 'sl_SI',
  sr: 'sr_BA',
  sv: 'sv_SE',
  sw: 'sw_KE',
  ta: 'ta_IN',
  th: 'th_TH',
  tl: 'tl_PH',
  tf: 'tr_TR',
  tt: 'tt_RU',
  uk: 'uk_UA',
  ur: 'ur_PK',
  uz: 'uz_UZ',
  vi: 'vi_VN',
  wo: 'wo_SN',
  zh: 'zh_CN',
};

// special cases - where a language might have more than 1 accepted locale
export const MAXIFY_LOCALE_MAP_MULTIPLE_LOCALES_PER_LANG = {
  en_GB: 'en_GB',
  en_US: 'en_US',
  en_AU: 'en_AU',
  zh_CN: 'zh_CN',
  zh_HK: 'zh_HK',
};

export const CURRENCY_CODE_US = 'USD';
export const CURRENCY_CODE_EU = 'EUR';
export const CURRENCY_CODE_AU = 'AUD';
export const CURRENCY_CODE_UK = 'GBP';
export const CURRENCY_CODE_NZ = 'NZD';
export const CURRENCY_CODE_CA = 'CAD';
export const CURRENCY_CODE_JP = 'JPY';

export const MAXIFY_CURRENCY_MAP_PER_REGION = {
  [MAXIFY_REGION_NORTHERN_AMERICA]: CURRENCY_CODE_US,
  [MAXIFY_REGION_EUROPE]: CURRENCY_CODE_EU,
  [MAXIFY_REGION_OCEANIA]: CURRENCY_CODE_AU,
  [MAXIFY_REGION_UNITED_KINGDOM]: CURRENCY_CODE_UK,
  [MAXIFY_REGION_NEW_ZEALAND]: CURRENCY_CODE_NZ,
};

export const CURRENCY_MAP_PER_COUNTRY_CODE = {
  [DEFAULT_COUNTRY_CODE]: CURRENCY_CODE_US,
  [AUSTRIA_COUNTRY_CODE]: CURRENCY_CODE_EU,
  [FRANCE_COUNTRY_CODE]: CURRENCY_CODE_EU,
  [GERMANY_COUNTRY_CODE]: CURRENCY_CODE_EU,
  [BELGIUM_COUNTRY_CODE]: CURRENCY_CODE_EU,
  [ITALY_COUNTRY_CODE]: CURRENCY_CODE_EU,
  [LUXEMBOURG_COUNTRY_CODE]: CURRENCY_CODE_EU,
  [NETHERLANDS_COUNTRY_CODE]: CURRENCY_CODE_EU,
  [AUSTRALIA_COUNTRY_CODE]: CURRENCY_CODE_AU,
  [UNITED_KINGDOM_COUNTRY_CODE]: CURRENCY_CODE_UK,
  [CANADA_COUNTRY_CODE]: CURRENCY_CODE_CA,
  [NEW_ZEALAND_COUNTRY_CODE]: CURRENCY_CODE_NZ,
  [SPAIN_COUNTRY_CODE]: CURRENCY_CODE_EU,
  ...(process.env.NEXT_PUBLIC_FEATURE_ENABLE_JAPAN === 'true'
    ? { [JAPAN_COUNTRY_CODE]: CURRENCY_CODE_JP }
    : {}),
};

export const CURRENCY_SYMBOL_MAP = {
  [CURRENCY_CODE_US]: '$',
  [CURRENCY_CODE_EU]: '€',
  [CURRENCY_CODE_AU]: 'AU$',
  [CURRENCY_CODE_NZ]: 'NZ$',
  [CURRENCY_CODE_UK]: '£',
  [CURRENCY_CODE_CA]: 'CA$',
  [CURRENCY_CODE_JP]: '¥',
};

export const SHIPPING_COUNTRY_PER_COUNTRY_CODE = {
  [DEFAULT_COUNTRY_CODE]: DEFAULT_COUNTRY_CODE,
  [AUSTRIA_COUNTRY_CODE]: GERMANY_COUNTRY_CODE,
  [FRANCE_COUNTRY_CODE]: GERMANY_COUNTRY_CODE,
  [GERMANY_COUNTRY_CODE]: GERMANY_COUNTRY_CODE,
  [BELGIUM_COUNTRY_CODE]: GERMANY_COUNTRY_CODE,
  [ITALY_COUNTRY_CODE]: GERMANY_COUNTRY_CODE,
  [LUXEMBOURG_COUNTRY_CODE]: GERMANY_COUNTRY_CODE,
  [NETHERLANDS_COUNTRY_CODE]: GERMANY_COUNTRY_CODE,
  [UNITED_KINGDOM_COUNTRY_CODE]: UNITED_KINGDOM_COUNTRY_CODE,
  [CANADA_COUNTRY_CODE]: DEFAULT_COUNTRY_CODE,
  [SPAIN_COUNTRY_CODE]: GERMANY_COUNTRY_CODE,
};

export const YOTPO_KEY_MAP = {
  [MAXIFY_REGION_NORTHERN_AMERICA]: process.env.NEXT_PUBLIC_YOTPO_KEY_US,
  [MAXIFY_REGION_EUROPE]: process.env.NEXT_PUBLIC_YOTPO_KEY_EU,
  [MAXIFY_REGION_OCEANIA]: process.env.NEXT_PUBLIC_YOTPO_KEY_AU,
  [MAXIFY_REGION_UNITED_KINGDOM]: process.env.NEXT_PUBLIC_YOTPO_KEY_UK,
  [MAXIFY_REGION_NEW_ZEALAND]: process.env.NEXT_PUBLIC_YOTPO_KEY_NZ,
  [MAXIFY_REGION_ASIA]: process.env.NEXT_PUBLIC_YOTPO_KEY_US,
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ReactNode } from 'react';
import * as React from 'react';
import { DEFAULT_LOCALE, MAXIFY_TOKEN_COOKIE_NAME } from '@constants';
import { FetcherError } from './utils/errors';
import {
  CommerceConfig,
  CommerceProvider as CoreCommerceProvider,
  useCommerce as useCoreCommerce,
} from './commerce';
import { getToken } from './api/utils/maxify';
import { getShopifyCartCookie } from './api/utils/shopify-cart-cookie';

async function getText(res: Response) {
  try {
    return (await res.text()) || res.statusText;
  } catch (error) {
    return res.statusText;
  }
}

async function getError(res: Response) {
  if (res.headers.get('Content-Type')?.includes('application/json')) {
    const data = await res.json();
    return new FetcherError({ errors: data.errors, status: res.status });
  }
  return new FetcherError({ message: await getText(res), status: res.status });
}

export const maxifyConfig: CommerceConfig = {
  locale: DEFAULT_LOCALE,
  cartCookie: MAXIFY_TOKEN_COOKIE_NAME,
  cartCookieValue: getToken(),
  shopifyCartCookie: getShopifyCartCookie(DEFAULT_LOCALE),
  async fetcher({ url, method = 'GET', variables, body: bodyObj }) {
    const hasBody = Boolean(variables || bodyObj);
    const body = hasBody
      ? JSON.stringify(variables ? { variables } : bodyObj)
      : undefined;
    const headers = hasBody
      ? { 'Content-Type': 'application/json' }
      : undefined;
    const res = await fetch(url!, { method, body, headers });

    if (res.ok) {
      const { data } = await res.json();
      return data;
    }

    throw await getError(res);
  },
};

export type MaxifyConfig = Partial<CommerceConfig>;

export type MaxifyProps = {
  children?: ReactNode;
  locale: string;
  cartCookieValue: string;
  shopifyCartCookie: string;
} & MaxifyConfig;

export function CommerceProvider({ children, ...config }: MaxifyProps) {
  return (
    <CoreCommerceProvider config={{ ...maxifyConfig, ...config }}>
      {children}
    </CoreCommerceProvider>
  );
}

export const useCommerce = () => useCoreCommerce();

import { useRef, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const usePrevious = <Value = any>(value: Value): Value => {
  const ref = useRef<Value>();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;

// use:
// get the previous value of props or state
// const [count, setCount] = useState(0)
// const prevCount = usePrevious(count)

import {
  ROUTE_SUPPORT,
  CONTENT_TYPE_PAGE,
  NZXT_S3_BUCKET,
  CAM_DOWNLOAD_PATH,
} from './routes';

export const CAM_CTA = {
  title: 'cam_title',
  description: 'cam_description',
  logo: '/logo/nzxt-cam.svg',
  firstButton: {
    linkType: 'primary',
    linkLabel: 'free_download',
    asButton: true,
    externalLinkRef: {
      url: `${NZXT_S3_BUCKET}/${CAM_DOWNLOAD_PATH}`,
    },
    id: 'free_download_cam',
  },
  smallPrint: 'cam_windows_10',
  background: 'dark grey',
};

export const HELP_CTA = {
  title: 'help_amp_support',
  description: 'help_description',
  firstButton: {
    linkType: 'primary',
    linkLabel: 'help_and_support',
    _modelApiKey: ROUTE_SUPPORT,
    internalLink: {
      _modelApiKey: CONTENT_TYPE_PAGE,
      pageType: ROUTE_SUPPORT,
      slug: ROUTE_SUPPORT,
      id: 'help-path',
    },
    asButton: true,
    id: 'explore_cam',
  },
  background: 'blue',
};

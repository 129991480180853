export const COOKIES = [
  'XDOMAIN-LOGGED-IN',
  '_BEAMER_*',
  '_cs_*',
  '_hj*',
  '_hp2*',
  '_rdt*',
  'ajs_*',
  'pp_*',
  'ps_*',
  'utm_params',
];

export const HJID = 4986894;

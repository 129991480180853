/* eslint-disable max-len */
const userAccount = {
  user_settings_title: 'アカウント',
  user_settings_change_password: 'パスワードの変更',
  user_settings_save: '保存',
  user_settings_cancel: 'キャンセル',
  user_profile_heading: 'プロフィール',
  user_saved_builds_heading: '保存されたビルド',
  user_saved_builds_bld_name: 'ビルド名',
  user_saved_builds_bld_date: '作成日',
  user_saved_builds_bld_item_part: '部品',
  user_saved_builds_delete_bld: 'BLDを削除',
  user_saved_builds_resume_bld: 'BLDを再開',
  user_saved_builds_hero_title: 'ビルドを誇示するようなデザインです',
  user_saved_builds_hero_text:
    'コンパクトな縦型デザインにより、市場に出回っているほとんどのフルサイズGPUのサポートを維持できるため、グラフィックスパワーを犠牲にせず、机のスペースを確保できます。',
  user_saved_builds_pc_heading: 'PCビルド',
  user_saved_builds_keyboard_heading: 'キーボードのビルド',
  user_saved_builds_view_pc: 'PCのビルドを表示',
  user_saved_builds_view_keyboard: 'キーボードのビルドを表示',
  user_saved_builds_no_pc: '保存されたPCビルドがありません',
  user_saved_builds_no_kb: '保存されたキーボードビルドがありません',
  user_order_history_heading: '注文履歴',
  user_order_history_date: '日付',
  user_order_history_order_number: '注文番号',
  user_order_history_status: 'ステータス',
  user_order_history_amount: '金額',
  user_order_history_view: 'レシートを表示',
  user_order_history_empty_view: '注文履歴がありません。',
  user_order_history_shipping: '配送先の住所',
  user_order_history_billing: '請求先の住所',
  user_order_history_item: 'アイテム',
  user_order_history_item_image: 'アイテムの画像',
  user_order_history_quantity: '数量',
  user_order_history_order: '注文番号',
  user_support_heading: 'サポート + 返品',
  user_data_heading: 'データリクエスト',
  user_terms_heading: '利用規約',
  user_terms_empty: 'まだ規約に署名されていません',
  user_support_subject_min_length: '件名は3文字以上でなければなりません',
  user_support_message_placeholder:
    'ご用件を詳しくご記入ください。 返品の場合は、注文番号をご記入ください',
  user_support_update_button: '更新',
  user_active_tickets_heading: 'アクティブなチケット',
  user_active_tickets_chat: 'メッセージを追加',
  user_active_tickets_no_chat_history: 'チャット履歴がありません',
  view_chat_button: 'チャットを表示',
  close_chat_button: 'チャットを閉じる',
  field_required_message: 'このフィールドは必須です',
  label_accept: '受け入れる',
  label_reason_for_contact: 'ご用件',
  label_country: '国',
  label_subject: '件名',
  label_product: '製品',
  label_message: 'メッセージ',
  label_requestor_type: '依頼者のタイプ',
  label_country_of_residence: '居住国',
  label_description: '説明',
  placeholder_description: 'リクエストの説明',
  label_submit: '送信',
  message_no_support_requests: 'アクティブなサポートリクエストはありません',
  label_ticket_number: 'チケット番号',
  label_date_created: '作成日',
  label_status: 'ステータス',
  label_department: '部署',
  subheading_active_ticket:
    'ヘルプが必要な場合、または商品を返品したい場合は、サポートまでご連絡ください',
  label_create_support_request: 'サポートリクエストを作成',
  button_comment: 'コメント',
  label_for_myself: '自分用',
  label_parent: '親',
  label_legal_rep: '法定代理人',
  label_relative: '親族',
  label_guardian: '保護者',
  label_accept_generic_terms: 'これらの規約を読み、同意します',
  error_view_message: 'おや！問題が発生しました。もう一度やり直してください。',
  signout_view_message: 'ログアウトされました。',
  birthday_error_message: '正しい生年月日を入力してください',
  label_view_saved: '保存されたビルドをすべて表示',
};

const formErrors = {
  invalid_email: '有効なEメールを入力してください',
  username_full_pattern_message:
    'ユーザー名は6文字以上30文字以内で入力してください。文字、数字、ピリオドが使用できますが、ピリオドを最初と最後に使用したり、連続して使用したりはできません。',
  password_pattern_message:
    'パスワードには少なくとも6文字、大文字1文字、小文字1文字、数字1文字が含まれなければなりません',
  username_min_length_message: 'ユーザー名には6文字以上使用してください',
  passwords_must_match: 'パスワードは一致しなければなりません',
  invalid_current_password:
    'パスワードを更新できません。現在のパスワードとして提供された値が正しくありません',
  must_accept_partner_program_terms:
    'NZXTパートナーになるには、パートナープログラム規約に同意いただく必要があります',
  must_accept_tos_terms:
    'NZXTアカウントを作成するには、利用規約に同意いただく必要があります',
  must_accept_generic_terms: 'これらの条件に同意いただく必要があります',
};

const auth = {
  user_auth_agreement: '私は以下に同意します。NZXTの',
  user_auth_privacy_policy: 'プライバシーポリシー',
  user_auth_and: 'また、以下のものを読みました',
  user_auth_terms: '利用規約',
  error_invalid_request: '無効なリクエストです。',
  error_invalid_token: 'ユーザー認証トークンが無効です',
  error_session_expired:
    'セッションの有効期限が切れました。続行するには再度ログインしてください。',
  user_forgot_required_email: 'Eメールアドレスが必要です',
  user_forgot_success_message:
    'ありがとうございます！このメールアドレスに関連するアカウントがある場合、すぐにパスワードリセットの手順を送信します。',
  error_email_exists: 'メールアドレスがすでに使用されています',
  user_email_verification_heading: 'メールアドレスの認証',
  error_email_not_verified:
    'メールアドレスはまだ認証されていません。確認メールを再送するには、クリックしてください',
  error_email_already_verified:
    'メールアドレスは認証済みです。ログインを続行してください。',
  user_email_verification_success_message:
    'Eメールアドレスの認証に成功しました！ログインを続行してください。',
  error_username_unavailable: 'ユーザー名が使用できません',
  error_reset_password_same_as_old_password:
    'このパスワードは以前と同じです。別のものを選択してください。',
  error_reset_password_link_expired:
    'パスワードをリセットするリンクの有効期限が切れました。',
  error_reset_password_link_invalid:
    'パスワードをリセットするリンクが無効です。',
  user_forgot_password_subheading:
    'パスワードのリセット方法に関する説明を受け取るには、Eメールを入力してください。',
  user_reset_password_heading: 'パスワードをリセット',
  user_reset_password_subheading: '新しいパスワードを入力してください。',
  user_reset_password: 'パスワードのリセット',
  user_reset_success_message: 'パスワードのリセットに成功しました！',
  user_login_text: 'Googleでログイン',
  user_login_remember: 'ユーザー情報を保存する',
  user_login_forgot_password: 'パスワードをお忘れですか？',
  user_login_wrong_email_or_password:
    'メールアドレスまたはパスワードが正しくありません',
  user_login_success_message: 'ログインに成功しました！',
  error_too_many_requests:
    'リクエストが多すぎます。数分お待ちいただくか、サポートまでご連絡ください',
  error_user_not_found: 'ユーザーが見つかりませんでした',
  user_sign_in_sign_up: 'サインイン / サインアップ',
  user_sign_out: 'サインアウト',
  user_sign_in: 'サインイン',
  user_account_updates: 'NZXTアップデート',
  user_account_no_updates:
    '最新のアップデートはありません。また後でご確認ください。',
  support_center_link: 'サポートセンター',
  error_google_account_exists:
    'Googleを使用して既にアカウントを作成済みです。代わりにログインをしてください。',
  error_local_account_exists:
    '既にアカウントをお持ちのようです。代わりにログインをしてください。',
  user_sign_up: 'サインアップ',
  user_sign_up_text: 'Googleでサインアップ',
  user_sign_up_opt_in:
    '製品、BLD、CAMに関するNZXTからの最新情報を受け取るためには、メールアドレスをご登録ください',
  user_sign_up_success_verify_message:
    'お客様のアドレスに、アクティベーションリンクを含むメールが送信されました。リンクをクリックして、アカウントを有効にしてください。リンクをクリックされない場合、アカウントは無効のままとなり、それ以降のメールは届きません。数分以内にメールが届かない場合は、迷惑メールフォルダをご確認ください。',
  user_info_updated_success_message: 'お客様の情報は正常に更新されました！',
  user_info_newsletter_confirmation_message:
    '確認のため、メールをご確認ください',
  user_email_sign_in: 'または以下の内容で続ける',
  user_no_account: 'メンバーではありませんか？',
  user_create_account: 'アカウントを作成',
  user_email_sign_up_text: 'または以下の内容でサインアップする',
  user_have_account: 'アカウントをお持ちですか？',
  open_login_view: 'ログインビューを開く',
  error_newsletter_subscription:
    '現在ニュースレターの購読 / 購読解除ができません。後でもう一度お試しください。',
  label_email_address: 'メールアドレス',
  label_password: 'パスワード',
  label_current_password: '現在のパスワード',
  label_confirm_password: 'パスワードの確認',
  label_new_password: '新しいパスワード',
  label_confirm_new_password: '新しいパスワードの確認',
  label_username: 'ユーザー名',
  label_first_name: '名',
  label_last_name: '姓',
  label_preferred_pronouns: 'ご希望される代名詞',
  label_search_for_help_articles: 'ヘルプ記事を検索',
  label_birthday: '誕生日',
  label_location: '場所',
};

const build = {
  custom_bld_modal_heading: 'PCをビルドする',
  custom_bld_modal_subheading: 'チップセットと予算を選択する',
  custom_bld_unavailable: '別のアイテムを選択してください',
  custom_bld_issue: '次のアイテムを解決する必要があります: ',
  custom_bld_title: 'カスタムBLD',
  custom_bld_lets_get_started: 'さあ、始めましょう',
  custom_bld_destrutive_action: '警告: ビルドがリセットされます',
  custom_bld_confirm_reset: '次のチップセットでビルドがリセットされます:',
  custom_bld_keyboard_confirm_reset: 'この変更でビルドがリセットされます',
  custom_bld_choose_chipset: 'チップセットを選択してください',
  custom_bld_chipset: 'チップセット',
  custom_bld_intel: 'Intel',
  custom_bld_amd: 'AMD',
  custom_bld_explore: 'Explore',
  custom_bld_select: '選択',
  review_bld_button: 'BLDをレビューする',
  custom_bld_summary: '概要',
  custom_bld_budget: '予算',
  custom_bld_specs: 'スペック',
  custom_bld_case: 'ケース',
  custom_bld_cases: 'ケース（複数）',
  custom_bld_caseCooling: '冷却',
  custom_bld_fans: 'ファン',
  custom_bld_cpu: 'CPU',
  custom_bld_gpu: 'GPU',
  custom_bld_ram: 'RAM',
  custom_bld_ssd: 'SSD',
  custom_bld_hdd: 'HDD',
  custom_bld_specials: 'スペシャル',
  custom_bld_colors: 'カラー（複数）',
  custom_bld_color: 'カラー',
  custom_bld_black: 'ブラック',
  custom_bld_red: 'レッド',
  custom_bld_white: 'ホワイト',
  custom_bld_grey: 'グレー',
  custom_bld_blue: 'ブルー',
  custom_bld_purple: 'パープル',
  custom_bld_yellow: 'イエロー',
  custom_bld_audio: 'オーディオ',
  custom_bld_extras: '追加品',
  custom_bld_core: 'コア',
  custom_bld_motherboard: 'マザーボード',
  custom_bld_motherboards: 'マザーボード',
  custom_bld_powerSupplies: '電源',
  custom_bld_powerSupply: '電源',
  custom_bld_services: 'サービス',
  custom_bld_software: 'ソフトウェア',
  custom_bld_monitors: 'モニター',
  custom_bld_peripherals: '周辺機器',
  custom_bld_lighting: '照明',
  custom_bld_headset: 'ヘッドセット',
  custom_bld_keyboard: 'キーボード',
  custom_bld_all: '全て',
  custom_bld_mouse: 'マウス',
  custom_bld_storage: 'ストレージ',
  custom_bld_streaming_ready: 'ストリーム対応',
  custom_bld_rgb_lighting: 'RGBライティング',
  custom_bld_vr_ready: 'VR対応',
  custom_bld_wifi: 'WiFi',
  custom_bld_from: 'フロム',
  custom_bld_bluetooth: 'Bluetooth',
  custom_bld_save_build_message:
    'ビルドが正常に保存されました！アカウント内の保存されたビルドで確認できます。',
  custom_bld_save_modal_message: '後のためにこのビルドを保存しますか？',
  custom_bld_confirm_save_modal_message:
    'このページを離れると、未保存の変更が失われます。',
  custom_bld_share_build_message: 'ビルドが正常に保存、コピーされました！',
  help_me_decide: '決めるのを手伝ってください',
  save_build: 'ビルドを保存',
  start_over: 'やり直す',
  summary: '概要',
  build_summary: 'ビルド概要',
  load_build: 'ビルドのロード',
  load: 'ロード',
  save: '保存',
  restart: '再起動',
  share: '共有',
  my_build_label: 'マイビルド',
  est_shipping: '配送予定',
  add_to_build: 'ビルドに追加',
  message_invalid: '一時的に入手不可',
  message_out_of_stock: '売り切れ',
  message_low_stock: '在庫わずか',
  message_new_item: '新着',
  message_coming_soon: '近日発売',
  message_best_seller: 'ベストセラー',
  message_is_locked: '互換性がありません',
  button_label_select: '選択',
  button_label_selected: '選択されました',
  button_label_add: '追加',
  button_label_added: '追加されました',
  button_label_update: '更新',
  button_label_remove: '削除',
  button_label_info: '情報',
  button_label_more_info: '詳細情報',
  button_label_options_info: 'オプション & 情報',
  button_label_continue_to: 'ビルドを続ける',
  button_label_edit: '編集',
  build_fps_label:
    'FPS速度は各ゲームの最高グラフィック設定を使用し、2560x1440の解像度で測定されます',
  build_includes: 'これらを含む',
  custom_bld_boost: 'ビルドをブースト',
  custom_bld_fps_unavailable: '保留中',
  custom_bld_amd_help:
    'ここ数年、IntelベースのPCが主流でしたが、今はAMDのPCがゲーミングには優れています。当社は両方のビルドをサポートし、相性が良いマザーボードとクーラーを選びましたので、ご自身で試行錯誤する必要はありません。当社がご提示するビルドのFPS性能とご自身の予算とを勘案してお決めください。',
  custom_bld_to_see_this: 'この選択をご覧になるためには',
  custom_bld_change_chipset: 'チップセットを変更してください。',
  custom_bld_prebuild_upsell: 'または、早くて簡単なプリビルドをお選びください',
  no_item_selected: '選択された項目はありません',
  build_change_label: 'ビルドが修正されました',
  invalid_action_label: '阻止されました',
  success_label: '成功',
  build_info_message: '情報',
  reset_label: 'ビルドのリセット',
  complete_build_label: 'ビルドを完了',
  back_to_build_label: 'ビルドに戻る',
  complete_build_sub_label: '追加品 & 周辺機器',
  back_to_build_sub_label: 'ビルドのコアコンポーネント',
  capacity_label: '容量',
  option_label: 'オプション',
  options_label: 'オプション（複数）',
  max_speed: '最高速度',
  modular_label: 'モジュラー',
  midtower_label: 'ミッドタワー',
  streaming_label: 'ストリーミング',
  save_and_exit: '保存して終了',
  exit: '終了',
  view_cart_label: 'カートを表示',
  cart_subtotal_label: 'カート小計',
  start_build_label: 'ビルドを開始',
  new_build_label: '新規ビルド',
  continue_saved_build_label: '保存されたビルドで続行',
  continue_progress_build_label: '進行中のビルドで続行',
  continue_cart_build_label: 'カート内のビルドを確認',
  core_label: 'ベース',
  extras_label: 'アドオン',
  summary_label: '概要',
  share_build_pc: 'PCをカスタムビルド',
  share_build_kb: 'キーボードをカスタムビルド',
  build_summary_label: 'キーボードビルドの概要。',
  pc_hub_card_heading: 'カスタムゲーミングPC',
  pc_hub_card_subheading:
    'ご自身の予算に合わせて、お好きなゲームにぴったりな夢のPCを作りましょう',
  kb_hub_card_heading: 'カスタムキーボード',
  kb_hub_card_subheading:
    'ご自身のスペースとゲームスタイルに合ったカスタムキーボードとマウスを作りましょう',
};

const keyboard = {
  keyboard_label: 'キーボード',
  keyboard_size: 'キーボードのサイズ',
  keyboard_base: 'キーボードのベース',
  keyboard_primay_base_color: 'ベースカラー',
  keyboard_layout: 'キーボードのレイアウト',
  keyboard_summary: 'キーボードの概要',
  accent_keycaps: 'アクセントキーキャップ',
  cabletype: 'アクセントケーブル',
  additional_cable_color: 'ケーブルの色',
  cable: 'ケーブル',
  additional_cable: '追加ケーブル',
  primary_keycaps: 'ベースのキーキャップ',
  switches: 'スイッチ',
  size: 'サイズ',
  layout: 'レイアウト',
  language: '言語',
  mouse_pad_label: 'マウスパッド',
  desk_mat_label: 'デスクマット',
  tools_label: 'ツール',
  wrist_rest_label: 'リストレスト',
  mouse_label: 'マウス',
  cable_description:
    'オプションのカラーケーブル。標準的な黒の2メートルケーブルが付属します。',
  cable_color_description: 'プレミアムケーブルの色。',
  language_description: 'Q, W, Z, Aキーの位置を決定します。',
  layout_description: 'キーのサイズと位置を決定します。',
  keycap_accent_description:
    'Escape, Enter, 矢印キー, テンキーボードのEnterなどのキーのオプションカラー。',
  primary_keycap_description: 'PBT昇華印刷型、チェリープロファイル。',
  switch_description: '各キーを押した際のタイピング感触を定めます。',
  keyboard_base_color_description: 'キーボードベースのメインカラー。',
  keyboard_base_size_description:
    'サイズによってキーボードのキーの数と寸法が決まります。',
  mouse_description: '軽量の両利き用マウス。',
  summary_description: 'キーボードビルドの概要',
  premium_label: 'プレミアム',
  base_label: 'ベース',
  keyboard_warning: '次のアイテムを選択して、ビルドを進めてください: ',
  switch_reset_warning:
    'スイッチがリセットされました。新しいスイッチを選択してください。',
  keycap_reset_warning:
    'キーキャップがリセットされました。新しいキーキャップを選択してください。',
  base_type: 'ビルドタイプ',
  build_type_description: '設定したいキーボードのタイプを選択してください。',
  full_custom_label: 'フルカスタム',
  barebones_disclaimer:
    'このアイテムを選択するには、ビルドタイプを変更してください',
  none_label: 'なし',
};

const camCta = {
  cam_description:
    '性能、温度、デバイス全てを 一つのアプリケーションから管理します。',
  cam_windows_10: 'Windows 10 64BIT/Windows 11',
};

const helpCta = {
  help_description:
    '保証、返品、技術的な問題全般、およびNZXTの全製品に関するさまざまなガイドとサポートをご利用できます',
};

const emailSignUp = {
  email_stay_in_touch: '最新の情報を受け取る',
  email_sub_copy:
    'NZXTからの最新リリースやオファー、ニュースを常に受け取りましょう',
  email_legal:
    'サインアップすることにより、NZXTおよびCAMのプライバシーポリシーと利用規約に同意したことになります。',
  email_notify: '通知を受け取る',
  email_notify_stock: '入手可能になったら通知を受け取る。',
};

const generic = {
  prebuild_fan_label: 'ファンのアップグレード',
  prebuild_keyboard_label: 'キーボード',
  prebuild_mouse_label: 'マウス',
  prebuild_audio_label: 'オーディオ',
  prebuild_accessories_label: 'アクセサリー',
  prebuild_monitor_label: 'モニター',
  fps_disclaimer: '超高設定でテスト済み',
  prebuild_specs_disclaimer: 'デフォルトの仕様',
  explore: 'Explore',
  cam_title: 'NZXT CAM',
  free_download: '無料ダウンロード',
  details: '詳細',
  help_amp_support: 'ヘルプ & サポート',
  help_and_support: 'ヘルプとサポート',
  read_more: '詳しく読む',
  privacy_policy: 'プライバシーポリシー',
  terms_conditions: '利用規約',
  and: 'そして',
  or: 'または',
  send: '送信',
  return_to: '戻る',
  previous: '前',
  next: '次',
  home: 'ホーム',
  keep_browsing: '閲覧を続ける',
  create_build: 'ビルドを作成',
  learn_more: '詳しく知る',
  add_to_cart: 'カートに追加',
  share_build: 'ビルドを共有',
  view: '表示する',
  close: '閉じる',
  price: '価格',
  cancel: 'キャンセル',
  here: 'こちら',
  ok: 'Ok',
  button_start_bld: 'BLDを開始',
  message_on_sale: 'セール',
  button_buy: '購入',
  button_start_build: 'ビルドを開始',
  view_build: 'ビルドを見る',
  button_back: '戻る',
  build: 'ビルド',
  performance: '性能',
  pending: '保留',
  error_generic: '何か問題が発生しました。後でもう一度お試しください。',
  label_support: 'サポート',
  contact_support: 'サポートに連絡',
  browse_articles: '記事の閲覧',
  search_support_articles: 'ヘルプ記事の検索',
  message_error: 'おっと、何か問題が発生したようです',
  estimated_performance_label: '性能',
  see_estimated_performance_label: '推定性能を見る',
  estimated_fps: '推定フレーム毎秒 (FPS)',
  title_related_items: 'こちらも気に入るかもしれません',
  qty: '数量',
  category_from: '送り主',
  reviews_label: 'レビュー',
  faqs_label: 'よくあるご質問',
  message_low_stock: '在庫わずか',
  message_out_of_stock: '売り切れ',
  message_limited_time: '期間限定',
  message_new_item: '新着',
  message_coming_soon: '近日発売',
  message_best_seller: 'ベストセラー',
  message_unavailable: '入手不可',
  message_invalid: '一時的に入手不可',
  message_clearance: 'クリアランス',
  message_is_limited_availability: '限定',
  message_featured_items: '注目商品の画像',
  message_colors: '色（複数）',
  message_color: '色',
  label_return_policy: '返品ポリシー',
  label_warranty_policy: '保証ポリシー',
  label_shop: 'ショップ',
  label_days: '日数',
  label_hours: '時間',
  label_minutes: '分',
  label_seconds: '秒',
  label_promotion: 'プロモーションを見る',
  label_promotions: 'プロモーション',
  monthly_payments: '月払い可能',
  message_promotion: 'プロモーション',
  discount_label: 'オフ',
  off_label: 'オフ',
  on_label: 'オン',
  choose_label: '選択する',
  move_to_previous_label: '前のスライドに移動',
  move_to_next_label: '次のスライドに移動',
  active_slide_label: 'アクティブなスライド',
  move_to_inactive_slide: '非アクティブなスライドに移動',
  color_options: 'カラーオプション',
  of: '宛て先',
  product_images: '商品画像',
  for: '対象',
  carousel: 'カルーセル',
  go_to_image: '画像へ移動',
  button_label_add: '追加',
  button_label_remove: '削除',
  increase_price_label: '価格を上げる',
  decrease_price_label: '価格を下げる',
  select_video_label: 'ビデオを選択',
  play_label: '再生',
  pause_label: '一時停止',
  buttons: 'ボタン',
  open_modal_label: '画像ズームモーダルを開く',
  close_modal_label: '画像ズームモーダルを閉じる',
  image_zoom_modal: '画像ズームモーダル',
  vr_ready_label: 'VR対応',
  vr_not_ready_label: 'VR非対応',
  streaming_ready_label: 'ストリーム対応',
  streaming_not_ready_label: 'ストリーム非対応',
  has_wifi_label: 'WiFiあり',
  no_wifi_label: 'WiFiなし',
  has_bluetooth_label: 'Bluetoothあり',
  no_bluetooth_label: 'Bluetoothなし',
  has_usbc_label: 'USB-Cあり',
  no_usbc_label: 'USB-Cなし',
  rgb_included_label: 'RGB照明が含まれます',
  rgb_not_included_label: 'RGB照明は含まれません',
  products_label: '製品',
  upsell_modal_label: 'よく一緒に購入されるもの',
  no_thanks_label: 'いいえ、結構です',
  label_deal_ends: '割引終了期限',
  label_ending_soon: 'もうすぐ割引が終了します',
  label_show_more: 'もっと表示する',
  label_show_less: '表示を減らす',
  label_case: 'ケース',
  label_software: 'ソフトウェア',
  label_services: 'サービス',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'GPU',
  label_motherboard: 'マザーボード',
  label_storage: 'ストレージ',
  label_cooling: '冷却',
  label_psu: 'PSU',
  label_warranty: '保証',
  label_accessories: 'アクセサリー',
  label_fps: 'FPS',
  label_feature: '機能',
  see_full_details_label: '全詳細を見る',
  label_more_videos: 'ビデオをもっと見る',
  label_now_playing: '再生中',
  label_quickshop: 'クイックショップ',
  label_add_to_cart_short: 'カートに追加',
  label_added_to_cart: 'カートに追加されました',
  view_cart_label: 'カートを見る',
  button_label_edit: '編集',
  label_cart_extend_heading: '購入商品を保護する',
  label_cart_extend_subheading:
    'メーカー保証期間終了後もNZXT製品を保護します。Extend（保証延長）は製品の交換、電源サージ、故障保護などをカバーします。',
  label_cart_affirm_heading: 'Affirmで後払い',
  label_cart_affirm_heading_0_apr: '0% APR ファイナンス',
  label_cart_affirm_subheading:
    'すぐに購入して、後払い。素早く簡単。隠れ手数料なし。チェックアウト時に月払いを承認を選択してください。',
  label_cta_cart_heading: '安心してご購入ください',
  label_deal_sold_out: 'セール品売り切れ',
  label_explore: '全て探す',
  label_ship_date: '発送予定日',
};

const cart = {
  cart_heading: 'お客様のカート',
  cart_heading_empty: 'カートは空です',
  cart_subheading_empty: 'お気に入りの商品を見つけましょう',
  cart_free_shipping_cta: '送料無料まであと25ドルです',
  cart_free_shipping_exclusion: '（カスタムとプリビルドPCを除く）',
  cart_free_shipping_promo_cta: '全ての注文で送料が無料',
  cart_heading_error: '何か問題が発生しました。もう一度お試しください。',
  cart_heading_success: 'ありがとうございました！',
  cart_label_subtotal: '小計',
  cart_label_taxes: '税金',
  cart_value_taxes: 'チェックアウト時に計算されます',
  cart_label_shipping: '配送料の見積もり',
  cart_value_shipping_free: '無料',
  cart_value_disclaimer:
    '*新品および / または修理済製品は、処理や出荷のタイミングが異なる場合があります。',
  cart_label_total: '合計',
  cart_button_checkout: 'チェックアウトに進む',
  cart_terms_message: '私は以下に同意します',
  cart_terms_link_text: '利用規約',
  cart_value_taxes_non_us: '含まれています',
  cart_value_shipping_calculated: 'チェックアウト時に計算されます',
  cart_consent_i_have_read: '私は以下を読みました',
  cart_consent_privacy_policy: 'プライバシーポリシー',
  cart_consent_privacy_policy_after: ' ',
  cart_au_terms_message: '私は以下に同意します',
  cart_au_terms_link: '利用規約',
  cart_au_consent_message:
    '私は、私の個人情報が以下に従い、収集および使用されることに同意します',
  cart_au_consent_link: 'プライバシーポリシー',
  cart_au_terms_text_1:
    'NZXT Pty Ltd (ABN 47 645 981 559)は、以下に従い、ご注文いただいた商品およびサービスを提供します',
  cart_au_terms_text_terms_link: '利用規約',
  cart_au_terms_text_2:
    'また、以下に従い、お客様から収集した個人情報を収集、使用、および開示します',
  cart_au_terms_text_privacy_link: 'プライバシーポリシー',
  cart_label_ssd: 'SSD',
  cart_label_hdd: 'HDD',
  cart_label_service: 'サービス',
  cart_label_software: 'ソフトウェア',
  cart_label_case: 'ケース',
  cart_label_motherboard: 'マザーボード',
  cart_label_cpu: 'CPU',
  cart_label_gpu: 'GPU',
  cart_label_power_supply: '電源',
  cart_label_cpu_cooler: 'CPUクーラー',
  cart_label_rgb: 'RGB',
  cart_label_fans: 'ファン',
  cart_label_ram: 'RAM',
  cart_label_monitors: 'モニター',
  cart_label_peripherals: '周辺機器',
  cart_label_extras: '追加品',
  cart_label_keycaps: 'キーキャップ',
  cart_label_keycap_accents: 'キーキャップアクセント',
  cart_label_switches: 'スイッチ',
  cart_label_keyboard_base: 'キーボードベース',
  cart_label_keycap_accent: 'アクセントキーキャップ',
  cart_label_accent_cable: 'アクセントケーブル',
  cart_label_mouse: 'マウス',
  cart_message_item_fallback: 'この商品',
  cart_message_build_item_oos_before: 'お客様のビルドにある商品は',
  cart_message_build_item_oos_after:
    '売り切れました。カートに追加するには、ここをクリックしてビルドを更新してください',
  cart_message_build_oos_v2:
    'ビルド内の商品が売り切れました。選択内容を更新するには、ここをクリックしてください。',
  cart_message_oos:
    'は現在在庫切れです。 チェックアウトに進むには、この商品を削除してください。',
};

const category = {
  explore_refurbished: 'ビルドを探す',
  explore_bulk: 'バルクを探す',
  bulk: 'バルク',
  refurbished: '修理済み',
  refurbished_cta:
    '完全にテスト、検査された修理済みビルドとパーツのお買い得情報をチェック。',
  bulk_cta:
    'ゲーム会社、大学、eスポーツ団体などに大量にビルドを提供するお手伝いをしてまいりました。',
};

const geoipBanner = {
  currently_browsing: '現在閲覧中',
  banner_region_text:
    'お住まいの地域に特化したショッピングやコンテンツを表示するために、地域を切り替えてください。',
  banner_button: '切り替え先',
  banner_country_text_shop: 'ショップ',
  banner_counrty_text:
    'または、お住まいの地域に応じた別の国を選択してください。',
  country_us: 'アメリカ合衆国',
  country_canada: 'カナダ',
  country_belgium: 'ベルギー',
  country_germany: 'ドイツ',
  country_luxembourg: 'ルクセンブルク',
  country_netherlands: 'オランダ',
  country_austria: 'オーストリア',
  country_france: 'フランス',
  country_italy: 'イタリア',
  country_spain: 'スペイン',
  country_uk: '英国',
  country_australia: 'オーストラリア',
  country_nz: 'ニュージーランド',
  country_japan: '日本',
  region_na: '北米',
  region_europe: 'ヨーロッパ',
  region_oceania: 'オセアニア',
  region_asia: 'アジア',
};

const ui = {
  loading: 'ローディング',
};

const footer = {
  label_language: '言語',
  label_country: '国',
  manage_cookie_preferences: 'Cookieの設定を管理する',
  all_rights_reserved: '無断転載を禁じます',
};

const search = {
  label_suggested_queries: '推奨クエリ',
  label_suggested_results: '推奨結果',
  label_serp_heading: '以下の検索結果',
  label_serp_not_found: '以下の検索結果が見つかりませんでした',
  label_serp_total: '検索結果の合計',
  label_serp_suggested: '以下の検索をお試しください',
};

const about = {
  label_customer_support_hours: 'カスタマーサポート受付時間',
  label_headquarters: '本社',
};

const product = {
  overview: '概要',
  tech_specs: '技術仕様',
  downloads: 'ダウンロード',
  message_invalid: '一時的に入手不可',
  message_out_of_stock: '売り切れ',
  message_low_stock: '在庫わずか',
  message_new_item: '新着',
  message_coming_soon: '近日発売',
  message_unavailable: '入手不可',
  title_highlights: 'ハイライト',
  title_shipping: '配送',
  title_in_the_box: '箱の中身',
  button_buy_now: '今すぐ購入',
  message_colors: '色（複数）',
  message_color: '色',
  message_size: 'サイズ',
  message_series: 'シリーズ',
  overview_anchor: '概要',
  tech_specs_anchor: '技術仕様',
  downloads_anchor: 'ダウンロード',
  related_anchor: '関連',
  customize_build_header: 'またはビルド',
  customize_build_subheader: 'このアイテムでカスタムPCをビルド',
  customize_kb_build_subheader: 'カスタムキーボードをビルド',
  buy_product_header: 'DIY（自作）',
  buy_product_subheader: '夢見ましょう。構築しましょう。やりましょう。',
  buy_label: '購入',
  retailers_subheader: '当社のパートナーでお買い物する。',
  link_all_retailers: '全ての小売店を表示',
  series_label: 'シリーズ',
  label_header_bundle: '一緒に購入',
  label_subheader_bundle:
    'エキスパートがおすすめする製品で、あなたのビルドを次のレベルへ',
  help_button_label: '今すぐチャット',
  help_button_heading: 'ご質問がおありですか？',
  help_button_subheading: 'エキスパートがいつでもお手伝いいたします。',
  configure_label: '設定する',
  documentation_label: 'ドキュメンテーション',
  drivers_label: 'ドライバー',
  miscellaneous_label: 'その他',
  resources_label: 'リソース',
  everything_you_need_label: '必要なもの全て',
  good_rating_label: 'グッド',
  better_rating_label: 'ベター',
  best_rating_label: 'ベスト',
  key_specs_label: '主な仕様',
  prebuild_upgrade_preproduct: '以下を選択すると',
  prebuild_upgrade_postproduct: 'ビルドをアップグレードします',
  prebuild_socket_cpu: 'CPU',
  prebuild_socket_gpu: 'GPU',
  prebuild_socket_memory: 'メモリー',
  prebuild_socket_motherboard: 'マザーボード',
  prebuild_socket_storage: 'ストレージ',
  prebuild_socket_psu: 'PSU',
  prebuild_heading_specs: '仕様',
  prebuild_subheading_specs: '現在の設定',
  prebuild_downgrade_preproduct: '以下を設定する',
  prebuild_downgrade_postproduct: '以下を選択します',
  prebuild_no: 'いいえ',
};

const collection = {
  message_invalid: '一時的に入手不可',
  message_out_of_stock: '売り切れ',
  message_low_stock: '在庫わずか',
  message_unavailable: '入手不可',
  message_new_item: '新着',
  message_coming_soon: '近日発売',
  button_buy_now: '今すぐ購入',
  message_colors: '色',
  learn_more: '詳しく知る',
  help_button_label: '今すぐチャット',
  help_button_heading: 'ご質問がおありですか？',
  help_button_subheading: '当社のエキスパートがいつでもお手伝いいたします。',
};

const news = {
  label_all_news: '全てのニュース',
};

const retailers = {
  label_regions: '地域',
  label_locations: '所在地',
  label_reseller_type: 'タイプ',
  option_label_all_regions: '全ての地域',
  option_label_all_locations: '全ての所在地',
  option_label_all_reseller_type: '全てのオプション',
  message_empty_state: '選択された条件に一致する小売業者はありません',
  hero_text: 'NZXT製品は、全ての地域のパートナーを通して購入できます',
};

const searchResults = {
  message_invalid: '一時的に入手不可',
  message_out_of_stock: '売り切れ',
  message_low_stock: '在庫わずか',
  message_new_item: '新着',
  message_coming_soon: '近日発売',
  message_unavailable: '入手不可',
};

const orderTracking = {
  title_order_status: '注文状況',
  label_cancelled_on: 'キャンセルされた日',
  label_shipping_date: '発送予定日',
  label_case: 'ケース',
  label_software: 'ソフトウェア',
  label_services: 'サービス',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'GPU',
  label_motherboard: 'マザーボード',
  label_storage: 'ストレージ',
  label_cooling: '冷却',
  label_psu: 'PSU',
};

const orderTrackingRoot = {
  title_order_tracking_root: '注文IDを入力してください',
  label_build_id: '注文ID',
  button_track: '注文を追跡',
  message_not_found: '見つかりません、もう一度お試しください',
};

const refurb = {
  label_all_products: '全製品',
  label_relevance: '関連度',
  label_price_low_high: '価格の安い順',
  label_price_high_low: '価格の高い順',
  label_name_a_z: '名前 A-Z',
  label_name_z_a: '名前 Z-A',
  label_case: 'ケース',
  label_software: 'ソフトウェア',
  label_services: 'サービス',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'GPU',
  label_motherboard: 'マザーボード',
  label_storage: 'ストレージ',
  label_cooling: '冷却',
  label_psu: 'PSU',
  label_other: 'その他',
  label_accessories: 'アクセサリー',
  label_fan: 'ファン',
  label_any_price: '任意の価格',
  label_no_products: '現在、入手可能な修理済コンポーネントはありません。',
  label_no_builds: '現在、入手可能な修理済ビルドはありません。',
};

const orderStatus = {
  confirmed_title: 'ご注文済みです',
  step_label: 'ステップ',
  confirmed_sub: 'ご注文を承りました。',
  processed_title: 'ご注文を確認しました',
  processed_sub: 'ご注文が受理され、お支払いが処理されました。',
  in_progress_title: '処理中です',
  in_progress_sub:
    'ご注文のピッキング & 組み立てが開始されました。現在ご注文はロックされており、変更/キャンセルの保証はいたしかねます。',
  shipped_title: '出荷しました',
  shipped_sub: 'ご注文の商品は配送中です！',
  cancelled_title: 'キャンセルされました',
  cancelled_sub: 'ご注文は問題なくキャンセルされました。',
  enh_ordered_sub: 'ご注文が受理され、キューに入れられました',
  enh_picking_title_bld: 'ビルドの準備中',
  enh_picking_title: 'ご注文を処理中です',
  enh_picking_sub_bld:
    'ご注文のピッキング & 組み立てが開始されました。ご注文の変更は保証いたしかねます。',
  enh_picking_sub:
    'ご注文の準備をしています。ご注文への変更は保証いたしかねます。',
  enh_build_title: 'ビルド中',
  enh_build_sub: 'ご注文の商品をビルドおよびテストしています',
  enh_packing_title: '出荷準備中',
  enh_packing_sub_bld:
    'ご注文の商品はビルドおよびテストされました。 配送のため梱包中です。',
  enh_packing_sub: 'ご注文は処理され、配送のため梱包中です。',
  enh_shipping_title: '配送中',
  enh_shipping_sub:
    'ご注文は配達のため配送業者に送られました。追跡番号は1営業日以内に更新され、お客様にEメールで送られます。',
};

const universal = {
  ...cart,
  ...userAccount,
  ...auth,
  ...formErrors,
  ...camCta,
  ...generic,
  ...helpCta,
  ...emailSignUp,
  ...geoipBanner,
  ...ui,
  ...footer,
  ...search,
  ...about,
  ...orderStatus,
};

// eslint-disable-next-line import/prefer-default-export
export const ja = {
  '/': {
    ...universal,
  },
  '/product/[slug]': {
    ...product,
    ...universal,
  },
  '/flex/[slug]': {
    ...product,
    ...universal,
  },
  '/collection/[slug]': {
    ...product,
    ...collection,
    ...universal,
  },
  '/partner/[slug]': {
    ...collection,
    ...universal,
  },
  '/sale/[slug]': {
    ...collection,
    ...universal,
  },
  '/about': {
    ...universal,
    ...about,
  },
  '/about/[slug]': {
    ...universal,
    ...about,
  },
  '/legal': {
    ...universal,
  },
  '/legal/[slug]': {
    ...universal,
  },
  '/news/[slug]': {
    ...news,
    ...universal,
    ...category,
  },
  '/news/page/[page]': {
    ...universal,
    ...category,
  },
  '/news/category/[category]': {
    ...universal,
    ...category,
  },
  '/news/category/[category]/page/[page]': {
    ...universal,
    ...category,
  },
  '/user/[slug]': {
    ...universal,
    ...build,
    ...keyboard,
  },
  '/software/[slug]': {
    ...universal,
  },
  '/category/[slug]': {
    ...category,
    ...universal,
  },
  '/category/[slug]/[subcategory-slug]': {
    ...category,
    ...universal,
  },
  '/build': {
    ...universal,
    ...build,
  },
  '/build/[slug]': {
    ...universal,
    ...build,
  },
  '/build/pc': {
    ...universal,
    ...build,
  },
  '/build/pc/[slug]': {
    ...universal,
    ...build,
  },
  '/build/keyboard/[slug]': {
    ...universal,
    ...build,
    ...keyboard,
  },
  '/build/[slug]/summary': {
    ...universal,
    ...build,
  },
  '/support': {
    ...universal,
  },
  '/refurbished/[slug]': {
    ...universal,
    ...refurb,
  },
  '/careers': {
    ...universal,
  },
  '/reviews/page/[page]': {
    ...universal,
  },
  '/careers/available-positions': {
    ...universal,
  },
  '/order-tracking/[slug]': {
    ...orderTracking,
    ...orderTrackingRoot,
    ...universal,
  },
  '/order-tracking': {
    ...orderTrackingRoot,
    ...universal,
  },
  '/retailers': {
    ...retailers,
    ...universal,
  },
  '/search': {
    ...searchResults,
    ...universal,
  },
  '/auth/[slug]': {
    ...universal,
  },
  universal,
};

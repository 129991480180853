import { setCookie } from 'nookies';
import {
  SYSTEM_IMPACT_ID_COOKIE_NAME,
  SYSTEM_IMPACT_COOKIE_MAX_AGE,
} from '@constants';

const setImpactIdCookie = (id?: string): void => {
  setCookie(null, SYSTEM_IMPACT_ID_COOKIE_NAME, id, {
    maxAge: SYSTEM_IMPACT_COOKIE_MAX_AGE,
    expires: new Date(Date.now() + SYSTEM_IMPACT_COOKIE_MAX_AGE * 1000),
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    sameSite: 'lax',
  });
};

export default setImpactIdCookie;

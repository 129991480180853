import { setCookie } from 'nookies';
import {
  SYSTEM_LOCALE_COOKIE_NAME,
  SYSTEM_COOKIE_MAX_AGE,
  DEFAULT_LOCALE,
} from '@constants';

const setLocaleCookie = (locale: string = DEFAULT_LOCALE): void => {
  setCookie(null, SYSTEM_LOCALE_COOKIE_NAME, locale, {
    maxAge: SYSTEM_COOKIE_MAX_AGE,
    expires: new Date(Date.now() + SYSTEM_COOKIE_MAX_AGE * 1000),
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    sameSite: 'lax',
  });
};

export default setLocaleCookie;

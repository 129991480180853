export const ANSI = 'ansi';
export const ISO = 'iso';
export const KB_NAME_MAP = {
  minitkl: 'MiniTKL',
  tkl: 'Tenkeyless',
  full: 'Full Size',
};

export const KB_CABLE_NAME_MAP = {
  KeyboardBase: {
    minitkl: 'MiniTKL',
    tkl: 'Tenkeyless',
    full: 'Full Size',
  },

  cable: {
    coiled: 'Coiled',
    straight: 'Straight',
  },
};

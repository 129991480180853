export const COOKIES = [
  '_fbp',
  '__kla_id',
  'kl_sessionid',
  '__cf_bm',
  'OptanonConsent',
  'kl_csrftoken',
  'OptanonAlertBoxClosed',
  '_ga',
  'ABTastySession',
  '__kla_off',
  '_kx',
];

export const SCRIPT_SRC =
  'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=';

/* eslint-disable consistent-return */
import { useEffect, useRef } from 'react';

/* https://overreacted.io/making-setinterval-declarative-with-react-hooks/ */

const useTimeout = (callback: () => void, delay: number | null): void => {
  const savedCallback = useRef(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick(): void {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setTimeout(tick, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
};

export default useTimeout;

import type { ShopifyByHandleResponse } from '@framework/api/product';

const getShopifyProductByHandle = async (
  handle: string
): Promise<ShopifyByHandleResponse> => {
  const data = await fetch('/api/maxify/product', {
    method: 'PUT',
    body: JSON.stringify({ handle }),
  }).then(res => res.json());

  return data;
};

export default getShopifyProductByHandle;

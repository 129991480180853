import { useIntl } from 'react-intl';

const useTranslation = (
  id: string,
  values?: Record<string, string | number>
): string => {
  const { formatMessage } = useIntl();

  return formatMessage({ id }, values);
};

export default useTranslation;

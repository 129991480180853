/* eslint-disable default-param-last */
import { setCookie } from 'nookies';
import {
  SHOPIFY_CART_ID_COOKIE_NAME,
  SYSTEM_COOKIE_MAX_AGE,
  DEFAULT_COUNTRY_CODE,
} from '@constants';

const setShopifyCartCookie = (
  country: string = DEFAULT_COUNTRY_CODE,
  cartId: string
): void => {
  setCookie(null, `${SHOPIFY_CART_ID_COOKIE_NAME}_${country}`, cartId, {
    maxAge: SYSTEM_COOKIE_MAX_AGE,
    expires: new Date(Date.now() + SYSTEM_COOKIE_MAX_AGE * 1000),
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    sameSite: 'lax',
  });
};

export default setShopifyCartCookie;

/* eslint-disable max-len */
const userAccount = {
  user_settings_title: 'Cuenta',
  user_settings_change_password: 'Cambiar contraseña',
  user_settings_save: 'Guardar',
  user_settings_cancel: 'Cancelar',
  user_profile_heading: 'Perfil',
  user_saved_builds_heading: 'Sistemas guardados',
  user_saved_builds_bld_name: 'Nombre del sistema',
  user_saved_builds_bld_date: 'Fecha de creación',
  user_saved_builds_bld_item_part: 'Componente',
  user_saved_builds_delete_bld: 'Eliminar BLD',
  user_saved_builds_resume_bld: 'Reanudar BLD',
  user_saved_builds_hero_title: 'Diseñado para exhibir tu montaje',
  user_saved_builds_hero_text:
    'El compacto diseño vertical es compatible con la mayoría de las gráficas de tamaño completo del mercado, y así puedas conservar el espacio en tu escritorio sin sacrificar la potencia gráfica.',
  user_saved_builds_pc_heading: 'Montajes de PC',
  user_saved_builds_keyboard_heading: 'Montajes de teclado',
  user_saved_builds_view_pc: 'Ver montajes de PC',
  user_saved_builds_view_keyboard: 'Ver montajes de teclado',
  user_saved_builds_no_pc: 'No tienes ningún montaje de PC guardado',
  user_saved_builds_no_kb: 'No has guardado tu montaje de teclado',
  user_order_history_heading: 'Historial de pedidos',
  user_order_history_date: 'Fecha',
  user_order_history_order_number: 'Número de pedido',
  user_order_history_status: 'Estado',
  user_order_history_amount: 'Importe',
  user_order_history_view: 'Ver recibo',
  user_order_history_empty_view: 'No tienes pedidos en el historial.',
  user_order_history_shipping: 'Dirección de envío',
  user_order_history_billing: 'Dirección de facturación',
  user_order_history_item: 'Artículo',
  user_order_history_item_image: 'Imagen del artículo',
  user_order_history_quantity: 'Cantidad',
  user_order_history_order: 'N.° de pedido',
  user_support_heading: 'Asistencia y devoluciones',
  user_data_heading: 'Petición de datos',
  user_terms_heading: 'Condiciones',
  user_terms_empty: 'Aún no tienes condiciones firmadas.',
  user_support_subject_min_length:
    'El asunto debe tener al menos tres caracteres.',
  user_support_message_placeholder:
    'Detalla los motivos del contacto. Si es una devolución, indica el número del pedido.',
  user_support_update_button: 'Actualizar',
  user_active_tickets_heading: 'Incidencias activas',
  user_active_tickets_chat: 'Añadir un mensaje',
  user_active_tickets_no_chat_history: 'No tienes chats en el historial.',
  view_chat_button: 'Ver chat',
  close_chat_button: 'Cerrar chat',
  field_required_message: 'Este campo es obligatorio.',
  label_accept: 'Aceptar',
  label_reason_for_contact: 'Motivo del contacto',
  label_country: 'País',
  label_region: 'Región',
  label_subject: 'Asunto',
  label_product: 'Producto',
  label_message: 'Mensaje',
  label_requestor_type: 'Tipo de solicitante',
  label_country_of_residence: 'País de residencia',
  label_description: 'Descripción',
  placeholder_description: 'Descripción de tu solicitud',
  label_submit: 'Enviar',
  message_no_support_requests: 'No tienes solicitudes de asistencia activas.',
  label_ticket_number: 'Número de incidencia',
  label_date_created: 'Fecha de creación',
  label_status: 'Estado',
  label_department: 'Departamento',
  subheading_active_ticket:
    'Si necesitas ayuda o quieres devolver un artículo, ponte en contacto con el servicio de soporte.',
  label_create_support_request: 'Crear solicitud de soporte',
  button_comment: 'Comentario',
  label_for_myself: 'Para mí',
  label_parent: 'Progenitor',
  label_legal_rep: 'Representante legal',
  label_relative: 'Familiar',
  label_guardian: 'Tutor legal',
  label_accept_generic_terms: 'He leído las condiciones y las acepto.',
  error_view_message: '¡Uy! Algo ha salido mal. Vuelve a intentarlo.',
  signout_view_message: 'Se ha cerrado la sesión.',
  birthday_error_message: 'Introduce una fecha de nacimiento válida.',
  label_view_saved: 'Ver todos los montajes guardados',
};

const formErrors = {
  invalid_email: 'Introduce una dirección de correo electrónico válida.',
  username_full_pattern_message:
    'El nombre de usuario debe tener entre 6 y 30 caracteres. Puedes usar letras, números y puntos, pero el nombre no puede empezar ni acabar con puntos consecutivos ni tampoco contenerlos.',
  password_pattern_message:
    'La contraseña debe contener al menos seis caracteres, una mayúscula, una minúscula y un número.',
  username_min_length_message:
    'Usa seis caracteres o más para el nombre de usuario.',
  passwords_must_match: 'Las contraseñas deben que coincidir.',
  invalid_current_password:
    'No se ha podido actualizar la contraseña. El valor indicado como contraseña actual es incorrecto.',
  must_accept_partner_program_terms:
    'Debes aceptar las condiciones del programa de socios para hacerte socio de NZXT.',
  must_accept_tos_terms:
    'Debes aceptar las condiciones del servicio para abrirte una cuenta en NZXT.',
  must_accept_generic_terms: 'Debes aceptar estas condiciones.',
};

const auth = {
  user_auth_agreement: 'Acepto la',
  user_auth_privacy_policy: 'Política de privacidad de NZXT',
  user_auth_and: 'y he leído las',
  user_auth_terms: 'Condiciones de uso',
  error_invalid_request: 'Solicitud no válida',
  error_invalid_token: 'Token de autentificación de usuario no válido',
  error_session_expired:
    'La sesión ha expirado. Vuelve a iniciar sesión para continuar.',
  user_forgot_required_email:
    'La dirección de correo electrónico es necesaria.',
  user_forgot_success_message:
    '¡Gracias! Si hay una cuenta asociada a esta dirección de correo electrónico, enviaremos las instrucciones para restablecer la contraseña inmediatamente.',
  error_email_exists: 'Esta dirección de correo electrónico ya está en uso.',
  user_email_verification_heading:
    'Verificación de la dirección de correo electrónico',
  error_email_not_verified:
    'La dirección de correo electrónico aún no se ha verificado. Para volver a enviar el correo electrónico de verificación, haz clic en',
  error_email_already_verified:
    'La dirección de correo electrónico ya ha sido verificada. Inicia la sesión a continuación.',
  user_email_verification_success_message:
    'Tu dirección de correo electrónico se ha verificado correctamente. Inicia la sesión a continuación.',
  error_username_unavailable: 'Este nombre de usuario no está disponible.',
  error_reset_password_same_as_old_password:
    'Esta contraseña es la misma que la anterior. Por favor, elige otra.',
  error_reset_password_link_expired:
    'El enlace para restablecer la contraseña ha expirado.',
  error_reset_password_link_invalid:
    'El enlace para restablecer la contraseña no es válido.',
  user_forgot_password_subheading:
    'Introduce tu dirección de correo electrónico para recibir instrucciones sobre cómo restablecer la contraseña.',
  user_reset_password_heading: 'Restablece tu contraseña',
  user_reset_password_subheading: 'Por favor, introduce una contraseña nueva.',
  user_reset_password: 'Restablecer contraseña',
  user_reset_success_message: 'Has restablecido tu contraseña correctamente.',
  user_login_text: 'Inicia sesión con Google',
  user_login_remember: 'Recuérdame.',
  user_login_forgot_password: 'He olvidado mi contraseña.',
  user_login_wrong_email_or_password:
    'Dirección de correo electrónico o contraseña incorrectas',
  user_login_success_message: 'Has iniciado sesión correctamente.',
  error_too_many_requests:
    'Has hecho demasiados intentos. Espera unos minutos o ponte en contacto con el servicio de soporte.',
  error_user_not_found: 'Usuario no encontrado',
  user_sign_in_sign_up: 'Iniciar sesión / Registrarse',
  user_sign_out: 'Cerrar sesión',
  user_sign_in: 'Iniciar sesión',
  user_account_updates: 'Actualizaciones de NZXT',
  user_account_no_updates:
    'No hay actualizaciones recientes. Vuelve más tarde.',
  support_center_link: 'Centro de soporte',
  error_google_account_exists:
    'Ya has creado una cuenta con Google. Por favor, inicia sesión.',
  error_local_account_exists:
    'Parece que ya tienes una cuenta. Por favor, inicia sesión.',
  user_sign_up: 'Registrarse',
  user_sign_up_text: 'Registrarse con Google',
  user_sign_up_opt_in:
    'Regístrate para recibir correos electrónicos con actualizaciones de NZXT sobre productos, BLD y CAM.',
  user_sign_up_success_verify_message:
    'Te hemos enviado un correo electrónico con un enlace de activación. Haz clic en el enlace para activar tu cuenta. Si no lo haces, la cuenta permanecerá inactiva y no recibirás más mensajes. Si no recibes el correo electrónico en unos minutos, mira la carpeta de correo no deseado.',
  user_info_updated_success_message:
    'Tu información se ha actualizado correctamente.',
  user_info_newsletter_confirmation_message:
    'Consulta tu correo electrónico para realizar la confirmación.',
  user_email_sign_in: 'O sigue con',
  user_no_account: '¿No eres miembro?',
  user_create_account: 'Crear cuenta',
  user_email_sign_up_text: 'O regístrate con',
  user_have_account: '¿Ya tienes cuenta?',
  open_login_view: 'Abrir vista de inicio de sesión',
  error_newsletter_subscription:
    'No podemos suscribirte / darte de baja del boletín de noticias en estos momentos. Vuelve a intentarlo más tarde.',
  label_email_address: 'Dirección de correo electrónico',
  label_password: 'Contraseña',
  label_current_password: 'Contraseña actual',
  label_confirm_password: 'Confirma la contraseña',
  label_new_password: 'Contraseña nueva',
  label_confirm_new_password: 'Confirma la contraseña nueva',
  label_username: 'Nombre de usuario',
  label_first_name: 'Nombre',
  label_last_name: 'Apellidos',
  label_preferred_pronouns: 'Pronombres preferidos',
  label_search_for_help_articles: 'Buscar artículos de ayuda',
  label_birthday: 'Cumpleaños',
  label_location: 'Ubicación',
};

const build = {
  custom_bld_modal_heading: 'Monta tu PC',
  custom_bld_modal_subheading: 'Selecciona tu chipset y presupuesto',
  custom_bld_unavailable: 'Selecciona otro artículo.',
  custom_bld_issue: 'Los artículos siguientes requieren una solución.',
  custom_bld_title: 'BLD personalizado',
  custom_bld_lets_get_started: 'Empecemos',
  custom_bld_destrutive_action: 'Advertencia: Restablecimiento del sistema',
  custom_bld_confirm_reset:
    'El sistema se va a restablecer con el chipset siguiente',
  custom_bld_keyboard_confirm_reset:
    'Su construcción se restablecerá con el siguiente conjunto de chips:',
  custom_bld_choose_chipset: 'Elige tu chipset',
  custom_bld_chipset: 'Chipset',
  custom_bld_intel: 'Intel',
  custom_bld_amd: 'AMD',
  custom_bld_explore: 'Explorar',
  custom_bld_select: 'Seleccionar',
  review_bld_button: 'Revisar BLD',
  custom_bld_summary: 'Resumen',
  custom_bld_budget: 'Presupuesto',
  custom_bld_specs: 'Especificaciones',
  custom_bld_case: 'Caja',
  custom_bld_cases: 'Cajas',
  custom_bld_caseCooling: 'Refrigeración',
  custom_bld_fans: 'Ventiladores',
  custom_bld_cpu: 'Procesador',
  custom_bld_gpu: 'Gráfica',
  custom_bld_ram: 'RAM',
  custom_bld_ssd: 'SSD',
  custom_bld_hdd: 'HDD',
  custom_bld_specials: 'Promociones',
  custom_bld_colors: 'Colores',
  custom_bld_color: 'Color',
  custom_bld_black: 'Negro',
  custom_bld_red: 'Rojo',
  custom_bld_white: 'Blanco',
  custom_bld_grey: 'Gris',
  custom_bld_blue: 'Azul',
  custom_bld_purple: 'Morado',
  custom_bld_audio: 'Sonido',
  custom_bld_extras: 'Extras',
  custom_bld_core: 'Núcleo',
  custom_bld_motherboard: 'Placa base',
  custom_bld_motherboards: 'Placas base',
  custom_bld_powerSupplies: 'Fuentes de alimentación',
  custom_bld_powerSupply: 'Fuente de alimentación',
  custom_bld_services: 'Servicios',
  custom_bld_software: 'Software',
  custom_bld_monitors: 'Monitores',
  custom_bld_peripherals: 'Periféricos',
  custom_bld_lighting: 'Iluminación',
  custom_bld_headset: 'Auriculares',
  custom_bld_keyboard: 'Teclado',
  custom_bld_all: 'Todo',
  custom_bld_mouse: 'Ratón',
  custom_bld_storage: 'Almacenamiento',
  custom_bld_streaming_ready: 'Preparado para streaming',
  custom_bld_rgb_lighting: 'Iluminación RGB',
  custom_bld_vr_ready: 'Preparado para RV',
  custom_bld_wifi: 'Wifi',
  custom_bld_from: 'Desde',
  custom_bld_bluetooth: 'Bluetooth',
  custom_bld_save_build_message:
    'Tu sistema se ha guardado correctamente. Puedes verlo en el apartado «Sistemas guardados» de tu cuenta.',
  custom_bld_share_build_message:
    'Tu sistema se ha guardado y copiado correctamente.',
  help_me_decide: 'Ayúdame a decidir.',
  save_build: 'Guardar sistema',
  start_over: 'Volver a empezar',
  summary: 'Resumen',
  build_summary: 'Resumen del sistema',
  load_build: 'Cargar sistema',
  load: 'Cargar',
  save: 'Guardar',
  restart: 'Reiniciar',
  share: 'Compartir',
  my_build_label: 'Mi sistema',
  est_shipping: 'Gastos de envío previstos',
  add_to_build: 'Añadir al sistema',
  message_invalid: 'No disponible temporalmente',
  message_out_of_stock: 'Agotado',
  message_low_stock: 'Stock bajo',
  message_new_item: 'Nuevo',
  message_coming_soon: 'Próximamente',
  message_best_seller: 'Más vendido',
  message_is_locked: 'No compatible',
  button_label_select: 'Seleccionar',
  button_label_selected: 'Seleccionado',
  button_label_add: 'Añadir',
  button_label_added: 'Añadido',
  button_label_update: 'Actualizar',
  button_label_remove: 'Quitar',
  button_label_info: 'Información',
  button_label_more_info: 'Más información',
  button_label_options_info: 'Opciones e información',
  button_label_continue_to: 'Continuar con el sistema',
  button_label_edit: 'Editar',
  build_fps_label:
    'Las velocidades de FPS se miden a una resolución de 2560 x 1440 utilizando el ajuste gráfico más alto de cada juego.',
  build_includes: 'Incluye',
  custom_bld_boost: 'Potencia tu configuración',
  custom_bld_fps_unavailable: 'Pendiente',
  custom_bld_amd_help:
    'Los PC Intel han sido los más vendidos durante los últimos años, aunque ahora los PC AMD son increíbles para jugar. Te ayudaremos con ambas opciones, seleccionando placas base y sistemas de refrigeración compatibles, para que no tengas que pensarlo tú. Te recomendamos que equilibres el rendimiento de FPS que presentamos con tu presupuesto.',
  custom_bld_to_see_this: 'Para ver esta selección, tienes que',
  custom_bld_change_chipset: 'cambiar el chipset.',
  custom_bld_prebuild_upsell:
    'O elige sistemas premontados rápidos y sencillos.',
  no_item_selected: 'No has seleccionado ningún artículo.',
  build_change_label: 'Sistema modificado',
  invalid_action_label: 'No permitido',
  success_label: 'Éxito',
  build_info_message: 'Información',
  reset_label: 'Restablecimiento del sistema',
  complete_build_label: 'Termina tu sistema.',
  back_to_build_label: 'Volver a tu sistema',
  complete_build_sub_label: 'Extras y periféricos',
  back_to_build_sub_label: 'Componentes esenciales de tu sistema',
  capacity_label: 'Capacidad',
  option_label: 'Opción',
  options_label: 'Opciones',
  max_speed: 'Velocidad máx.',
  modular_label: 'Modular',
  midtower_label: 'Mid-Tower',
  streaming_label: 'Streaming',
  save_and_exit: 'Guardar y Salir',
  exit: 'Salir',
  view_cart_label: 'Ver carrito',
  cart_subtotal_label: 'Subtotal del carrito',
  start_build_label: 'Iniciar construcción',
  new_build_label: 'Nuevo montaje',
  continue_saved_build_label: 'Continuar montaje guardado',
  continue_progress_build_label: 'Continuar con el montaje en progreso',
  continue_cart_build_label: 'Revisar montaje en el carrito',
  core_label: 'Base',
  extras_label: 'Complementos',
  summary_label: 'Resumen',
  share_build_pc: 'Montaje de PC personaliado',
  share_build_kb: 'Montaje de Teclados personalizados',
  build_summary_label: 'Resumen del montaje de tu teclado',
  pc_hub_card_heading: 'Ordenadores gaming custom',
  pc_hub_card_subheading:
    'Monta el PC de tus sueños en función de tu presupuesto y optimizado para los juegos que mas te gustan.',
  kb_hub_card_heading: 'Teclados personalizados',
  kb_hub_card_subheading:
    'Monta un teclado y un ratón personalizados que se adapten a tu espacio y estilo de juego',
};

const keyboard = {
  keyboard_label: 'Teclado',
  keyboard_size: 'Tamaño del teclado',
  keyboard_base: 'Base del teclado',
  keyboard_primay_base_color: 'Color de la base',
  keyboard_layout: 'Disposición del teclado',
  keyboard_summary: 'Resumen del teclado',
  accent_keycaps: 'Teclas destacadas',
  cabletype: 'Cable personalizado',
  additional_cable_color: 'Color del cable',
  cable: 'Cable',
  additional_cable: 'Cable adicional',
  primary_keycaps: 'Teclas base',
  switches: 'Switches',
  size: 'Tamaño',
  layout: 'Diseño',
  language: 'Idioma',
  mouse_pad_label: 'Alfombrilla de ratón',
  desk_mat_label: 'Alfombrilla de escritorio',
  tools_label: 'Herramientas',
  wrist_rest_label: 'Reposamuñecas',
  mouse_label: 'Ratón',
  cable_description:
    'Cable de color opcional. Se incluirá un cable negro estándar de 2 metros.',
  cable_color_description: 'Color del cable premium',
  language_description: 'Determina la posición de las teclas Q, W, Z y A.',
  layout_description: 'Determina el tamaño y la posición de las teclas.',
  keycap_accent_description:
    'Color opcional para las teclas Escape, Enter, Flecha y Numpad Enter.',
  primary_keycap_description: 'PBT sublimado, perfil Cherry.',
  switch_description:
    'Los switches de un teclado definen la experiencia de tecleo cada vez que se pulsa una tecla.',
  keyboard_base_color_description: 'Color principal de la base del teclado',
  keyboard_base_size_description:
    'El tamaño del teclado determina el número de teclas y las dimensiones que tiene.',
  mouse_description: 'Ratón ligero y ambidiestro',
  summary_description: 'Resumen de la construcción del teclado',
  premium_label: 'Premium',
  base_label: 'Base',
  keyboard_warning:
    'Por favor, selecciona los siguientes artículos para continuar tu montaje:',
  switch_reset_warning:
    'Tus switches se han reestablecido. Por favor, selecciona nuevos switches.',
  keycap_reset_warning:
    'Tus teclas se han reestablecido. Por favor, selecciona nuevas teclas.',
  base_type: 'Tipo de montaje',
  build_type_description: `Selecciona el tipo de teclado que te gustaría configurar`,
  full_custom_label: 'Personalizado',
  barebones_disclaimer: `Cambia el tipo de montaje para seleccionar este artículo`,
  none_label: 'Ninguno',
};

const camCta = {
  cam_description:
    'Gestiona el rendimiento, las temperaturas y los dispositivos desde una sola aplicación.',
  cam_windows_10: 'WINDOWS 10 DE 64 BITS',
};

const helpCta = {
  help_description:
    'Consigue guías exhaustivas y asistencia para todos tus productos NZXT, así como ayuda sobre la garantía, las devoluciones y cualquier cuestión técnica.',
};

const emailSignUp = {
  email_stay_in_touch: 'Estemos en contacto',
  email_sub_copy:
    'Mantente al día sobre los últimos lanzamientos, ofertas y noticias de NZXT.',
  email_legal:
    'Al registrarte, aceptas la Política de Privacidad y los Términos y Condiciones de NZXT y CAM. ',
  email_notify: 'Notifícame',
  email_notify_stock:
    'Recibe una notificación cuando este artículo esté disponible.',
};

const generic = {
  prebuild_fan_label: 'Actualizaciones de ventiladores',
  fps_disclaimer: 'Probado en configuraciones ultra altas',
  prebuild_specs_disclaimer: 'Especificaciones predeterminadas',
  explore: 'Explorar',
  cam_title: 'NZXT CAM',
  free_download: 'Descarga gratuita',
  details: 'Detalles',
  help_amp_support: 'Ayuda y asistencia técnica',
  help_and_support: 'Ayuda y asistencia técnica',
  read_more: 'Más información',
  privacy_policy: 'Política de privacidad',
  terms_conditions: 'Condiciones',
  and: 'y',
  or: 'o',
  send: 'enviar',
  return_to: 'volver a',
  previous: 'Anterior',
  next: 'Siguiente',
  home: 'Inicio',
  keep_browsing: 'Seguir explorando',
  create_build: 'Crear sistema',
  learn_more: 'Más información',
  add_to_cart: 'Añadir al carrito',
  share_build: 'Compartir sistema',
  view: 'Ver',
  close: 'Cerrar',
  price: 'Precio',
  cancel: 'Cancelar',
  here: 'aquí',
  ok: 'Aceptar',
  button_start_bld: 'Empezar BLD',
  message_on_sale: 'Rebajas',
  button_buy: 'Comprar',
  button_start_build: 'Empieza tu monaje',
  view_build: 'Ver sistema',
  button_back: 'Volver',
  build: 'Sistema',
  performance: 'Rendimiento',
  pending: 'Pendiente',
  error_generic: 'Algo ha salido mal. Vuelve a intentarlo más tarde.',
  label_support: 'Asistencia',
  contact_support: 'Contactar con el servicio de asistencia',
  browse_articles: 'Explorar artículos',
  search_support_articles: 'Buscar artículos de ayuda',
  message_error: '¡Vaya! Parece que algo ha salido mal.',
  estimated_performance_label: 'Rendimiento previsto',
  see_estimated_performance_label: 'Ver rendimiento previsto',
  estimated_fps: 'Estimated frames per second (FPS)',
  title_related_items: 'Puede que también te guste',
  qty: 'Cant.',
  category_from: 'Desde',
  reviews_label: 'Opiniones',
  faqs_label: 'FAQs',
  message_out_of_stock: 'Agotado',
  message_low_stock: 'Stock bajo',
  message_new_item: 'Nuevo',
  message_coming_soon: 'Próximamente',
  message_best_seller: 'Más vendido',
  message_unavailable: 'No disponible',
  message_clearance: 'Liquidación',
  message_invalid: 'No disponible temporalmente',
  message_featured_items: 'Productos presentados en la imagen',
  message_colors: 'Colores',
  message_color: 'Color',
  label_return_policy: 'Política de devoluciones',
  label_warranty_policy: 'Condiciones de garantía',
  label_shop: 'Tienda',
  label_days: 'Días',
  label_hours: 'Horas',
  label_minutes: 'Minutos',
  label_seconds: 'Segundos',
  label_promotion: 'Ver promoción',
  label_promotions: 'Promociones',
  monthly_payments: 'Pagos mensuales disponibles',
  message_promotion: 'Promoción',
  discount_label: 'de descuento',
  off_label: 'Apagado',
  on_label: 'Encendido',
  choose_label: 'Elige',
  move_to_previous_label: 'Ir a la diapositiva anterior',
  move_to_next_label: 'Pasar a la diapositiva siguiente',
  active_slide_label: 'Diapositiva activaa',
  move_to_inactive_slide: 'Mover a la diapositiva inactiva',
  color_options: 'Opciones de color',
  upsell_modal_label: 'A menudo se compran juntos',
  no_thanks_label: 'No, gracias',
  label_deal_ends: 'La oferta termina en',
  label_ending_soon: 'La oferta termina pronto',
  label_show_more: 'Ver más',
  label_show_less: 'Ver menos',
  label_case: 'Caja',
  label_software: 'Software',
  label_services: 'Servicios',
  label_ram: 'RAM',
  label_cpu: 'Procesador',
  label_gpu: 'Gráfica',
  label_motherboard: 'Placa base',
  label_storage: 'Almacenamiento',
  label_cooling: 'Refrigeración',
  label_psu: 'Fuente de alimentación',
  label_warranty: 'Garantía',
  label_accessories: 'Accesorios',
  label_fps: 'FPS',
  label_feature: 'Característica',
  label_more_videos: 'Más vídeos',
  label_now_playing: 'Now Playing',
  label_quickshop: 'Compra Rápida',
  see_full_details_label: 'Ver detalles completos',
  label_add_to_cart_short: 'Añadir al carrito',
  label_added_to_cart: 'Añadido a la cesta',
  view_cart_label: 'Ver carrito',
  button_label_edit: 'Editar',
  label_cart_extend_heading: 'Protect Your Purchase',
  label_cart_extend_subheading:
    'Keep your NZXT products protected after the manufacturer warranty expires. Extend covers product replacements, power surges, failure protection, and more.',
  label_cart_affirm_heading: 'Pay Later with Affirm',
  label_cart_affirm_heading_0_apr: '0% APR Financing',
  label_cart_affirm_subheading:
    'Buy now, pay over time. Quick and easy. No hidden fees. Select "Affirm monthly payments" during checkout.',
  label_cta_cart_heading: 'Purchase With Confidence',
  label_deal_sold_out: 'Oferta finalizada',
  label_explore: 'Descubre todos los',
  label_ship_date: 'Estimated Ship Date',
  message_shipping_date: 'Ships by',
};

const cart = {
  cart_heading: 'Tu carrito',
  cart_heading_empty: 'Tu carrito está vacío.',
  cart_subheading_empty: 'Busca productos que te encantarán.',
  cart_free_shipping_cta: 'Te faltan 25 € para que el envío te salga gratis.',
  cart_free_shipping_exclusion: '(excepto PC personalizados y premontados)',
  cart_free_shipping_promo_cta: `Envío gratuito en todos los pedidos`,
  cart_heading_error: 'Algo ha salido mal. Vuelve a intentarlo.',
  cart_heading_success: '¡Gracias!',
  cart_label_subtotal: 'Subtotal',
  cart_label_taxes: 'Impuestos',
  cart_value_taxes: 'Calculados en la página de pago',
  cart_label_shipping: 'Gastos de envío previstos',
  cart_value_shipping_free: 'GRATIS',
  cart_value_disclaimer:
    '*Puede que los productos nuevos o reacondicionados se tramiten y envíen en plazos distintos.',
  cart_label_total: 'Total',
  cart_button_checkout: 'Ir al pago',
  cart_terms_message: 'Acepto las',
  cart_terms_link_text: 'Condiciones del servicio',
  cart_value_taxes_non_us: 'Incluidos',
  cart_value_shipping_calculated: 'Calculados en la página de pago',
  cart_consent_i_have_read: 'He leído la',
  cart_consent_privacy_policy: 'Política de privacidad',
  cart_consent_privacy_policy_after: ' ',
  cart_au_terms_message: 'Acepto las',
  cart_au_terms_link: 'Condiciones del servicio',
  cart_au_consent_message:
    'Doy mi consentimiento para que se recojan y se usen mis datos personales conforme a la',
  cart_au_consent_link: 'Política de privacidad',
  cart_au_terms_text_1:
    'NZXT Pty Ltd (ABN 47 645 981 559) ofrece los productos y servicios que has pedido conforme con sus',
  cart_au_terms_text_terms_link: 'Condiciones',
  cart_au_terms_text_2:
    'y recoge, utiliza y difunde los datos personales que recopila sobre usted según su',
  cart_au_terms_text_privacy_link: 'Política de privacidad',
  cart_label_ssd: 'SSD',
  cart_label_hdd: 'HDD',
  cart_label_service: 'Servicio',
  cart_label_software: 'Software',
  cart_label_case: 'Caja',
  cart_label_motherboard: 'Placa base',
  cart_label_cpu: 'Procesador',
  cart_label_gpu: 'Gráfica',
  cart_label_power_supply: 'Fuente de alimentación',
  cart_label_cpu_cooler: 'Refrigerador de procesador',
  cart_label_rgb: 'RGB',
  cart_label_fans: 'Ventiladores',
  cart_label_ram: 'RAM',
  cart_label_monitors: 'Monitores',
  cart_label_peripherals: 'Periféricos',
  cart_label_extras: 'Extras',
  cart_label_keycaps: 'Teclas',
  cart_label_keycap_accents: 'Teclas destacadas',
  cart_label_switches: 'Interruptores',
  cart_label_keyboard_base: 'Base del teclado',
  cart_label_keycap_accent: 'Tapas de teclado acentuadas',
  cart_label_accent_cable: 'Cable personalizado',
  cart_label_mouse: 'Ratón',
  cart_message_item_fallback: 'Este artículo',
  cart_message_build_item_oos_before: 'Un componente de esta configuración,',
  cart_message_build_item_oos_after:
    'está actualmente agotado. Por favor, elimine este artículo para proceder a la compra.',
  cart_message_build_oos_v2:
    'Un artículo de tu selección se ha agotado. Haz clic aquí para actualizar tu selección.',
  cart_message_oos:
    'se ha agotado. Por favor, haga clic aquí para actualizar su construcción con el fin de añadirlo a la cesta.',
};

const category = {
  explore_refurbished: 'Explorar sistemas',
  explore_bulk: 'Explorar artículos al por mayor',
  bulk: 'Artículos al por mayor',
  refurbished: 'Reacondicionado',
  refurbished_cta:
    'Echa un vistazo a nuestros ordenadores y componentes reacondicionados, totalmente revisados y probados.',
  bulk_cta:
    'Hemos ayudado a suministrar ordenadores al por mayor a empresas de gaming, universidades, organizaciones de eSports y más.',
};

const geoipBanner = {
  currently_browsing: 'Explorando ahora',
  banner_region_text:
    'Cambia de región para comprar o ver contenido específico de tu ubicación.',
  banner_button: 'Cambiar a',
  banner_country_text_shop: 'Compre',
  banner_counrty_text:
    'o seleccione un país diferente específico para su ubicación.',
  country_us: 'Estados Unidos',
  country_canada: 'Canadá',
  country_belgium: 'Bélgica',
  country_germany: 'Alemania',
  country_luxembourg: 'Luxemburgo',
  country_netherlands: 'Países Bajos',
  country_austria: 'Austria',
  country_france: 'Francia',
  country_italy: 'Italia',
  country_spain: 'España',
  country_uk: 'Reino Unido',
  country_australia: 'Australia',
  country_nz: 'Nueva Zelanda',
  country_japan: 'Japón',
  region_na: 'América del Norte',
  region_europe: 'Europa',
  region_oceania: 'Oceanía',
  region_asia: 'Asia',
};

const ui = {
  loading: 'Cargando',
};

const footer = {
  label_language: 'Idioma',
  label_region: 'Región',
  manage_cookie_preferences: 'Gestionar preferencias de cookies',
  all_rights_reserved: 'Todos los derechos reservados',
};

const search = {
  label_suggested_queries: 'Consultas sugeridas',
  label_suggested_results: 'Resultados sugeridos',
  label_serp_heading: 'Buscar resultados de',
  label_serp_not_found: 'Ningún resultado de',
  label_serp_total: 'resultados en total',
  label_serp_suggested: 'Prueba a buscar',
};

const about = {
  label_customer_support_hours: 'Horario de atención al cliente',
  label_headquarters: 'Sede central',
};

const product = {
  overview: 'Resumen',
  tech_specs: 'Especificaciones técnicas',
  downloads: 'Descargas',
  message_invalid: 'No disponible temporalmente',
  message_out_of_stock: 'Agotado',
  message_low_stock: 'Stock bajo',
  message_new_item: 'Nuevo',
  message_coming_soon: 'Próximamente',
  message_unavailable: 'No disponible',
  title_highlights: 'Destacados',
  title_shipping: 'Envío',
  title_in_the_box: 'En el interior de la caja',
  button_buy_now: 'Comprar',
  message_colors: 'Colores',
  message_color: 'Color',
  message_size: 'Tamaño',
  message_series: 'Serie',
  overview_anchor: 'Resumen',
  tech_specs_anchor: 'Especificaciones técnicas',
  downloads_anchor: 'Descargas',
  related_anchor: 'Relacionado',
  customize_build_header: 'O monta',
  customize_build_subheader: 'Monta un PC personalizado con este artículo',
  buy_product_header: 'Hazlo tú mismo/a',
  buy_product_subheader: 'Imagínatelo. Móntalo. Hazlo.',
  buy_label: 'Comprar',
  retailers_subheader: 'Compra a nuestros distribuidores.',
  link_all_retailers: 'Ver todos los distribuidores',
  series_label: 'Serie',
  label_header_bundle: 'Comprar juntos',
  label_subheader_bundle:
    'Lleva tu sistema al siguiente nivel con productos recomendados por expertos.',
  help_button_label: 'Chatear',
  help_button_heading: '¿Tienes preguntas?',
  help_button_subheading: 'Nuestros expertos están aquí para ayudarte.',
  configure_label: 'Configurar',
  documentation_label: 'Documentación',
  drivers_label: 'Controladores',
  miscellaneous_label: 'Varios',
  resources_label: 'Recursos',
  everything_you_need_label: 'Todo lo que necesitas',
  good_rating_label: 'Bueno',
  better_rating_label: 'Mejor',
  best_rating_label: 'Lo mejor',
  key_specs_label: 'Especificaciones clave',
  prebuild_upgrade_preproduct: 'Selecting the',
  prebuild_upgrade_postproduct: 'will upgrade your build to a',
  prebuild_socket_cpu: 'CPU',
  prebuild_socket_gpu: 'GPU',
  prebuild_socket_memory: 'Memory',
  prebuild_socket_motherboard: 'Motherboard',
  prebuild_socket_storage: 'Storage',
  prebuild_socket_psu: 'PSU',
  prebuild_heading_specs: 'Specifications',
  prebuild_subheading_specs: 'Current Configuration',
  prebuild_downgrade_preproduct: 'Configure a',
  prebuild_downgrade_postproduct: 'to select the',
  prebuild_no: 'No',
};

const collection = {
  message_invalid: 'No disponible temporalmente',
  message_out_of_stock: 'Agotado',
  message_low_stock: 'Stock bajo',
  message_unavailable: 'No disponible',
  message_new_item: 'Nuevo',
  message_coming_soon: 'Próximamente',
  button_buy_now: 'Comprar',
  message_colors: 'Colores',
  learn_more: 'Más información',
  label_show_more: 'Ver más',
  label_show_less: 'Ver menos',
  help_button_label: 'Chatear',
  help_button_heading: '¿Tienes preguntas?',
  help_button_subheading: 'Nuestros expertos están aquí para ayudarte.',
};

const news = {
  label_all_news: 'Todas las noticias',
};

const retailers = {
  label_regions: 'Regiones',
  label_locations: 'Ubicaciones',
  label_reseller_type: 'Tipo',
  option_label_all_regions: 'Todas las regiones',
  option_label_all_locations: 'Todas las ubicaciones',
  option_label_all_reseller_type: 'Todas las opciones',
  message_empty_state:
    'No hay ningún distribuidor que reúna los criterios seleccionados.',
  hero_text:
    'Los productos NZXT se pueden comprar a través de nuestros distribuidores en todas las regiones.',
};

const searchResults = {
  message_invalid: 'No disponible temporalmente',
  message_out_of_stock: 'Agotado',
  message_low_stock: 'Stock bajo',
  message_new_item: 'Nuevo',
  message_coming_soon: 'Próximamente',
  message_unavailable: 'No disponible',
};

const orderTracking = {
  title_order_status: 'Estado del pedido',
  label_cancelled_on: 'Cancelado el',
  label_shipping_date: 'Fecha de envío prevista',
  label_case: 'Caja',
  label_software: 'Software',
  label_services: 'Servicios',
  label_ram: 'RAM',
  label_cpu: 'Procesador',
  label_gpu: 'Gráfica',
  label_motherboard: 'Placa base',
  label_storage: 'Almacenamiento',
  label_cooling: 'Refrigeración',
  label_psu: 'Fuente de alimentación',
};

const orderTrackingRoot = {
  title_order_tracking_root: 'Introduce el ID de tu pedido',
  label_build_id: 'ID del pedido',
  button_track: 'Rastrear pedido',
  message_not_found: 'no encontrado; vuelve a intentarlo.',
};

const refurb = {
  label_all_products: 'Todos los productos',
  label_relevance: 'Relevancia',
  label_price_low_high: 'Precio ascendente',
  label_price_high_low: 'Precio descendente',
  label_name_a_z: 'Nombre A-Z',
  label_name_z_a: 'Nombre Z-A',
  label_case: 'Caja',
  label_software: 'Software',
  label_services: 'Servicios',
  label_ram: 'RAM',
  label_cpu: 'Procesador',
  label_gpu: 'Gráfica',
  label_motherboard: 'Placa base',
  label_storage: 'Almacenamiento',
  label_cooling: 'Refrigeración',
  label_psu: 'Fuente de alimentación',
  label_other: 'Otro',
  label_accessories: 'Accesorios',
  label_fan: 'Ventiladores',
  label_any_price: 'Cualquier precio',
  label_no_products:
    'No hay ningún componente reacondicionado disponible en estos momentos.',
  label_no_builds:
    'No hay ningún ordenador reacondicionado disponible en estos momentos.',
};

const orderStatus = {
  confirmed_title: 'Pedido realizado',
  step_label: 'Paso',
  confirmed_sub: 'Hemos recibido tu pedido.',
  processed_title: 'Pedido confirmado',
  processed_sub: 'Hemos recibido tu pedido y el pago se ha procesado.',
  in_progress_title: 'En curso',
  in_progress_sub:
    'Hemos empezado con la selección y el montaje de componentes para tu pedido. Ahora el pedido está bloqueado y los cambios o cancelaciones no se pueden garantizar.',
  shipped_title: 'Enviado',
  shipped_sub: '¡Tu pedido está en camino!',
  cancelled_title: 'Cancelado',
  cancelled_sub: 'Tu pedido se ha cancelado correctamente.',
  enh_ordered_sub: 'Hemos recibido tu pedido y lo hemos puesto en la cola.',
  enh_picking_title_bld: 'Preparando el montaje',
  enh_picking_title: 'Tramitando el pedido',
  enh_picking_sub_bld:
    'Hemos empezado con la selección y el montaje de componentes para tu pedido. Los cambios ya no se pueden garantizar.',
  enh_picking_sub:
    'Estamos preparando tu pedido. Los cambios ya no se pueden garantizar.',
  enh_build_title: 'Montando',
  enh_build_sub: 'Estamos montando y probando tu pedido.',
  enh_packing_title: 'Preparando para el envío',
  enh_packing_sub_bld:
    'Ya hemos montado y probado tu pedido. Ahora lo estamos embalando para enviarlo.',
  enh_packing_sub:
    'Ya hemos tramitado tu pedido y lo estamos embalando para enviarlo.',
  enh_shipping_title: 'Envío',
  enh_shipping_sub:
    'Hemos enviado tu pedido al transportista para su entrega. El número de seguimiento se actualizará y lo recibirás por correo electrónico en un día laborable.',
};

const universal = {
  ...userAccount,
  ...cart,
  ...auth,
  ...formErrors,
  ...camCta,
  ...generic,
  ...helpCta,
  ...emailSignUp,
  ...geoipBanner,
  ...ui,
  ...footer,
  ...search,
  ...about,
  ...orderStatus,
};

// eslint-disable-next-line import/prefer-default-export
export const es = {
  '/': {
    ...universal,
  },
  '/product/[slug]': {
    ...product,
    ...universal,
  },
  '/collection/[slug]': {
    ...collection,
    ...universal,
  },
  '/partner/[slug]': {
    ...collection,
    ...universal,
  },
  '/sale/[slug]': {
    ...collection,
    ...universal,
  },
  '/about': {
    ...universal,
    ...about,
  },
  '/about/[slug]': {
    ...universal,
    ...about,
  },
  '/legal': {
    ...universal,
  },
  '/legal/[slug]': {
    ...universal,
  },
  '/news/[slug]': {
    ...news,
    ...universal,
    ...category,
  },
  '/news/page/[page]': {
    ...universal,
    ...category,
  },
  '/news/category/[category]': {
    ...universal,
    ...category,
  },
  '/news/category/[category]/page/[page]': {
    ...universal,
    ...category,
  },
  '/user/[slug]': {
    ...universal,
    ...build,
    ...keyboard,
  },
  '/software/[slug]': {
    ...universal,
  },
  '/category/[slug]': {
    ...category,
    ...universal,
  },
  '/category/[slug]/[subcategory-slug]': {
    ...category,
    ...universal,
  },
  '/build': {
    ...universal,
    ...build,
  },
  '/build/[slug]': {
    ...universal,
    ...build,
  },
  '/build/pc': {
    ...universal,
    ...build,
  },
  '/build/pc/[slug]': {
    ...universal,
    ...build,
  },
  '/build/keyboard/[slug]': {
    ...universal,
    ...build,
    ...keyboard,
  },
  '/build/[slug]/summary': {
    ...universal,
    ...build,
  },
  '/support': {
    ...universal,
  },
  '/refurbished/[slug]': {
    ...universal,
    ...refurb,
  },
  '/careers': {
    ...universal,
  },
  '/reviews/page/[page]': {
    ...universal,
  },
  '/careers/available-positions': {
    ...universal,
  },
  '/order-tracking/[slug]': {
    ...orderTracking,
    ...orderTrackingRoot,
    ...universal,
  },
  '/order-tracking': {
    ...orderTrackingRoot,
    ...universal,
  },
  '/retailers': {
    ...retailers,
    ...universal,
  },
  '/search': {
    ...searchResults,
    ...universal,
  },
  '/auth/[slug]': {
    ...universal,
  },
  universal,
};

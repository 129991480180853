/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */

import { FC, useState } from 'react';
import useAddItem from '@framework/cart/use-add-item';
import useUiStore, { getOpenCart } from '@stores/use-ui-store';
import {
  MAXIFY_CURRENCY_MAP_PER_REGION,
  MAXIFY_REGION_NORTHERN_AMERICA,
  ROUTE_CART,
} from '@constants';
import { useRouter } from 'next/router';
import getExtendPricePoint from './get-extend-price-point';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};

declare global {
  interface Window {
    Extend?: any;
    ExtendShopify?: any;
  }
}

const InitializeAftermarket: FC<Props> = () => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const openCart = useUiStore(getOpenCart);
  const addItem = useAddItem('last chance modal');
  if (typeof window !== 'undefined') {
    window.addEventListener('ExtendLoaded', function () {
      // Finds name parameter
      const getQueryParam = name => {
        const results = new RegExp('[?&]' + name + '=([^&#]*)').exec(
          window.location.href
        );
        if (results == null) {
          return null;
        }
        /* eslint-disable @typescript-eslint/explicit-function-return-type */
        return decodeURI(results[1]) || 0;
      };

      // Searches URL for both leadToken or leadtoken and sets it to the leadToken variable
      let leadToken;
      getQueryParam('leadToken')
        ? (leadToken = getQueryParam('leadToken'))
        : (leadToken = getQueryParam('leadtoken'));

      // If leadToken show modal containing lead offer
      if (leadToken) {
        window.Extend.aftermarketModal.open({
          leadToken: leadToken,
          onClose: async function (plan, product, quantity) {
            if (plan && product) {
              const planVariant = await getExtendPricePoint(plan);

              const items = [
                {
                  shopify_variants_id: parseInt(planVariant, 10),
                  quantity: quantity,
                  attributes: [
                    {
                      key: `Extend.IsExtendWarranty`,
                      value: `true`,
                    },
                    {
                      key: `Extend.LeadToken`,
                      value: leadToken,
                    },
                    {
                      key: `Extend.LeadQuantity`,
                      value: quantity.toString(),
                    },
                    {
                      key: `_coveredProduct`,
                      value: `${product.name}`,
                    },
                    {
                      key: `_Extend.PlanId`,
                      value: `${plan.planId}`,
                    },
                    {
                      key: `_Extend.ProductId`,
                      value: `${product.id}`,
                    },
                    {
                      key: `_Extend.IsPricePoint`,
                      value: `true`,
                    },
                  ],
                },
              ];

              await addItem(items).then(() => {
                router.push(`/${ROUTE_CART}`);
                setLoading(false);
              });
            }
          },
        });
      }
    });
  }

  return null;
};

export default InitializeAftermarket;

const regionFeatures = {
  default: {
    cartButton: true,
    signIn: true,
    support: true,
    zendeskChat: true,
  },
  asia: {
    cartButton: false,
    signIn: false,
    support: false,
    zendeskChat: false,
  },
};

export default regionFeatures;

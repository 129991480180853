import { FC, memo, useEffect } from 'react';
import { useRouter } from 'next/router';
import { getRegion } from '@framework/api/utils/maxify';
import { YOTPO_KEY_MAP } from '@constants';

const YotpoReviews: FC = () => {
  const { locale } = useRouter();
  const region = getRegion(locale);

  const key = YOTPO_KEY_MAP[region] || process.env.NEXT_PUBLIC_YOTPO_KEY_US;

  // TODO - update this to remove old scripts on region change?
  useEffect(() => {
    const e = document.createElement('script');
    e.async = true;
    e.src = `https://cdn-widgetsrepository.yotpo.com/v1/loader/${key}`;

    const t = document.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(e, t);
  }, [key]);

  return null;
};

export default memo(YotpoReviews);

import React, {
  FC,
  memo,
  useMemo,
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from 'react';
import { useRouter } from 'next/router';

interface Hist {
  history: string[];
  setHistory(data: string[]): void;
  last?: string;
}

type Props = {
  children: ReactNode;
};

const HistoryContext = createContext<Hist>({} as Hist);

export const HistoryProvider: FC<Props> = memo(({ children }) => {
  const { asPath } = useRouter();
  const [history, setHistory] = useState<string[]>([]);
  const [last, setLast] = useState<string>(null);

  useEffect(() => {
    setHistory(previous => [...previous, asPath]);
  }, [asPath]);

  useEffect(() => {
    const lastRoute = history[history.length - 2] || null;
    setLast(lastRoute);
  }, [history]);

  const store = useMemo(
    () => ({
      history,
      setHistory,
      last,
    }),
    [history, last]
  );

  return (
    <HistoryContext.Provider value={store}>{children}</HistoryContext.Provider>
  );
});

HistoryProvider.displayName = 'HistoryProvider';

export function useHistory(): Hist {
  const context = useContext(HistoryContext);
  return context;
}

import { useEffect } from 'react';

const useRootClass = (className: string | string[]): void => {
  useEffect(() => {
    const root = document.documentElement;
    const addRootClass = (c: string): void => root.classList.add(c);
    const removeRootClass = (c: string): void => root.classList.remove(c);

    if (Array.isArray(className)) className.forEach(addRootClass);
    else addRootClass(className);

    return () => {
      if (Array.isArray(className)) className.forEach(removeRootClass);
      else removeRootClass(className);
    };
  }, [className]);
};

export default useRootClass;

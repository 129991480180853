/* eslint-disable max-len */
const userAccount = {
  user_settings_title: 'Compte',
  user_settings_change_password: 'Modifier le mot de passe',
  user_settings_save: 'Sauvegarder',
  user_settings_cancel: 'Annuler',
  user_profile_heading: 'Profil',
  user_saved_builds_heading: 'BLD enregistrés',
  user_saved_builds_bld_name: 'Nom du BLD',
  user_saved_builds_bld_date: 'Date de création',
  user_saved_builds_bld_item_part: 'Composant',
  user_saved_builds_delete_bld: 'Supprimer BLD',
  user_saved_builds_resume_bld: 'Reprendre BLD',
  user_saved_builds_hero_title: 'Conçu pour être montré',
  user_saved_builds_hero_text:
    "Le design compact et vertical de nos tours est compatible avec la plupart des tailles de GPU sur le marché, ce qui te permet de gagner de l'espace sur ton bureau sans sacrifier la puissance graphique.",
  user_saved_builds_pc_heading: 'Constructions de PC',
  user_saved_builds_keyboard_heading: 'Constructions de claviers',
  user_saved_builds_view_pc: 'Voir les constructions de PC',
  user_saved_builds_view_keyboard: 'Voir les constructions de claviers',
  user_saved_builds_no_pc: "Vous n'avez pas sauvegardé de PC Builds",
  user_saved_builds_no_kb:
    "Vous n'avez pas enregistré de constructions de clavier",
  user_order_history_heading: 'Historique des commandes',
  user_order_history_date: 'Date',
  user_order_history_order_number: 'Numéro de commande',
  user_order_history_status: 'État',
  user_order_history_amount: 'Montant',
  user_order_history_view: 'Voir le reçu',
  user_order_history_empty_view: "Tu n'as pas d'historique de commandes.",
  user_order_history_shipping: 'Adresse de livraison',
  user_order_history_billing: 'Adresse de facturation',
  user_order_history_item: 'Article',
  user_order_history_item_image: "Image de l'article",
  user_order_history_quantity: 'Quantité',
  user_order_history_order: '# de commande',
  user_support_heading: 'Assistance + Retours',
  user_data_heading: 'Demande de données',
  user_terms_heading: 'Termes',
  user_terms_empty: "Tu n'as pas encore de conditions signées.",
  user_support_subject_min_length:
    "L'objet doit comporter au moins 3 caractères",
  user_support_message_placeholder:
    "Merci de détailler les raisons pour lesquelles tu nous contactes. Pour un retour, merci d'inclure le numéro de commande",
  user_support_update_button: 'Mettre à jour',
  user_active_tickets_heading: 'Tickets ouverts',
  user_active_tickets_chat: 'Ajouter un message',
  user_active_tickets_no_chat_history: 'Ton historique de chat est vide',
  view_chat_button: 'Voir le chat',
  close_chat_button: 'Fermer le chat',
  field_required_message: 'Ce champ est requis',
  label_accept: "J'accepte",
  label_reason_for_contact: 'Motif de contact',
  label_country: 'Pays',
  label_subject: 'Sujet',
  label_product: 'Produit',
  label_message: 'Message',
  label_requestor_type: 'Type de demandeur',
  label_country_of_residence: 'Pays de résidence',
  label_description: 'Description',
  placeholder_description: 'Description de ta demande',
  label_submit: 'Soumettre',
  message_no_support_requests: "Tu n'as aucune demande d'assistance active",
  label_ticket_number: 'Numéro de billet',
  label_date_created: 'date de création',
  label_status: 'Statut',
  label_department: 'Département',
  subheading_active_ticket:
    "Si tu as besoin d'aide ou souhaites retourner un article, merci de contacter le support.",
  label_create_support_request: "Créer une demande d'assistance",
  button_comment: 'Commentaire',
  label_for_myself: 'Pour moi-même',
  label_parent: 'Parent',
  label_legal_rep: 'Représentant légal',
  label_relative: 'Un membre de ma famille',
  label_guardian: 'Gardien',
  label_accept_generic_terms: "J'ai lu et j'accepte ces conditions",
  error_view_message: "Oups, une erreur s'est produite.",
  signout_view_message: 'Tu as été déconnecté.',
  user_order_history_close: 'Fermer',
  birthday_error_message: 'Merci de saisir une date de naissance valide',
  label_view_saved: 'Voir toutes les constructions sauvegardées',
};

const formErrors = {
  invalid_email: "Ton adresse e-mail n'est pas valide",
  username_full_pattern_message:
    "Le nom d'utilisateur doit comporter entre 6 et 30 caractères. Vous pouvez utiliser des lettres, des chiffres et des points mais ne pouvez pas commencer, terminer ou contenir des points consécutifs.",
  password_pattern_message:
    'Le mot de passe doit contenir au moins six caractères dont une lettre majuscule, une lettre minuscule et un chiffre',
  username_min_length_message:
    "Utilisez 6 caractères minimum pour votre nom d'utilisateur",
  passwords_must_match: 'Les mots de passe doivent concorder',
  invalid_current_password:
    'Impossible de mettre à jour le mot de passe. La valeur fournie comme mot de passe actuel est incorrecte',
  must_accept_partner_program_terms:
    'Vous devez accepter les conditions du programme de partenariat pour devenir un partenaire NZXT',
  must_accept_tos_terms:
    "Tu dois accepter les conditions d'utilisation pour créer un compte NZXT",
  must_accept_generic_terms: 'Vous devez accepter ces conditions',
};

const auth = {
  user_auth_agreement: "J'accepte",
  user_auth_privacy_policy: 'la politique de confidentialité',
  user_auth_and: "et j'ai lu",
  user_auth_terms: "les conditions d'utilisation.",
  error_invalid_request: 'Requête invalide.',
  error_invalid_token: 'Tentative de connexion utilisateur non valide',
  error_session_expired:
    'Ta session a expiré. Merci de te reconnecter pour continuer.',
  user_forgot_required_email: 'Une adresse e-mail est nécessaire',
  user_forgot_success_message:
    'Merci! Si un compte est associé à cet e-mail, nous vous enverrons immédiatement les instructions de réinitialisation du mot de passe.',
  error_email_exists: "L'e-mail est déjà utilisé",
  user_email_verification_heading: "vérification de l'E-mail",
  error_email_not_verified:
    "L'adresse e-mail n'est pas encore vérifiée. Pour renvoyer l'e-mail de vérification, cliquez sur",
  error_email_already_verified:
    "L'adresse e-mail a déjà été vérifiée. Veuillez continuer à vous connecter.",
  user_email_verification_success_message:
    'Votre adresse e-mail a été vérifiée avec succès !. Veuillez continuer à vous connecter.',
  error_username_unavailable: "Le nom d'utilisateur n'est pas disponible",
  error_reset_password_same_as_old_password:
    "Ce mot de passe est le même que le précédent. Merci d'en choisir un autre.",
  error_reset_password_link_expired:
    'Le lien pour réinitialiser ton mot de passe a expiré.',
  error_reset_password_link_invalid:
    "Le lien pour réinitialiser ton mot de passe n'est pas valide.",
  user_forgot_password_subheading:
    'Saisis ton adresse e-mail pour recevoir les instructions de réinitialisation de ton mot de passe.',
  user_reset_password_heading: 'réinitialise ton mot de passe',
  user_reset_password_subheading: 'Merci de saisir un nouveau mot de passe.',
  user_reset_password: 'Réinitialiser le mot de passe',
  user_reset_success_message:
    'Tu as réinitialisé ton mot de passe avec succès !',
  user_login_text: 'Se connecter avec Google',
  user_login_remember: 'Se souvenir de moi',
  user_login_forgot_password: 'Mot de passe oublié ?',
  user_login_wrong_email_or_password:
    'Adresse e-mail ou mot de passe incorrects',
  user_login_success_message: 'Tu es connecté !',
  user_sign_up_success_verify_message:
    "Un e-mail a été envoyé à votre adresse e-mail contenant un lien d'activation. Veuillez cliquer sur le lien pour activer votre compte. Si vous ne cliquez pas sur le lien, votre compte restera inactif et vous ne recevrez plus d'e-mails. Si vous ne recevez pas l'e-mail dans quelques minutes, veuillez vérifier votre dossier spam.",
  error_too_many_requests:
    "Tu as fait trop de tentatives. Merci de patienter quelques minutes ou contacter l'assistance",
  error_user_not_found: "L'utilisateur n'a pas été trouvé",
  user_sign_in_sign_up: "Se connecter/S'inscrire",
  user_sign_out: 'Se déconnecter',
  user_sign_in: 'Se connecter',
  user_account_updates: 'Mises à jour NZXT',
  user_account_no_updates: 'Aucune mise à jour récente, revenez plus tard.',
  support_center_link: 'Aide & Assistance',
  error_google_account_exists:
    "Tu as déjà créé un compte avec Google. Merci d'essayer de te connecter.",
  error_local_account_exists:
    "Il semble que tu as déjà un compte. Merci d'essayer de te connecter.",
  user_sign_up: "S'inscrire",
  user_sign_up_text: "S'inscrire avec Google",
  user_sign_up_opt_in:
    'Enregistre-toi pour recevoir des informations par e-mail de la part de NZXT sur les nouveaux produits, les BLD et les CAM',
  user_info_updated_success_message:
    'Tes informations ont été mises à jour avec succès!',
  user_info_newsletter_confirmation_message:
    'Merci de vérifier ton e-mail pour confirmation.',
  user_email_sign_in: 'Ou continue avec',
  user_no_account: "Tu n'es pas membre ?",
  user_create_account: 'Créer un compte',
  user_email_sign_up_text: 'Ou enregistre-toi avec',
  user_have_account: 'Tu as un compte ?',
  open_login_view: 'Ouvrir la fenêtre de connexion',
  error_newsletter_subscription:
    "Impossible de s'abonner / se désabonner à la newsletter pour le moment. Merci de réessayer plus tard.",
  user_login_too_many_requests:
    'Tu as dépassé le nombre maximum autorisé de requêtes. Merci de patienter quelques minutes ou contacter le service clients',
  label_email_address: 'Adresse E-mail',
  label_password: 'Mot de Passe',
  label_current_password: 'Mot de passe actuel',
  label_confirm_password: 'Confirmez le mot de passe',
  label_new_password: 'nouveau mot de passe',
  label_confirm_new_password: 'Confirmer le nouveau mot de passe',
  label_username: "Nom d'utilisateur",
  label_first_name: 'Prénom',
  label_last_name: 'Nom de famille',
  label_preferred_pronouns: 'Pronoms préférés',
  label_search_for_help_articles: "Rechercher des articles d'aide",
  label_birthday: "Date d'anniversaire",
  label_location: 'Emplacement',
};

const build = {
  custom_bld_modal_heading: 'Configure ton PC',
  custom_bld_modal_subheading: 'Choisis ton chipset et sélectionne ton budget',
  custom_bld_unavailable: 'Merci de sélectionner un autre composant',
  custom_bld_issue: 'Il y a un problème avec le(s) composant(s) suivant(s) : ',
  custom_bld_title: 'Configuration sur mesure',
  custom_bld_lets_get_started: 'Démarrons',
  custom_bld_destrutive_action: 'Avertissement : Remise à zéro de ton BLD',
  custom_bld_confirm_reset:
    'Ton BLD va être remis à zéro avec le chipset suivant:',
  custom_bld_keyboard_confirm_reset: `Votre build sera réinitialisé avec ce changement`,
  custom_bld_choose_chipset: 'Sélectionne ton chipset',
  custom_bld_chipset: 'Chipset',
  custom_bld_intel: 'Intel',
  custom_bld_amd: 'AMD',
  custom_bld_explore: 'Explorer',
  custom_bld_select: 'Sélectionner',
  review_bld_button: 'Vérifier le BLD',
  custom_bld_summary: 'Récapitulatif',
  custom_bld_budget: 'Budget',
  custom_bld_specs: 'Spécifications',
  custom_bld_case: 'Boîtier',
  custom_bld_cases: 'Boîtiers',
  custom_bld_caseCooling: 'Refroidissement',
  custom_bld_fans: 'Ventilateurs',
  custom_bld_cpu: 'CPU',
  custom_bld_gpu: 'GPU',
  custom_bld_ram: 'RAM',
  custom_bld_ssd: 'SSD',
  custom_bld_hdd: 'Disque dur',
  custom_bld_specials: 'Points spéciaux',
  custom_bld_colors: 'Couleurs',
  custom_bld_color: 'Couleur',
  custom_bld_black: 'Noir',
  custom_bld_red: 'Rouge',
  custom_bld_white: 'Blanc',
  custom_bld_grey: 'Gris',
  custom_bld_blue: 'Bleu',
  custom_bld_purple: 'Violet',
  custom_bld_yellow: 'Jaune',
  custom_bld_audio: 'Audio',
  custom_bld_extras: 'Extras',
  custom_bld_core: 'Core',
  custom_bld_motherboard: 'Carte mère',
  custom_bld_motherboards: 'Cartes Mères Gaming',
  custom_bld_powerSupplies: 'Alimentations',
  custom_bld_powerSupply: "Bloc d'alimentation",
  custom_bld_services: 'Services',
  custom_bld_software: 'Logiciels',
  custom_bld_monitors: 'Moniteurs',
  custom_bld_peripherals: 'Périphériques',
  custom_bld_lighting: 'Éclairage',
  custom_bld_headset: 'Casque',
  custom_bld_keyboard: 'Clavier',
  custom_bld_all: 'Tout',
  custom_bld_mouse: 'Souris',
  custom_bld_storage: 'Stockage',
  custom_bld_streaming_ready: 'Prêt pour le stream',
  custom_bld_rgb_lighting: 'Éclairage RGB',
  custom_bld_vr_ready: 'Compatible VR',
  custom_bld_wifi: 'WiFi',
  custom_bld_from: 'À partir de',
  custom_bld_bluetooth: 'Bluetooth',
  custom_bld_save_build_message:
    'Ton BLD a bien été enregistré ! Tu peux le retrouver dans "BLD enregistrées" sur ton compte.',
  custom_bld_save_modal_message:
    'Voulez-vous enregistrer cette construction pour plus tard?',
  custom_bld_confirm_save_modal_message:
    'Vous perdrez les modifications non sauvegardées en quittant cette page',
  custom_bld_share_build_message: 'Ton BLD a bien été enregistré et copié !',
  help_me_decide: 'Aide-moi à faire le bon choix',
  save_build: 'Sauvegarder la configuration',
  start_over: 'Recommencer à zéro',
  summary: 'Récapitulatif',
  build_summary: 'Récapitulatif de configuration',
  load_build: 'Charger la configuration',
  load: 'Charger',
  save: 'Sauvegarder',
  restart: 'Redémarrer',
  share: 'Partager',
  my_build_label: 'Mon BLD',
  est_shipping: 'Date d’expédition estimée',
  add_to_build: 'Ajouter au BLD',
  message_invalid: 'Temporairement indisponible',
  message_out_of_stock: 'En rupture de stock',
  message_low_stock: 'Disponible en quantité limitée',
  message_new_item: 'Nouveau',
  message_coming_soon: 'Bientôt disponible',
  message_best_seller: 'Le plus vendu',
  message_is_locked: 'Pas Compatible',
  button_label_select: 'Sélectionner',
  button_label_selected: 'Sélectionné',
  button_label_add: 'Ajouter',
  button_label_remove: 'Supprimer',
  button_label_added: 'Ajouté',
  button_label_update: 'Mettre à jour',
  button_label_info: 'Info',
  button_label_more_info: "Plus d'infos",
  button_label_options_info: 'Options et informations',
  button_label_continue_to: 'Continuer la configuration',
  button_label_edit: 'Modifier',
  build_fps_label:
    "Le nombre d'images par seconde (FPS) est mesuré à la résolution 2560x1440 en utilisant les paramètres graphiques les plus élevés de chaque jeu",
  build_includes: 'Comprend',
  custom_bld_boost: 'Booste ton build',
  custom_bld_fps_unavailable: 'En attente',
  custom_bld_amd_help:
    "Les PC Intel ont longtemps été majoritaires mais les PC AMD sont aujourd'hui aussi excellents pour les jeux. Nous allons t'aider à assembler les deux types de PC et nous avons choisi des cartes mères et des refroidisseurs qui sont compatibles pour te faciliter la tâche. Nous te recommandons de choisir un bon équilibre entre le nombre d'images par seconde (FPS) et le budget que tu as pour ton BLD.",
  custom_bld_to_see_this: 'Pour choisir ce composant, tu dois',
  custom_bld_change_chipset: 'changer de chipset.',
  performance: 'Performances',
  message_error: 'Houston, nous avons un problème',
  custom_bld_prebuild_upsell: 'Ou choisis tout simplement un PC pré-assemblé',
  no_item_selected: 'Aucun Élément Sélectionné',
  build_change_label: 'BLD Modifié',
  invalid_action_label: 'Arrêté',
  success_label: 'Succès',
  build_info_message: 'Info',
  reset_label: 'Réinitialiser le BLD',
  complete_build_label: 'Termine ton BLD',
  back_to_build_label: 'Retour vers ton BLD',
  complete_build_sub_label: 'Extras et Périphériques',
  back_to_build_sub_label: 'Composants de base de ton BLD',
  capacity_label: 'Capacité',
  option_label: 'Option',
  options_label: 'Options',
  max_speed: 'Vitesse maximale',
  modular_label: 'Modulaire',
  midtower_label: 'Moyen Tour',
  streaming_label: 'Streaming',
  save_and_exit: 'Sauvegarder et quitter',
  exit: 'Quitter',
  view_cart_label: 'Voir le panier',
  cart_subtotal_label: 'Sous-total du panier',
  start_build_label: 'Nouvelle configuration',
  new_build_label: 'Nouvelle configuration',
  continue_saved_build_label: 'Finalise ta configuration',
  continue_progress_build_label:
    'Finalise la configuration de tes périphériques',
  continue_cart_build_label: 'Vérifiez la construction dans votre panier',
  core_label: 'Base',
  extras_label: 'Options',
  summary_label: 'Récapitulatif',
  share_build_pc: 'PC personnalisé',
  share_build_kb: 'Clavier personnalisé',
  build_summary_label: 'Aperçu de ton clavier personnalisé.',
  pc_hub_card_heading: 'PC Gaming personnalisés',
  pc_hub_card_subheading: `Configure le PC de tes rêves en fonction de ton budget et optimisé pour les jeux que tu aimes.`,
  kb_hub_card_heading: 'Claviers personnalisés',
  kb_hub_card_subheading:
    'Crée un clavier et une souris personnalisés en fonction de ton espace et de ton style de jeu.',
};

const keyboard = {
  keyboard_label: 'Clavier',
  keyboard_size: 'Taille du clavier',
  keyboard_base: 'Base du clavier',
  keyboard_primay_base_color: 'Couleur de base',
  keyboard_layout: 'Disposition du clavier',
  keyboard_summary: 'Récapitulatif du clavier',
  accent_keycaps: `Couleur d'accentuation`,
  cabletype: 'Câble Accent',
  additional_cable_color: 'Couleur du câble',
  cable: 'Câble',
  additional_cable: 'Câble supplémentaire',
  primary_keycaps: 'Keycaps de base',
  switches: 'Switches',
  size: 'Taille',
  layout: 'Disposition',
  language: 'Langue',
  mouse_pad_label: 'Tapis de souris',
  desk_mat_label: 'Tapis de bureau',
  tools_label: 'Outils',
  mouse_label: 'Souris',
  wrist_rest_label: 'Repose-poignets',
  cable_description: `Câble coloré en option. Un câble noir standard de 2 mètres sera inclus.`,
  cable_color_description: 'La couleur du câble premium.',
  language_description: 'Détermine la position des touches Q, W, Z et A.',
  layout_description: 'Détermine la taille et la position des touches.',
  keycap_accent_description:
    'Couleur optionnelle pour les touches Echap, Entrée, les flèches et Entrée du pavé numérique.',
  primary_keycap_description: 'PBT sublimé, profil Cherry.',
  switch_description: `Définit l'expérience de frappe grâce aux touches du clavier.`,
  keyboard_base_color_description:
    'La couleur principale de la base du clavier.',
  keyboard_base_size_description:
    'Détermine le nombre de touches et la dimension du clavier.',
  mouse_description: 'Souris ambidextre légère.',
  summary_description: 'Aperçu de ton clavier personnalisé',
  premium_label: 'Premium',
  base_label: 'Base',
  keyboard_warning:
    'Veuillez sélectionner les éléments suivants pour procéder à votre construction :',
  switch_reset_warning:
    'Vos interrupteurs ont été réinitialisés. Veuillez sélectionner de nouveaux interrupteurs.',
  keycap_reset_warning:
    'Vos porte-clés ont été réinitialisés. Veuillez choisir de nouveaux porte-clés.',
  base_type: 'Type de build',
  build_type_description: `Sélectionnez le type de clavier que vous souhaitez configurer.`,
  full_custom_label: 'Entièrement personnalisé',
  barebones_disclaimer:
    'Modifie ton type de build pour sélectionner cet élément',
  none_label: 'Aucun',
};

const camCta = {
  cam_description:
    "Gère les performances, les températures et les composants à partir d'une seule application.",
  cam_windows_10: 'WINDOWS 10 64 BITS',
};

const helpCta = {
  help_description:
    "Obtiens des guides et un support complets pour tous tes produits NZXT, ainsi qu'une aide pour les garanties, les retours et tout aspect technique.",
};

const emailSignUp = {
  email_stay_in_touch: 'Rester en contact',
  email_sub_copy:
    'Sois informé des dernières sorties, offres et nouveautés de NZXT.',
  email_legal:
    "En t'inscrivant, tu acceptes la politique de confidentialité et les conditions générales de NZXT et CAM. ",
  email_notify: 'Me prévenir',
  email_notify_stock: 'Prévenez-moi lorsque cet article est disponible.',
};

const generic = {
  prebuild_fan_label: 'Mises à niveau des ventilateurs',
  prebuild_keyboard_label: 'Clavier',
  prebuild_mouse_label: 'Souris',
  prebuild_audio_label: 'Audio',
  prebuild_accessories_label: 'Accessoires',
  prebuild_monitor_label: 'Moniteurs',
  prebuild_software_label: 'Logiciels',
  prebuild_lighting_label: 'Lighting accessories',
  prebuild_capture_card_label: 'Cartes de Capture',
  prebuild_os: `Système d'exploitation`,
  prebuild_windows: 'Windows 11 Home',
  fps_disclaimer: 'Tests réalisés avec les paramètres en ultra élevés',
  prebuild_specs_disclaimer: 'Caractéristiques par défaut',
  explore: 'Explorer',
  cam_title: 'NZXT CAM',
  free_download: 'Téléchargement gratuit',
  details: 'Détails',
  help_amp_support: 'Aide & assistance',
  help_and_support: 'Aide et assistance',
  read_more: 'En savoir plus',
  privacy_policy: 'Politique de confidentialité',
  terms_conditions: 'Conditions générales',
  and: 'et',
  or: 'ou',
  send: 'envoyer',
  return_to: 'retourner à',
  previous: 'Précédent',
  next: 'Suivant',
  home: 'Accueil',
  keep_browsing: 'Poursuivre la navigation',
  create_build: 'Créer un BLD',
  learn_more: 'En savoir plus',
  add_to_cart: 'Ajouter au panier',
  share_build: 'Partager le BLD',
  view: 'Voir',
  close: 'Fermer',
  price: 'Prix',
  cancel: 'Annuler',
  here: 'ici',
  ok: "D'accord",
  button_start_bld: 'Démarrer le BLD',
  message_on_sale: 'Promotion',
  button_buy: 'Acheter',
  button_start_build: 'Démarrer le BLD',
  view_build: 'Voir le BLD',
  button_back: 'Retour',
  performance: 'Performance',
  pending: 'En attente',
  build: 'Assembler',
  error_generic: "Quelque chose s'est mal passé. Merci de réessayer plus tard.",
  label_support: 'Assistance',
  contact_support: "Contacte l'assistance",
  browse_articles: 'Parcourir les articles',
  search_support_articles: "Rechercher des articles d'aide",
  message_error: "Oh, quelque chose s'est mal passé",
  estimated_performance_label: 'Performance estimée',
  estimated_fps: `Nombre d'images par seconde (FPS) estimé`,
  see_estimated_performance_label: 'Voir les performances estimées',
  title_related_items: 'Tu pourrais aussi aimer',
  qty: 'Quantité',
  category_from: 'À partir de',
  reviews_label: 'Commentaires client',
  faqs_label: 'FAQs',
  message_invalid: 'Temporairement indisponible',
  message_out_of_stock: 'En rupture de stock',
  message_low_stock: 'Disponible en quantité limitée',
  message_new_item: 'Nouveau',
  message_coming_soon: 'Bientôt disponible',
  message_best_seller: 'Le plus vendu',
  message_featured_items: `Produits en vedette dans l'image`,
  message_unavailable: 'Indisponible',
  message_clearance: 'Braderie',
  message_stock_amount: "Il n'en reste que {stock}!",
  message_is_limited_availability: 'Limitée',
  message_colors: 'Couleurs',
  message_color: 'Couleur',
  label_return_policy: 'Politique de retour',
  label_warranty_policy: 'Politique de garantie',
  label_shop: 'Acheter',
  label_days: 'Jours',
  label_hours: 'Heures',
  label_minutes: 'Minutes',
  label_seconds: 'Secondes',
  monthly_payments: 'Paiements mensuels disponibles.',
  message_promotion: 'Promotion',
  discount_label: 'de réduction',
  off_label: 'Inactif',
  on_label: 'Activé',
  choose_label: 'Choisissez',
  move_to_previous_label: `Diapositive précédente`,
  move_to_next_label: `Diapositive suivante`,
  active_slide_label: 'Diapositive active',
  move_to_inactive_slide: 'Diapositive inactive',
  color_options: 'Options de couleur',
  of: 'de',
  product_images: 'Images du produit',
  for: 'pour',
  carousel: 'Carrousel',
  go_to_image: `Aller à l'image`,
  button_label_add: 'Ajouter',
  button_label_remove: 'Supprimer',
  increase_price_label: 'Augmenter le prix',
  decrease_price_label: 'Diminution du prix',
  select_video_label: 'Sélectionnez la vidéo',
  play_label: 'Jouer',
  pause_label: 'Pause',
  buttons: 'Boutons',
  open_modal_label: `Ouvrir la modale de zoom sur l'image`,
  close_modal_label: `Fermer l'image Zoom modal`,
  image_zoom_modal: `Modalité de zoom de l'image`,
  vr_ready_label: 'VR Ready',
  vr_not_ready_label: 'Non VR Ready',
  streaming_ready_label: 'Stream Ready',
  streaming_not_ready_label: 'Non Stream Ready',
  has_wifi_label: `Possède l'WiFi`,
  no_wifi_label: `Pas d'WiFi`,
  has_bluetooth_label: `Possède l'Bluetooth`,
  no_bluetooth_label: `Pas d'Bluetooth`,
  has_usbc_label: `Possède l'USB-C`,
  no_usbc_label: `Pas d'USB-C`,
  rgb_included_label: 'Éclairage RGB inclus',
  rgb_not_included_label: 'Eclairage RGB non inclus',
  products_label: 'Produits',
  upsell_modal_label: 'Fréquemment achetés ensemble',
  no_thanks_label: 'Non merci',
  label_deal_ends: `L'offre se termine dans`,
  label_ending_soon: 'La promo est bientôt terminée',
  label_show_less: 'Montrer moins',
  label_show_more: 'Montrer plus',
  label_case: 'Boîtier',
  label_software: 'Logiciel',
  label_services: 'Services',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'GPU',
  label_motherboard: 'Carte mère',
  label_storage: 'Stockage',
  label_cooling: 'Refroidissement',
  label_psu: "Bloc d'alimentation",
  label_warranty: 'Garantie',
  label_accessories: 'Accessoires',
  label_fps: 'Images par seconde (FPS)',
  label_feature: 'Caractéristiques',
  see_full_details_label: 'Voir tous les détails',
  label_more_videos: 'Plus de vidéos',
  label_now_playing: 'En cours de lecture',
  label_add_to_cart_short: 'Ajouter au panier',
  label_quickshop: 'Achat Rapide',
  label_added_to_cart: 'Ajouté au panier',
  view_cart_label: 'Voir le panier',
  button_label_edit: 'Modifier',
  label_cart_extend_heading: 'Protect Your Purchase',
  label_cart_extend_subheading:
    'Keep your NZXT products protected after the manufacturer warranty expires. Extend covers product replacements, power surges, failure protection, and more.',
  label_cart_affirm_heading: 'Pay Later with Affirm',
  label_cart_affirm_heading_0_apr: '0% APR Financing',
  label_cart_affirm_subheading:
    'Buy now, pay over time. Quick and easy. No hidden fees. Select "Affirm monthly payments" during checkout.',
  label_cta_cart_heading: 'Purchase With Confidence',
  label_deal_sold_out: 'Rupture de stock',
  label_explore: 'Découvre tous les',
  label_ship_date: `Date d'expédition estimée`,
  button_label_info: 'Info',
  message_shipping_date: "Expédition d'ici le",
};

const cart = {
  cart_heading: 'Ton panier',
  cart_heading_empty: 'Ton panier est vide',
  cart_subheading_empty: 'Découvre des produits que tu vas adorer',
  cart_free_shipping_cta: 'Plus que 20 $ et la livraison est gratuite !',
  cart_free_shipping_exclusion:
    '(Exclut les ordinateurs personnalisés et précieux)',
  cart_free_shipping_promo_cta: `Livraison gratuite pour toutes les commandes`,
  cart_heading_error: "Une erreur s'est produite. Merci de réessayer.",
  cart_heading_success: 'Merci !',
  cart_label_subtotal: 'Sous-total',
  cart_label_original_price: `Prix d'origine`,
  cart_label_savings: 'Économies',
  cart_label_estimated_total: 'Estimated Total',
  cart_label_ship_tax: 'Frais de port et taxes calculés lors du paiement',
  cart_label_taxes: 'TVA',
  cart_value_taxes: 'Calculés à la prochaine étape',
  cart_label_shipping: 'Frais de livraison',
  cart_value_shipping_free: 'GRATUIT',
  cart_value_disclaimer:
    '*Les produits neufs et/ou reconditionnés peuvent être traités et expédiés à de différents moments.',
  cart_label_total: 'Total',
  cart_button_checkout: 'Procéder au paiement',
  cart_terms_message: "J'accepte les",
  cart_terms_link_text: 'conditions générales du service',
  cart_value_taxes_non_us: 'Inclus',
  cart_value_shipping_calculated: 'Calculés à la prochaine étape',
  cart_consent_i_have_read: "J'ai lu la",
  cart_consent_privacy_policy: 'politique de confidentialité',
  cart_consent_privacy_policy_after: ' ',
  cart_au_terms_message: "J'accepte les",
  cart_au_terms_link: "conditions d'utilisation",
  cart_au_consent_message:
    "J'accepte que mes informations personnelles soient collectées et utilisées conformément à la",
  cart_au_consent_link: 'politique de confidentialité',
  cart_au_terms_text_1:
    'NZXT Pty Ltd (ABN 47 645 981 559) fournit les biens et les services que tu as commandés conformément à ses',
  cart_au_terms_text_terms_link: 'termes et conditions',
  cart_au_terms_text_2:
    "et collecte, utilise et divulgue les informations personnelles qu'il recueille auprès de toi conformément à sa",
  cart_au_terms_text_privacy_link: 'politique de confidentialité',
  cart_label_ssd: 'SSD',
  cart_label_hdd: 'HDD',
  cart_label_service: 'Services',
  cart_label_software: 'Software',
  cart_label_case: 'Case',
  cart_label_motherboard: 'Carte mère',
  cart_label_cpu: 'CPU',
  cart_label_gpu: 'GPU',
  cart_label_power_supply: "Bloc d'alimentation",
  cart_label_cpu_cooler: 'CPU Refroidissement',
  cart_label_rgb: 'RGB',
  cart_label_fans: 'Ventilateurs',
  cart_label_ram: 'RAM',
  cart_label_monitors: 'Moniteurs',
  cart_label_peripherals: 'Périphériques',
  cart_label_extras: 'Extras',
  label_promotion: 'Voir la promotion',
  label_promotions: 'Promotions',
  cart_label_keycaps: 'Touches',
  cart_label_keycap_accents: 'Accents sur les touches',
  cart_label_switches: 'Commutateurs',
  cart_label_keyboard_base: 'Base de clavier',
  cart_label_keycap_accent: "Touches d'accentuation",
  cart_label_accent_cable: "Câble d'accentuation",
  cart_label_mouse: 'Souris',
  cart_message_item_fallback: 'Cet objet',
  cart_message_build_item_oos_before: 'Un article de votre',
  cart_message_build_item_oos_after: `est épuisé. Veuillez cliquer ici pour mettre à jour votre build afin de l'ajouter au panier.`,
  cart_message_build_oos_v2:
    'Un article de ta configuration est épuisé. Merci de cliquer ici pour mettre à jour ta sélection.',
  cart_message_oos:
    'est actuellement en rupture de stock. Veuillez retirer cet article pour passer à la caisse.',
};

const category = {
  explore_refurbished: 'Découvre les configurations',
  explore_bulk: 'Achats en gros',
  bulk: 'En gros',
  refurbished: 'Reconditionnés',
  refurbished_cta:
    'Découvre nos deals sur les BLD et les composants reconditionnés qui ont été minutieusement vérifiés et testés.',
  bulk_cta:
    'Nous avons aidé des entreprises dans le gaming, des universités, des organisations esport et plus encore, à acheter des PC en gros.',
};

const geoipBanner = {
  currently_browsing: 'Visiteurs actuels',
  banner_region_text:
    'Sélectionne ta région pour acheter en ligne ou pour afficher le contenu spécifique à ton emplacement géographique.',
  banner_button: 'Passer à',
  banner_country_text_shop: 'Magasinez',
  banner_counrty_text:
    'ou sélectionnez un autre pays spécifique à votre emplacement.',
  country_us: 'États-Unis',
  country_canada: 'Canada',
  country_belgium: 'Belgique',
  country_germany: 'Allemagne',
  country_luxembourg: 'Luxembourg',
  country_netherlands: 'Pays-Bas',
  country_austria: 'Autriche',
  country_france: 'France',
  country_italy: 'Italie',
  country_spain: 'Espagne',
  country_uk: 'Royaume-Uni',
  country_australia: 'Australie',
  country_nz: 'Nouvelle-Zélande',
  country_japan: 'Japon',
  region_na: 'Amérique du Nord',
  region_europe: 'Europe',
  region_oceania: 'Océanie',
  region_asia: 'Asie',
};

const ui = { loading: 'Loading' };

const footer = {
  label_language: 'Langue',
  label_country: 'Pays',
  manage_cookie_preferences: 'Gérer les préférences pour les cookies',
  all_rights_reserved: 'Tous droits réservés',
};

const search = {
  label_suggested_queries: 'Recherches suggérées',
  label_suggested_results: 'Résultats suggérés',
  label_serp_heading: 'Rechercher les résultats pour',
  label_serp_not_found: 'Aucun résultat trouvé pour',
  label_serp_total: 'total des résultats',
  label_serp_suggested: 'Essaye de rechercher',
};

const about = {
  label_customer_support_hours: 'Horaires du service clients',
  label_headquarters: 'Siège social',
};

const product = {
  overview: `Vue d'ensemble`,
  tech_specs: 'Caractéristiques techniques',
  downloads: 'Téléchargements',
  message_invalid: 'Temporairement indisponible',
  message_out_of_stock: 'En rupture de stock',
  message_low_stock: 'Disponible en quantité limitée',
  message_new_item: 'Nouveau',
  message_coming_soon: 'Bientôt Disponible',
  message_unavailable: 'Indisponible',
  title_highlights: 'Points forts',
  title_shipping: 'Expédition',
  title_in_the_box: 'Contenu du coffret',
  button_buy_now: 'Acheter maintenant',
  message_colors: 'Couleurs',
  message_color: 'Couleur',
  message_size: 'Taille',
  message_series: 'Série',
  overview_anchor: 'Présentation',
  tech_specs_anchor: 'Caractéristiques techniques',
  downloads_anchor: 'Téléchargements',
  related_anchor: 'Associé',
  customize_build_header: 'Ou démarre un BLD',
  customize_build_subheader: 'Démarre un BLD avec cet article',
  customize_kb_build_subheader: 'Construire un clavier personnalisé',
  buy_product_header: 'Assemble par toi-même',
  buy_product_subheader: 'Tu en rêves. Assemble-le. Fais-le.',
  buy_label: 'Acheter',
  retailers_subheader: 'Achète chez nos partenaires.',
  link_all_retailers: 'Voir tous les revendeurs',
  series_label: 'Séries',
  label_header_bundle: 'Acheter ensemble',
  label_subheader_bundle:
    'Faites passer votre construction au niveau supérieur avec des produits recommandés par des experts',
  help_button_label: 'Discuter maintenant',
  help_button_heading: 'Tu as des questions ?',
  help_button_subheading: `Nos experts sont prêts à t'aider.`,
  configure_label: 'Configurer',
  services_label: 'Services',
  add_ons_label: 'Compléments',
  documentation_label: 'Documentation',
  drivers_label: 'Pilotes',
  miscellaneous_label: 'Divers',
  resources_label: 'Ressources',
  everything_you_need_label: 'Everything you need',
  good_rating_label: 'Bien',
  better_rating_label: 'Mieux',
  best_rating_label: 'Meilleur',
  key_specs_label: `Coup d'oeil`,
  prebuild_upgrade_preproduct: 'Sélectionner',
  prebuild_upgrade_postproduct:
    'permettra de passer ta configuration au niveau supérieur vers un',
  prebuild_socket_cpu: 'CPU',
  prebuild_socket_gpu: 'GPU',
  prebuild_socket_memory: 'Mémoire vive',
  prebuild_socket_motherboard: 'Carte mère',
  prebuild_socket_storage: 'Stockage',
  prebuild_socket_psu: 'Alimentation',
  prebuild_heading_specs: 'Caractéristiques',
  prebuild_subheading_specs: 'Configuration actuelle',
  prebuild_downgrade_preproduct: 'Configurer un',
  prebuild_downgrade_postproduct: 'pour sélectionner la',
  prebuild_no: 'Pas de',
};

const collection = {
  message_invalid: 'Temporairement indisponible',
  message_out_of_stock: 'En rupture de stock',
  message_low_stock: 'Disponible en quantité limitée',
  message_new_item: 'Nouveau',
  message_coming_soon: 'Bientôt disponible',
  message_unavailable: 'Indisponible',
  button_buy_now: 'Acheter maintenant',
  message_colors: 'Couleurs',
  learn_more: 'En savoir plus',
  message_color: 'Couleur',
  message_size: 'Taille',
  help_button_label: 'Discuter maintenant',
  help_button_heading: 'Tu as des questions ?',
  help_button_subheading: `Nos experts sont prêts à t'aider.`,
};

const news = {
  label_all_news: 'Toutes les nouvelles',
};

const retailers = {
  label_regions: 'Régions',
  label_locations: 'Pays',
  label_reseller_type: 'Types',
  option_label_all_regions: 'Toutes les régions',
  option_label_all_locations: 'Tous les pays',
  option_label_all_reseller_type: 'Toutes les options',
  message_empty_state:
    'Aucun revendeur ne correspond aux critères sélectionnés.',
  hero_text:
    'Les produits NZXT peuvent être achetés via nos partenaires dans toutes les régions',
};

const searchResults = {
  message_invalid: 'Temporairement indisponible',
  message_out_of_stock: 'En rupture de stock',
  message_low_stock: 'Disponible en quantité limitée',
  message_new_item: 'Nouveau',
  message_coming_soon: 'Bientôt disponible',
  message_unavailable: 'Indisponible',
};

const orderTracking = {
  title_order_status: 'Statut de la commande',
  label_cancelled_on: 'Annulé le',
  label_shipping_date: "Date d'expédition estimée",
  label_case: 'Boîtier',
  label_software: 'Logiciel',
  label_services: 'Services',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'GPU',
  label_motherboard: 'Carte mère',
  label_storage: 'Stockage',
  label_cooling: 'Refroidissement',
  label_psu: "Bloc d'alimentation",
};

const orderTrackingRoot = {
  title_order_tracking_root: 'Saisis ton numéro de commande',
  label_build_id: 'numéro de commande',
  button_track: 'Suivi de commande',
  message_not_found: 'introuvable, merci de réessayer',
};

const refurb = {
  label_all_products: 'Tous les produits',
  label_relevance: 'Pertinence',
  label_price_low_high: 'Prix croissant',
  label_price_high_low: 'Prix décroissant',
  label_name_a_z: 'Nom de A-Z',
  label_name_z_a: 'Nom de Z-A',
  label_case: 'Boîtier',
  label_software: 'Logiciel',
  label_services: 'Services',
  label_ram: 'RAM',
  label_cpu: 'CPU',
  label_gpu: 'GPU',
  label_motherboard: 'Carte mère',
  label_storage: 'Stockage',
  label_cooling: 'Refroidissement',
  label_psu: "Bloc d'alimentation",
  label_other: 'Autre',
  label_accessories: 'Accessoires',
  label_fan: 'Ventilateurs',
  label_any_price: `N'importe quel prix`,
  label_no_products: `Aucun matériel assemblé remis à neuf n'est actuellement disponible.`,
  label_no_builds: `Aucun matériel assemblé remis à neuf n'est actuellement disponible.`,
};

const orderStatus = {
  confirmed_title: 'Commande effectuée',
  step_label: 'Étape',
  confirmed_sub: 'Ta commande a été enregistrée.',
  processed_title: ' Commande confirmée',
  processed_sub: 'Ta commande a été enregistrée et ton paiement a été traité.',
  in_progress_title: 'En cours',
  in_progress_sub:
    'Le traitement et l’assemblage de ta commande ont commencé. Ta commande est maintenant verrouillée et les modifications/annulations ne peuvent être garanties.',
  shipped_title: 'Expédié',
  shipped_sub: 'Votre commande est sur son chemin!',
  cancelled_title: 'Annulé',
  cancelled_sub: 'Votre commande a été annulée avec succès.',
  enh_ordered_sub: 'Votre commande a été reçue et est en attente.',
  enh_picking_title_bld: 'Préparation de la construction',
  enh_picking_title: 'Traitement de votre commande',
  enh_picking_sub_bld:
    "La cueillette et l'assemblage de votre commande ont commencé. Les modifications de votre commande ne peuvent être garanties.",
  enh_picking_sub:
    'Nous préparons votre commande. Les modifications de votre commande ne peuvent être garanties.',
  enh_build_title: 'Imeuble',
  enh_build_sub: 'Votre commande est en cours de construction et de test.',
  enh_packing_title: "Préparation à l'expédition",
  enh_packing_sub_bld:
    'Votre commande a été construite et testée. Il est emballé pour la livraison.',
  enh_packing_sub:
    "Votre commande a été traitée et est en cours d'emballage pour la livraison.",
  enh_shipping_title: 'Expédition',
  enh_shipping_sub:
    "Votre commande a été envoyée au transporteur pour livraison. Votre numéro de suivi sera mis à jour et vous sera envoyé par e-mail dans un délai d'un jour ouvrable.",
};

const universal = {
  ...cart,
  ...userAccount,
  ...auth,
  ...formErrors,
  ...camCta,
  ...generic,
  ...helpCta,
  ...emailSignUp,
  ...geoipBanner,
  ...ui,
  ...footer,
  ...search,
  ...about,
  ...orderStatus,
};

// eslint-disable-next-line import/prefer-default-export
export const fr = {
  '/': {
    ...universal,
  },
  '/product/[slug]': {
    ...product,
    ...universal,
  },
  '/collection/[slug]': {
    ...collection,
    ...universal,
  },
  '/partner/[slug]': {
    ...collection,
    ...universal,
  },
  '/sale/[slug]': {
    ...collection,
    ...universal,
  },
  '/about/[slug]': {
    ...universal,
  },
  '/legal': {
    ...universal,
  },
  '/legal/[slug]': {
    ...universal,
  },
  '/news/[slug]': {
    ...news,
    ...universal,
    ...category,
  },
  '/news/page/[page]': {
    ...universal,
    ...category,
  },
  '/news/category/[category]': {
    ...universal,
    ...category,
  },
  '/news/category/[category]/page/[page]': {
    ...universal,
    ...category,
  },
  '/user/[slug]': {
    ...universal,
    ...build,
    ...keyboard,
  },
  '/software/[slug]': {
    ...universal,
  },
  '/category/[slug]': {
    ...category,
    ...universal,
  },
  '/category/[slug]/[subcategory-slug]': {
    ...category,
    ...universal,
  },
  '/build': {
    ...universal,
    ...build,
  },
  '/build/[slug]': {
    ...universal,
    ...build,
  },
  '/build/pc': {
    ...universal,
    ...build,
  },
  '/build/pc/[slug]': {
    ...universal,
    ...build,
  },
  '/build/keyboard/[slug]': {
    ...universal,
    ...build,
    ...keyboard,
  },
  '/build/[slug]/summary': {
    ...universal,
    ...build,
  },
  '/support': {
    ...universal,
  },
  '/refurbished/[slug]': {
    ...universal,
    ...refurb,
  },
  '/careers': {
    ...universal,
  },
  '/reviews/page/[page]': {
    ...universal,
  },
  '/careers/available-positions': {
    ...universal,
  },
  '/order-tracking/[slug]': {
    ...orderTracking,
    ...orderTrackingRoot,
    ...universal,
  },
  '/order-tracking': {
    ...orderTrackingRoot,
    ...universal,
  },
  '/retailers': {
    ...retailers,
    ...universal,
  },
  '/search': {
    ...searchResults,
    ...universal,
  },
  '/auth/[slug]': {
    ...universal,
  },
  universal,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { LOCALHOST_OVERRIDE_SRC, LOCALHOST_OVERRIDE_DEST } from '@constants';

declare global {
  interface Window {
    CookieControl?: any;
    fbq?: any;
    _fbq?: any;
    dataLayer?: any;
    gtag?: any;
    affirm?: any;
    Extend?: any;
    ExtendShopify?: any;
    getAll?: any;
    gaplugins?: any;
    __learnq?: any;
    ire?: any;
    klaviyo?: any;
    _klOnsite?: any;
    TAI?: any;
  }
}

type OptionalCookie = {
  name: string;
  label: string;
  recommendedState: boolean;
  description: string;
  cookies: string[];
  onAccept: () => void;
  onRevoke: () => void;
};

type Statement = {
  description: string;
  name: string;
  url: string;
  updated: string;
};

type LocaleOptionalCookie = {
  label: string;
  description: string;
};

type Locale = {
  locale: string;
  text: {
    title: string;
    intro: string;
    settings: string;
    notifyTitle: string;
    notifyDescription?: string;
    accept: string;
    necessaryTitle: string;
    necessaryDescription: string;
    acceptRecommended: string;
    statement: Statement;
    optionalCookies: LocaleOptionalCookie[];
  };
};

export type Config = {
  apiKey: string;
  product: 'COMMUNITY' | 'PRO' | 'PRO_MULTISITE';
  position: 'left' | 'right';
  theme: 'light' | 'dark';
  initialState: 'open' | 'closed' | 'notify' | 'top' | 'box';
  layout: 'slideout' | 'popup';
  toggleType: 'slider' | 'checkbox';
  statement: Statement;
  branding: {
    fontFamily: string;
    fontColor: string;
    fontSizeTitle: string;
    fontSizeIntro: string;
    fontSizeHeaders?: string;
    fontSize?: string;
    backgroundColor: string;
    toggleColor: string;
    buttonIcon?: string;
    buttonIconWidth: string;
    buttonIconHeight: string;
    removeIcon: boolean;
    removeAbout: boolean;
    acceptBackground: string;
    acceptText: string;
  };
  text: {
    notifyTitle: string;
    accept: string;
    necessaryTitle: string;
    necessaryDescription: string;
    acceptRecommended: string;
  };
  locales: Locale[];
  consentCookieExpiry: number;
  necessaryCookies: string[];
  optionalCookies: OptionalCookie[];
};

const onLoad = (config: Config): void => {
  if (typeof window !== 'undefined') {
    if (window.location.href.includes('localhost')) {
      const { open } = window.XMLHttpRequest.prototype;
      // eslint-disable-next-line func-names
      window.XMLHttpRequest.prototype.open = function (method, url, ...args) {
        // eslint-disable-next-line no-param-reassign
        url = url.replace(LOCALHOST_OVERRIDE_SRC, LOCALHOST_OVERRIDE_DEST);
        return open.call(this, method, url, ...args);
      };
    }

    window.CookieControl.load(config);
  }
};

export default onLoad;

import { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import { ERROR_CODES } from '@framework/utils/errors';
import { User } from '@framework/api/types';

type AuthValues = {
  isLoggedIn: boolean;
  token: string | unknown;
  user: Partial<User>;
};

const useAuth = (): AuthValues => {
  const { data: session, status } = useSession();
  const loading = status === 'loading';
  const [newSession, setNewSession] = useState({
    isLoggedIn:
      session?.accessToken &&
      session?.error !== ERROR_CODES.RefreshAccessTokenError &&
      !loading,
    token: session?.accessToken,
    user: session?.user,
  });

  useEffect(() => {
    setNewSession({
      isLoggedIn:
        session?.accessToken &&
        session?.error !== ERROR_CODES.RefreshAccessTokenError &&
        !loading,
      token: session?.accessToken,
      user: session?.user,
    });
  }, [session, loading]);
  return newSession;
};

export default useAuth;

import {
  MAXIFY_REGION_NORTHERN_AMERICA,
  MAXIFY_REGION_EUROPE,
  MAXIFY_REGION_UNITED_KINGDOM,
  MAXIFY_REGION_OCEANIA,
  MAXIFY_REGION_NEW_ZEALAND,
  MAXIFY_REGION_ASIA,
} from './location';

export const DEFAULT_LANGUAGE_CODE = 'en';
export const GERMAN_LANGUAGE_CODE = 'de';
export const DUTCH_LANGUAGE_CODE = 'nl';
export const FRENCH_LANGUAGE_CODE = 'fr';
export const ITALIAN_LANGUAGE_CODE = 'it';
export const SPANISH_LANGUAGE_CODE = 'es';
export const JAPANESE_LANGUAGE_CODE = 'ja';

export const LANGUAGES = [
  {
    label: 'English',
    value: DEFAULT_LANGUAGE_CODE,
  },
  {
    label: 'Deutsch',
    value: GERMAN_LANGUAGE_CODE,
  },
  {
    label: 'Dutch',
    value: DUTCH_LANGUAGE_CODE,
  },
  {
    label: 'Français',
    value: FRENCH_LANGUAGE_CODE,
  },
  {
    label: 'Italiano',
    value: ITALIAN_LANGUAGE_CODE,
  },
  {
    label: 'Español',
    value: SPANISH_LANGUAGE_CODE,
  },
  {
    label: 'Japanese',
    value: JAPANESE_LANGUAGE_CODE,
  },
];

export const LANGS_PER_REGION = {
  [MAXIFY_REGION_NORTHERN_AMERICA]: [
    {
      label: 'English',
      value: DEFAULT_LANGUAGE_CODE,
    },
  ],
  [MAXIFY_REGION_UNITED_KINGDOM]: [
    {
      label: 'English',
      value: DEFAULT_LANGUAGE_CODE,
    },
  ],
  [MAXIFY_REGION_OCEANIA]: [
    {
      label: 'English',
      value: DEFAULT_LANGUAGE_CODE,
    },
  ],
  [MAXIFY_REGION_NEW_ZEALAND]: [
    {
      label: 'English',
      value: DEFAULT_LANGUAGE_CODE,
    },
  ],
  [MAXIFY_REGION_EUROPE]: [
    {
      label: 'English',
      value: DEFAULT_LANGUAGE_CODE,
    },
    {
      label: 'Deutsch',
      value: GERMAN_LANGUAGE_CODE,
    },
    {
      label: 'Français',
      value: FRENCH_LANGUAGE_CODE,
    },
    {
      label: 'Italiano',
      value: ITALIAN_LANGUAGE_CODE,
    },
    {
      label: 'Español',
      value: SPANISH_LANGUAGE_CODE,
    },
  ],
  [MAXIFY_REGION_ASIA]: [
    {
      label: 'Japanese',
      value: JAPANESE_LANGUAGE_CODE,
    },
  ],
};

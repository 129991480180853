import { useCallback, useEffect, useRef, useState } from 'react';
import { getToken, setToken } from '@framework/api/utils/maxify';
import {
  getLocaleCookie,
  setLocaleCookie,
} from '@framework/api/utils/locale-cookie';
import { getShopifyCartCookie } from '@framework/api/utils/shopify-cart-cookie';
import useUiStore, {
  getClearCartStockNotices,
  getSetIsShopifyCartCookieLoading,
  getIsShopifyCartCookieLoading,
} from '@stores/use-ui-store';
import parseLocale from '@utils/parse-locale';
import { DEFAULT_COUNTRY_CODE } from '@constants';
import useCartId from '@framework/cart/use-cart-id';
import { useSWRConfig } from 'swr';

const useLocationBasedCookies = ({
  locale,
  defaultLocale,
}: {
  locale: string;
  defaultLocale: string;
}): {
  locationBasedCookies: {
    cartCookie: string;
    localeCookie: string;
    shopifyCartCookie: string;
  };
  updateLocationBasedCookies: (newLocale: string) => void;
} => {
  const setIsShopifyCartCookieLoading = useUiStore(
    getSetIsShopifyCartCookieLoading
  );
  const isShopifyCartCookieLoading = useUiStore(getIsShopifyCartCookieLoading);
  const clearCartStockNotices = useUiStore(getClearCartStockNotices);

  const getCartId = useCartId();
  const { mutate } = useSWRConfig();

  const getInitialValues = (): {
    cartCookie: string;
    localeCookie: string;
    shopifyCartCookie: string;
  } => {
    const initialValues = {
      cartCookie: '',
      localeCookie: '',
      shopifyCartCookie: '',
    };
    const parsedLocale = parseLocale(defaultLocale, locale);
    const country = parsedLocale[1] || DEFAULT_COUNTRY_CODE;
    const cartIdFromCookie = getToken();
    const localeFromCookie = getLocaleCookie();
    const shopifyCartFromCookie = getShopifyCartCookie(country);

    if (cartIdFromCookie) {
      initialValues.cartCookie = cartIdFromCookie;
    } else {
      setToken(country);
      initialValues.cartCookie = getToken();
    }

    if (localeFromCookie) {
      initialValues.localeCookie = localeFromCookie;
    } else {
      setLocaleCookie(locale);
      initialValues.localeCookie = getLocaleCookie();
    }

    if (shopifyCartFromCookie) {
      initialValues.shopifyCartCookie = getShopifyCartCookie(country);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (!isShopifyCartCookieLoading) {
        setIsShopifyCartCookieLoading(true);
        getCartId(country).then(() => {
          initialValues.shopifyCartCookie = getShopifyCartCookie(country);
          setIsShopifyCartCookieLoading(false);
        });
      }
    }

    return initialValues;
  };

  const [locationBasedCookies, setLocationBasedCookies] = useState(
    getInitialValues()
  );

  const cookieRef = useRef<{
    cartCookie?: string;
    localeCookie?: string;
    shopifyCartCookie?: string;
  }>({});

  useEffect(() => {
    cookieRef.current = locationBasedCookies;
  }, [locationBasedCookies]);

  const updateLocationBasedCookies = useCallback(
    (newLocale: string) => {
      const { cartCookie } = cookieRef.current;
      const prevTokenLocale = cartCookie?.slice(-2);
      const prevTokenWithoutLocale = cartCookie?.slice(0, -3);
      const parsedLocale = parseLocale(defaultLocale, newLocale);
      const newCountry = parsedLocale[1] || DEFAULT_COUNTRY_CODE;
      setLocaleCookie(newLocale);
      if (prevTokenLocale !== newCountry) {
        setToken(newCountry, prevTokenWithoutLocale);
        if (!getShopifyCartCookie(newCountry) && !isShopifyCartCookieLoading) {
          setIsShopifyCartCookieLoading(true);
          getCartId(newCountry).then(() => {
            setIsShopifyCartCookieLoading(false);
          });
        }
        clearCartStockNotices();
      }
      if (getShopifyCartCookie(newCountry) && !isShopifyCartCookieLoading) {
        // TODO: fix cachey key format
        mutate([
          '/api/maxify/cart',
          undefined,
          'GET',
          getShopifyCartCookie(newCountry),
        ]);
      }
      setLocationBasedCookies(prevState => ({
        ...prevState,
        cartCookie: getToken(),
        localeCookie: getLocaleCookie(),
        shopifyCartCookie: getShopifyCartCookie(newCountry),
      }));
    },
    [cookieRef, defaultLocale, clearCartStockNotices]
  );

  return { locationBasedCookies, updateLocationBasedCookies };
};

export default useLocationBasedCookies;

/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCallback } from 'react';
import type { HookFetcher, HookFetcherOptions } from './types';
import { useCommerce } from '../commerce';

export default function useAction<T, Input = null>(
  options: HookFetcherOptions,
  fetcher: HookFetcher<T, Input>
) {
  const { fetcherRef } = useCommerce();

  return useCallback(
    (input: Input) => fetcher(options, input, fetcherRef.current),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher]
  );
}

import type { State, Payload } from './types';

export const getLastProduct = (state: State): Payload => ({
  lastProduct: state.lastProduct,
});

export const getSetLastProduct = (state: State): ((id: number) => void) =>
  state.setLastProduct;

export const getClearLastProduct = (state: State): (() => void) =>
  state.clearLastProduct;

/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
export type ErrorData = {
  message: string;
  code?: string;
};

export type ErrorProps = {
  code?: string;
} & (
  | { message: string; errors?: never }
  | { message?: never; errors: ErrorData[] }
);

export class CommerceError extends Error {
  code?: string;

  errors: ErrorData[];

  constructor({ message, code, errors }: ErrorProps) {
    const error: ErrorData = message
      ? { message, ...(code ? { code } : {}) }
      : errors![0];

    super(error.message);
    this.errors = message ? [error] : errors!;

    if (error.code) this.code = error.code;
  }
}

export class FetcherError extends CommerceError {
  status: number;

  constructor(
    options: {
      status: number;
    } & ErrorProps
  ) {
    super(options);
    this.status = options.status;
  }
}

export enum ERROR_CODES {
  Generic = 'UNKNOWN_ERROR',
  EmailRequired = 'EMAIL_REQUIRED',
  ResourceExhausted = 'RESOURCE_EXHAUSTED',
  InvalidArgument = 'INVALID_ARGUMENT',
  UnAuthenticated = 'UNAUTHENTICATED',
  InvalidAccessToken = 'INVALID_ACCESS_TOKEN',
  NotFound = 'NOT_FOUND',
  UniquePasswordRequired = 'UNIQUE_PASSWORD_REQUIRED',
  InvalidCurrentPassword = 'INVALID_CURRENT_PASSWORD',
  InvalidPassword = 'INVALID_PASSWORD',
  InvalidUsername = 'INVALID_USERNAME',
  TokenExpired = 'TOKEN_EXPIRED',
  TokenInvalid = 'TOKEN_INVALID',
  EmailAlreadyExists = 'EMAIL_ALREADY_EXISTS',
  EmailNotVerified = 'EMAIL_NOT_VERIFIED',
  EmailAlreadyVerified = 'EMAIL_ALREADY_VERIFIED',
  UsernameAlreadyExists = 'USERNAME_ALREADY_EXISTS',
  CredentialsSignin = 'CredentialsSignin',
  SubscriptionFailed = 'SUBSCRIPTION_FAILED',
  RefreshAccessTokenError = 'RefreshAccessTokenError',
  LocalAccountExists = 'LocalAccountExists',
  GoogleAccountExists = 'GoogleAccountExists',
}

type ERROR_CODE_VALUES = {
  [key in ERROR_CODES]: string;
};

const ERROR_MESSAGES: ERROR_CODE_VALUES = {
  [ERROR_CODES.Generic]: 'error_generic',
  [ERROR_CODES.EmailRequired]: 'user_forgot_required_email',
  [ERROR_CODES.ResourceExhausted]: 'error_too_many_requests',
  [ERROR_CODES.InvalidArgument]: 'error_invalid_request',
  [ERROR_CODES.UnAuthenticated]: 'user_login_wrong_email_or_password',
  [ERROR_CODES.InvalidAccessToken]: 'error_invalid_token',
  [ERROR_CODES.NotFound]: 'error_user_not_found',
  [ERROR_CODES.UniquePasswordRequired]:
    'error_reset_password_same_as_old_password',
  [ERROR_CODES.InvalidCurrentPassword]: 'invalid_current_password',
  [ERROR_CODES.InvalidPassword]: 'password_pattern_message',
  [ERROR_CODES.InvalidUsername]: 'username_full_pattern_message',
  [ERROR_CODES.TokenExpired]: 'error_reset_password_link_expired',
  [ERROR_CODES.TokenInvalid]: 'error_reset_password_link_invalid',
  [ERROR_CODES.EmailAlreadyExists]: 'error_email_exists',
  [ERROR_CODES.EmailNotVerified]: 'error_email_not_verified',
  [ERROR_CODES.EmailAlreadyVerified]: 'error_email_already_verified',
  [ERROR_CODES.UsernameAlreadyExists]: 'error_username_unavailable',
  [ERROR_CODES.CredentialsSignin]: 'user_login_wrong_email_or_password',
  [ERROR_CODES.SubscriptionFailed]: 'error_newsletter_subscription',
  [ERROR_CODES.RefreshAccessTokenError]: 'error_session_expired',
  [ERROR_CODES.LocalAccountExists]: 'error_local_account_exists',
  [ERROR_CODES.GoogleAccountExists]: 'error_google_account_exists',
};

export const getErrorMessageKeys = (errors: ErrorData[] = []): string[] =>
  errors.reduce((keys, error) => {
    const key = ERROR_MESSAGES[error.code];
    if (key) {
      keys.push(key);
    }
    return keys;
  }, []);

export const getErrorMessageKey = (errors: ErrorData[]): string => {
  const messageKeys = getErrorMessageKeys(errors);
  return messageKeys.length ? messageKeys[0] : null;
};

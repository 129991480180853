import { useEffect, useState, FC } from 'react';
import Script from 'next/script';
import { useAuth } from '@utils/hooks';
import waitForGlobal from '@utils/wait-for-global';

const ThreadAi: FC = () => {
  const { user } = useAuth();
  const userId = user?.email ?? null;

  const [hasLoggedIn, setHasLoggedIn] = useState(false);

  // if a user logs in for the first time, set userId to their email address
  useEffect(() => {
    if (userId && !hasLoggedIn) {
      waitForGlobal('TAI', () => {
        setHasLoggedIn(true);
        window.TAI('identify', userId);
      });
    }
  }, [hasLoggedIn, userId]);

  return (
    <>
      <Script id="tai">
        {`
          !(function(t,a,i){if(t[i])return;var z=t[i]=function(){var q=Array.prototype.slice.call(arguments);q[3]=new Date();z.process?z.process.apply(z,q):z.queue.push(q);};z.queue=[];})(window,document,"TAI");TAI("config", "endpoint", "${process.env.NEXT_PUBLIC_THREAD_ENDPOINT}");TAI("init", "${process.env.NEXT_PUBLIC_THREAD_ID}");
          `}
      </Script>
      <Script src="https://cdn.threadai.zip/assets/tai.v1.js" />
    </>
  );
};

export default ThreadAi;

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Variant } from '@framework/api/types';
import type {
  State,
  ModalView,
  SelectedVariant,
  ModalContent,
  CartStockNotice,
  ProductDetails,
} from './types';

// Modals
export const getDisplayModal = (state: State): boolean => state.displayModal;

export const getModalView = (state: State): string => state.modalView;

export const getModalAction = (state: State): (() => void) => state.modalAction;

export const getModalOnOpenAction = (state: State): (() => void) =>
  state.modalOnOpenAction;

export const getModalOnCloseAction = (state: State): (() => void) =>
  state.modalOnCloseAction;

export const getOpenModal = (state: State): (() => void) => state.openModal;

export const getCloseModal = (state: State): (() => void) => state.closeModal;

export const getSetModalView = (state: State): ((view: ModalView) => void) =>
  state.setModalView;

export const getSetModalAction = (state: State): ((fn: () => void) => void) =>
  state.setModalAction;

export const getSetModalProductDetails = (
  state: State
): ((fn: () => void) => void) => state.setModalAction;

export const getSetModalOnOpenAction = (
  state: State
): ((fn: () => void) => void) => state.setModalOnOpenAction;

export const getSetModalOnCloseAction = (
  state: State
): ((fn: () => void) => void) => state.setModalOnCloseAction;

// Nav
export const getDisplayNav = (state: State): boolean => state.displayNav;

export const getDisplayBuildNav = (state: State): boolean =>
  state.displayBuildNav;

export const getDisplayCart = (state: State): boolean => state.displayCart;

export const getDisplayOverlay = (state: State): boolean =>
  state.displayOverlay;

export const getDisplayDrawer = (state: State): boolean => state.displayDrawer;

export const getDisplaySearch = (state: State): boolean => state.displaySearch;

export const getDisplayAccountNav = (state: State): boolean =>
  state.displayAccountNav;

export const getToggleNav = (state: State): (() => void) => state.toggleNav;

export const getCloseNav = (state: State): (() => void) => state.closeNav;

export const getToggleBuildNav = (state: State): (() => void) =>
  state.toggleBuildNav;

export const getCloseBuildNav = (state: State): (() => void) =>
  state.closeBuildNav;

export const getOpenCart = (state: State): (() => void) => state.openCart;

export const getCloseCart = (state: State): (() => void) => state.closeCart;

export const getToggleCart = (state: State): (() => void) => state.toggleCart;

export const getClickOverlay = (state: State): (() => void) =>
  state.clickOverlay;

export const getOpenDrawer = (state: State): (() => void) => state.openDrawer;

export const getCloseDrawer = (state: State): (() => void) => state.closeDrawer;

export const getOpenSearch = (state: State): (() => void) => state.openSearch;

export const getCloseSearch = (state: State): (() => void) => state.closeSearch;

export const getToggleSearch = (state: State): (() => void) =>
  state.toggleSearch;

export const getDisplayDesktopNav = (state: State): boolean =>
  state.displayDesktopNav;

export const getToggleDesktopNav = (state: State): (() => void) =>
  state.toggleDesktopNav;

export const getOpenDesktopNav = (state: State): (() => void) =>
  state.openDesktopNav;

export const getCloseDesktopNav = (state: State): (() => void) =>
  state.closeDesktopNav;

export const getToggleAccountNav = (state: State): (() => void) =>
  state.toggleAccountNav;

export const getOpenAccountNav = (state: State): (() => void) =>
  state.openAccountNav;

export const getCloseAccountNav = (state: State): (() => void) =>
  state.closeAccountNav;

export const getDisplayPreviewBanner = (state: State): boolean =>
  state.displayPreviewBanner;

export const getShowPreviewBanner = (state: State): (() => void) =>
  state.showPreviewBanner;

export const getClosePreviewBanner = (state: State): (() => void) =>
  state.closePreviewBanner;

export const getDisplayMarketingBanner = (state: State): boolean =>
  state.displayMarketingBanner;

export const getOpenMarketingBanner = (state: State): (() => void) =>
  state.openMarketingBanner;

export const getCloseMarketingBanner = (state: State): (() => void) =>
  state.closeMarketingBanner;

export const getSetSelectedVariant = (
  state: State
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ((selectedVariant: SelectedVariant | any) => void) =>
  state.setSelectedVariant;

export const getSelectedVariant = (state: State): Variant =>
  state.selectedVariant;

export const getSetProductDetails = (
  state: State
): ((productDetails: ProductDetails) => void) => state.setProductDetails;

export const getProductDetails = (state: State): ProductDetails =>
  state.productDetails;

export const getSetModalContent = (
  state: State
): ((modalContent: ModalContent) => void) => state.setModalContent;

export const getModalContent = (state: State): ModalContent =>
  state.modalContent;

export const getSetAdditionalProductContent = (
  state: State
): ((
  additionalProducts: Variant[],
  showWarning?: boolean,
  modalWarning?: string
) => void) => state.setAdditionalProductContent;

export const getShowWarning = (state: State): boolean => state.showWarning;
export const getModalWarning = (state: State): string => state.modalWarning;

export const getAdditionalProductContent = (state: State): any[] =>
  state.additionalProducts;

export const getSetLogout = (state: State): (() => void) => state.setLogout;

export const getSetIsTermsAccepted = (
  state: State
): ((isTermsAccepted: boolean) => void) => state.setIsTermsAccepted;

export const getIsTermsAccepted = (state: State): boolean =>
  state.auth.isTermsAccepted;

export const getPlayingId = (state: State): number | string =>
  state.soundButtonId;
export const setPlayingId = (state: State): ((id: string | number) => void) =>
  state.setSoundButtonId;

export const getCartStockNotices = (state: State): CartStockNotice[] =>
  state.cartStockNotices;

export const getSetCartStockNotice = (
  state: State
): ((cartStockNotice: CartStockNotice) => void) => state.setCartStockNotice;

export const getRemoveCartStockNotice = (
  state: State
): ((cartStockNotice: CartStockNotice) => void) => state.removeCartStockNotice;

export const getClearCartStockNotices = (state: State): (() => void) =>
  state.clearCartStockNotices;

export const getSetIsShopifyCartCookieLoading = (
  state: State
): ((isShopifyCartCookieLoading: boolean) => void) =>
  state.setIsShopifyCartCookieLoading;

export const getIsShopifyCartCookieLoading = (state: State): boolean =>
  state.isShopifyCartCookieLoading;

import waitForGlobal from '@utils/wait-for-global';

const onRevoke = (): void => {
  waitForGlobal('zE', () => {
    if (process.env.NEXT_PUBLIC_FEATURE_FLOWBUILDER === 'true') {
      window.zE('messenger:set', 'cookies', false);
    } else {
      window.zE('webWidget', 'updateSettings', {
        cookies: false,
      });
    }
  });
};

export default onRevoke;

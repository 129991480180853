import GTM from '@utils/gtm';
import initAnalyticsJs from './init-analytics-js';

function gtag(...rest): void {
  window.dataLayer.push(...rest);
}

const onAccept = (gtmId: string, serverUrl: string): void => {
  if (typeof window !== 'undefined') {
    GTM.initialize({
      gtmId,
      serverUrl,
    });

    if (process.env.NEXT_PUBLIC_GTM_SW_ID) {
      GTM.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_SW_ID,
        serverUrl,
      });
    }

    window[`ga-disable-${process.env.NEXT_PUBLIC_GA4_ID}`] = false;
    window[`ga-disable-${process.env.NEXT_PUBLIC_UA_ID}`] = false;
    window[`ga-disable-${process.env.NEXT_PUBLIC_GA4_SW_ID}`] = false;

    window.dataLayer = window.dataLayer || [];

    gtag('js', new Date());

    gtag('config', process.env.NEXT_PUBLIC_GA4_ID, {
      anonymize_ip: true,
      linker: {
        domains: [
          process.env.NEXT_PUBLIC_BASE_URL.replace(/^https?:\/\//, ''),
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_AU,
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_DE,
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_US,
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_UK,
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_NZ,
        ],
      },
    });
    gtag('config', process.env.NEXT_PUBLIC_GA4_SW_ID, {
      anonymize_ip: true,
      linker: {
        domains: [
          process.env.NEXT_PUBLIC_BASE_URL.replace(/^https?:\/\//, ''),
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_AU,
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_DE,
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_US,
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_UK,
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_NZ,
        ],
      },
    });
    gtag('config', process.env.NEXT_PUBLIC_UA_ID, {
      anonymize_ip: true,
      linker: {
        domains: [
          process.env.NEXT_PUBLIC_BASE_URL.replace(/^https?:\/\//, ''),
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_AU,
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_DE,
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_US,
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_UK,
          process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_NZ,
        ],
      },
    });

    gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });

    window.gtag = gtag;

    initAnalyticsJs();
  }
};

export default onAccept;

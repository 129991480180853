import { useState, useEffect } from 'react';

type DateProps = {
  days: number | string;
  hours: number | string;
  minutes: number | string;
  seconds: number | string;
  initialized: boolean;
  isExpired?: boolean;
};

const useCountdown = (endDate: string): DateProps => {
  const countdownToDate = new Date(endDate).getTime();

  const [state, setState] = useState<DateProps>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    initialized: false,
    isExpired: false,
  });

  const setNewTime = (): void => {
    if (countdownToDate) {
      const startDate = new Date().getTime();
      const distanceToDate = Math.abs(
        countdownToDate.valueOf() - startDate.valueOf()
      );

      const isExpired = startDate > countdownToDate;

      let days: string | number = Math.floor(
        distanceToDate / (1000 * 60 * 60 * 24)
      );
      let hours: number | string = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes: string | number = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds: string | number = Math.floor(
        (distanceToDate % (1000 * 60)) / 1000
      );

      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

      if (numbersToAddZeroTo.includes(days)) {
        days = `0${days}`;
      }
      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      }
      if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      }
      if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }

      setState({
        days: days || `${days}`,
        hours,
        minutes,
        seconds,
        initialized: true,
        isExpired,
      });
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (endDate) {
      const tick = setInterval(() => setNewTime(), 1000);
      return () => clearInterval(tick);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  return state;
};

export default useCountdown;

const waitForGlobal = (key: string, cb: () => void, n = 10): void => {
  if (typeof window === 'undefined') {
    return;
  }

  let timer = null;

  if (window[key]) {
    if (timer) {
      clearTimeout(timer);
    }

    cb();
  } else if (n > 0) {
    timer = setTimeout(() => {
      waitForGlobal(key, cb, n - 1);
    }, 100);
  }

  if (n <= 0) {
    clearTimeout(timer);
  }
};

export default waitForGlobal;

import { useEffect, FC } from 'react';
import { useAuth } from '@utils/hooks';
import setImpactIdCookie from '@utils/impact-cookie/set-impact-id-cookie';
import setImpactEmailCookie from '@utils/impact-cookie/set-impact-email-cookie';
import crypto from 'crypto';

const Impact: FC = () => {
  const { user: { email, id } = {}, isLoggedIn } = useAuth();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const customerId = id ?? '';
      const customerEmail =
        isLoggedIn && email
          ? crypto.createHash('sha1').update(email).digest('hex')
          : '';

      setImpactIdCookie(customerId);
      setImpactEmailCookie(customerEmail);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'impact_identify',
        customerId,
        customerEmail,
      });
    }
  }, [id, email, isLoggedIn]);

  return null;
};

export default Impact;

import { useEffect, useState, FC } from 'react';
import { init, setUserId } from '@amplitude/analytics-browser';
import { useAuth } from '@utils/hooks';

const amplitudeId = process.env.NEXT_PUBLIC_AMPLITUDE_ID;

const Amplitude: FC = () => {
  const { user } = useAuth();
  const userId = user?.email ?? null;

  const [hasLoggedIn, setHasLoggedIn] = useState(false);

  // init amplitude
  useEffect(() => {
    if (amplitudeId) {
      init(amplitudeId, null, {
        defaultTracking: {
          attribution: true,
          pageViews: false,
          sessions: true,
          fileDownloads: true,
          formInteractions: true,
        },
      });
    }
  }, []);

  // if a user logs in for the first time, set userId to their email address
  useEffect(() => {
    if (userId && !hasLoggedIn) {
      setHasLoggedIn(true);
      setUserId(userId);
    }
  }, [hasLoggedIn, userId]);

  return null;
};

export default Amplitude;

/* eslint-disable consistent-return */
import { useEffect, useRef } from 'react';

/* https://overreacted.io/making-setinterval-declarative-with-react-hooks/ */

const useInterval = (callback: () => void, delay: number | null): void => {
  const savedCallback = useRef(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick(): void {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export default useInterval;

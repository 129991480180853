/* eslint-disable max-len */
const userAccount = {
  user_settings_title: 'Account',
  user_settings_change_password: 'Cambia la password',
  user_settings_save: 'Salva',
  user_settings_cancel: 'Annulla',
  user_profile_heading: 'Profilo',
  user_saved_builds_heading: 'Build salvate',
  user_saved_builds_bld_name: 'Nome build',
  user_saved_builds_bld_date: 'Data di creazione',
  user_saved_builds_bld_item_part: 'Parte',
  user_saved_builds_delete_bld: 'Elimina BLD',
  user_saved_builds_resume_bld: 'Riprendi BLD',
  user_saved_builds_hero_title: 'Progettato per mostrare la tua build',
  user_saved_builds_hero_text:
    'Il design verticale compatto mantiene il supporto per la maggior parte delle GPU di dimensioni standard sul mercato in modo da poter mantenere lo spazio sulla scrivania senza sacrificare la potenza grafica.',
  user_saved_builds_pc_heading: 'Costruzioni di PC',
  user_saved_builds_keyboard_heading: 'Costruzioni di tastiere',
  user_saved_builds_view_pc: 'Visualizza i PC costruiti',
  user_saved_builds_view_keyboard: 'Visualizza le costruzioni della tastiera',
  user_saved_builds_no_pc: 'Non hai build salvate per il PC',
  user_saved_builds_no_kb: 'Non hai memorizzato nessuna build di tastiera',
  user_order_history_heading: 'Cronologia degli ordini',
  user_order_history_date: 'Data',
  user_order_history_order_number: 'Numero dell’ordine',
  user_order_history_status: 'Stato',
  user_order_history_amount: 'Quantità',
  user_order_history_view: 'Visualizza ricevuta',
  user_order_history_empty_view: 'Non hai cronologia ordini.',
  user_order_history_shipping: 'Indirizzo di spedizione',
  user_order_history_billing: 'Indirizzo di fatturazione',
  user_order_history_item: 'Articolo',
  user_order_history_item_image: 'Immagine articolo',
  user_order_history_quantity: 'Quantità',
  user_order_history_order: 'Ordine #',
  user_support_heading: 'Assistenza + resi',
  user_data_heading: 'Richiesta dati',
  user_terms_heading: 'Termini',
  user_terms_empty: 'Non hai ancora firmato i termini.',
  user_support_subject_min_length:
    'L’oggetto deve contenere almeno 3 caratteri',
  user_support_message_placeholder:
    'Esponi i motivi per cui ci contatti. Se è una restituzione, includi il numero',
  user_support_update_button: 'Aggiornamento',
  user_active_tickets_heading: 'Ticket attivi',
  user_active_tickets_chat: 'Aggiungi un messaggio',
  user_active_tickets_no_chat_history: 'Non hai una cronologia della chat',
  view_chat_button: 'Visualizza chat',
  close_chat_button: 'Chiudi chat',
  field_required_message: 'Questo campo è obbligatorio',
  label_accept: 'Accettare',
  label_reason_for_contact: 'Motivo del contatto',
  label_country: 'Paese',
  label_subject: 'Soggetto',
  label_product: 'Prodotto',
  label_message: 'Messaggio',
  label_requestor_type: 'Tipo richiedente',
  label_country_of_residence: 'Paese di residenza',
  label_description: 'Descrizione',
  placeholder_description: 'Descrizione della tua richiesta',
  label_submit: 'Invia',
  message_no_support_requests: 'Non hai richieste di supporto attive',
  label_ticket_number: 'Numero del ticket',
  label_date_created: 'data di creazione',
  label_status: 'Stato',
  label_department: 'Dipartimento',
  subheading_active_ticket:
    "Se hai bisogno di aiuto o stai cercando di restituire un articolo, contatta l'assistenza.",
  label_create_support_request: 'Crea richiesta di supporto',
  button_comment: 'Commento',
  label_for_myself: 'Per me',
  label_parent: 'Genitore',
  label_legal_rep: 'Rappresentante legale',
  label_relative: 'Parente',
  label_guardian: 'Custode',
  label_accept_generic_terms: 'Ho letto e accetto questi termini',
  error_view_message: 'Spiacenti, si è verificato un problema.',
  signout_view_message: 'Sei stato disconnesso.',
  user_order_history_close: 'Chiudi',
  birthday_error_message: 'Inserisci una data di nascita valida',
  label_view_saved: 'Visualizza tutte le build salvate',
};

const formErrors = {
  invalid_email: 'Digita un indirizzo e-mail valido',
  username_full_pattern_message:
    'Il nome utente deve essere lungo da 6 a 30 caratteri. Puoi utilizzare lettere, numeri e punti ma non puoi iniziare, finire o contenere punti consecutivi.',
  password_pattern_message:
    'La password deve contenere almeno sei caratteri, una lettera maiuscola, una lettera minuscola e un numero',
  username_min_length_message: 'Utilizza 6 caratteri o più per il nome utente',
  passwords_must_match: 'Le password devono combaciare',
  invalid_current_password:
    'Impossibile aggiornare la password. Il valore fornito come password corrente non è corretto',
  must_accept_partner_program_terms:
    'Devi accettare i Termini del Programma Partner per diventare un partner NZXT',
  must_accept_tos_terms:
    'Devi accettare i Termini di Servizio per creare un account NZXT',
  must_accept_generic_terms: 'Devi accettare questi termini',
};

const auth = {
  user_auth_agreement: "Accetto NZXT's",
  user_auth_privacy_policy: 'Informativa sulla privacy',
  user_auth_and: 'e ho letto la',
  user_auth_terms: 'ai Termini di utilizzo.',
  error_invalid_request: 'Richiesta non valida.',
  error_invalid_token: 'Token di autenticazione utente non valido',
  error_session_expired:
    'La tua sessione è scaduta. Effettua nuovamente il login per continuare.',
  user_forgot_required_email: 'È richiesto un indirizzo e-mail',
  user_forgot_success_message:
    'Grazie! Se è presente un account associato a questa email, invieremo immediatamente le istruzioni per la reimpostazione della password.',
  error_email_exists: "L'email è già in uso",
  user_email_verification_heading: 'verifica email',
  error_email_not_verified:
    "L'indirizzo email non è ancora verificato. Per inviare nuovamente l'e-mail di verifica, fare clic su",
  error_email_already_verified:
    "L'indirizzo email è già stato verificato. Si prega di continuare ad accedere.",
  user_email_verification_success_message:
    'Il tuo indirizzo email è stato verificato con successo!. Si prega di continuare ad accedere.',
  error_username_unavailable: 'Il nome utente non è disponibile',
  error_reset_password_same_as_old_password:
    'Questa password è la stessa di prima. Selezionane un altra.',
  error_reset_password_link_expired:
    'Il collegamento per reimpostare la password è scaduto.',
  error_reset_password_link_invalid:
    'Il collegamento per reimpostare la password non è valido.',
  user_forgot_password_subheading:
    'Inserisci la tua email per ricevere istruzioni su come reimpostare la password.',
  user_reset_password_heading: 'reimposta la tua password',
  user_reset_password_subheading: 'Per favore inserisci una nuova password.',
  user_reset_password: 'Resetta la password',
  user_reset_success_message: 'Hai reimpostato con successo la tua password!',
  user_login_text: 'Accedi con Google',
  user_login_remember: 'Ricordami',
  user_login_forgot_password: 'Hai dimenticato la password?',
  user_login_wrong_email_or_password: 'Indirizzo e-mail o password errato/a',
  user_login_success_message: 'Hai effettuato il log in con successo!',
  user_sign_up_success_verify_message:
    "È stata inviata un'e-mail al tuo indirizzo e-mail contenente un link di attivazione. Clicca sul link per attivare il tuo account. Se non clicchi sul link il tuo account rimarrà inattivo e non riceverai ulteriori email. Se non ricevi l'e-mail entro pochi minuti, controlla la cartella spam.",
  error_too_many_requests:
    "Hai fatto troppe richieste. Attendi qualche minuto o contatta l'assistenza",
  error_user_not_found: "L'utente non è stato trovato",
  user_sign_in_sign_up: 'Accedi / registrati',
  user_sign_out: 'Esci',
  user_sign_in: 'Accedi',
  user_account_updates: 'Aggiornamenti NZXT',
  user_account_no_updates:
    'Nessun aggiornamento recente, ricontrolla più tardi.',
  support_center_link: 'Aiuto e Assistenza',
  error_google_account_exists:
    'Hai già creato un account utilizzando Google. Effettua invece il login.',
  error_local_account_exists:
    'Sembra che tu abbia già un account. Effettua invece il login.',
  user_sign_up: 'Registrati',
  user_sign_up_text: 'Registrati con Google',
  user_sign_up_opt_in:
    'Iscriviti alla Mailing List per ricevere aggiornamenti su prodotti, BLD e CAM da parte di NZXT',
  user_info_updated_success_message:
    'Le tue informazioni sono state aggiornate con successo!',
  user_info_newsletter_confirmation_message:
    'Controlla la tua email per la conferma.',
  user_email_sign_in: 'Oppure continua con',
  user_no_account: 'Non sei ancora registrato?',
  user_create_account: 'Crea account',
  user_email_sign_up_text: 'Oppure iscriviti con',
  user_have_account: 'Hai già un account?',
  open_login_view: 'Apri la visualizzazione dell’accesso',
  error_newsletter_subscription:
    "Al momento non è possibile iscriversi / annullare l'iscrizione alla newsletter. Per favore riprova più tardi.",
  user_login_too_many_requests:
    'Hai effettuato troppe richieste. Attendi qualche minuto o contatta l’assistenza',
  label_email_address: 'Indirizzo E-mail',
  label_password: 'Password',
  label_current_password: 'password attuale',
  label_confirm_password: 'conferma password',
  label_new_password: 'nuova password',
  label_confirm_new_password: 'Conferma la nuova password',
  label_username: 'Nome utente',
  label_first_name: 'Nome di battesimo',
  label_last_name: 'Cognome',
  label_preferred_pronouns: 'Pronomi preferiti',
  label_search_for_help_articles: 'Cerca articoli di aiuto',
  label_birthday: 'Compleanno',
  label_location: 'Posizione',
};

const build = {
  custom_bld_modal_heading: 'Costruisci il tuo PC',
  custom_bld_modal_subheading: 'Seleziona il tuo chipset e il tuo budget',
  custom_bld_unavailable: 'Seleziona un articolo',
  custom_bld_issue: 'I seguenti elementi devono essere risolti: ',
  custom_bld_title: 'Build personalizzata',
  custom_bld_lets_get_started: 'Cominciamo',
  custom_bld_destrutive_action: 'Attenzione: Reimpostazione della build',
  custom_bld_confirm_reset:
    'La tua build sarà reimpostata con il seguente chipset:',
  custom_bld_keyboard_confirm_reset: `La tua build sarà resettata con questo cambiamento`,
  custom_bld_choose_chipset: 'Scegli il tuo chipset',
  custom_bld_chipset: 'Chipset',
  custom_bld_intel: 'Intel',
  custom_bld_amd: 'AMD',
  custom_bld_explore: 'Esplora',
  custom_bld_select: 'Seleziona',
  review_bld_button: 'Rivedi Build',
  custom_bld_summary: 'Riepilogo',
  custom_bld_budget: 'Budget',
  custom_bld_specs: 'Specifiche',
  custom_bld_case: 'Case',
  custom_bld_cases: 'Case',
  custom_bld_caseCooling: 'Raffreddamento',
  custom_bld_fans: 'Ventole',
  custom_bld_cpu: 'CPU',
  custom_bld_gpu: 'GPU',
  custom_bld_ram: 'RAM',
  custom_bld_ssd: 'SSD',
  custom_bld_hdd: 'HDD',
  custom_bld_specials: 'Elementi speciali',
  custom_bld_colors: 'Colori',
  custom_bld_color: 'Colore',
  custom_bld_black: 'Nero',
  custom_bld_red: 'Rosso',
  custom_bld_white: 'Bianca',
  custom_bld_grey: 'Grigio',
  custom_bld_blue: 'Blu',
  custom_bld_purple: 'Viola',
  custom_bld_yellow: 'Giallo',
  custom_bld_audio: 'Audio',
  custom_bld_extras: 'Elementi supplementari',
  custom_bld_core: 'Core',
  custom_bld_motherboard: 'Scheda madre',
  custom_bld_motherboards: 'Schede madri',
  custom_bld_powerSupplies: 'Alimentatori',
  custom_bld_powerSupply: 'Alimentatore',
  custom_bld_services: 'Servizi',
  custom_bld_software: 'Software',
  custom_bld_monitors: 'Monitor',
  custom_bld_peripherals: 'Periferiche',
  custom_bld_lighting: 'Illuminazione',
  custom_bld_headset: 'Cuffie',
  custom_bld_keyboard: 'Tastiere',
  custom_bld_all: 'Tutti',
  custom_bld_mouse: 'Mouse',
  custom_bld_storage: 'Supporti di memoria',
  custom_bld_streaming_ready: 'Pronto per lo streaming',
  custom_bld_rgb_lighting: 'Illuminazione RGB',
  custom_bld_vr_ready: 'Pronto per la VR',
  custom_bld_wifi: 'WiFi',
  custom_bld_from: 'Da',
  custom_bld_bluetooth: 'Bluetooth',
  custom_bld_save_build_message:
    'La tua build è stata salvata con successo! Puoi visualizzarla in "Build salvate" nel tuo account.',
  custom_bld_save_modal_message: 'Vuoi salvare questa configurazione per dopo?',
  custom_bld_confirm_save_modal_message:
    'Lasciando questa pagina perderai le modifiche non salvate',
  custom_bld_share_build_message:
    'La tua build è stata salvata e copiata con successo!',
  help_me_decide: 'Aiutami a Decidere',
  save_build: 'Salva Build',
  start_over: 'Ricomincia da Capo',
  summary: 'Riepilogo',
  build_summary: 'Riepilogo Build',
  load_build: 'Carica Build',
  est_shipping: 'Spedizione stimata il',
  add_to_build: 'Aggiungi alla Build',
  load: 'Caricare',
  save: 'Salva',
  restart: 'Ricomincia',
  share: 'Condividere',
  my_build_label: 'La mia build',
  message_invalid: 'Temporaneamente non disponibile',
  message_out_of_stock: 'Esaurito',
  message_low_stock: 'Disponibile in quantità limitate',
  message_new_item: 'Nuovo',
  message_coming_soon: 'Prossimamente',
  message_best_seller: 'Il più venduto',
  message_is_locked: 'Non Compatibile',
  button_label_select: 'Seleziona',
  button_label_selected: 'Selezionata',
  button_label_add: 'Aggiungi',
  button_label_remove: 'Rimuovi',
  button_label_added: 'Aggiunta',
  button_label_update: 'Aggiornamento',
  button_label_info: 'Informazioni',
  button_label_more_info: 'Maggiori informazioni',
  button_label_options_info: 'Opzioni e Informazioni',
  button_label_continue_to: 'Continua a configurare la build',
  button_label_edit: 'Modifica',
  build_fps_label:
    "Le velocità FPS sono misurate a una risoluzione di 2560x1440 utilizzando l'impostazione grafica più alta di ogni gioco",
  build_includes: 'Include',
  custom_bld_boost: 'Potenzia la tua build',
  custom_bld_fps_unavailable: 'In attesa di',
  custom_bld_amd_help:
    'I PC basati su Intel sono stati la norma negli ultimi anni ma i PC AMD sono fantastici per i giochi ora. Ti aiuteremo a costruirli entrambi e abbiamo scelto per te schede madri e dispositivi di raffreddamento compatibili tra loro. TI consigliamo di bilanciare le prestazioni FPS suggerite nelle build con altri componenti che preferisci e su cui sei disposto a investire.',
  custom_bld_to_see_this: 'Per vedere questa selezione dovrai',
  custom_bld_change_chipset: 'modificare la tua selezione.',
  performance: 'Prestazioni',
  message_error: 'Oh-oh, sembra si sia verificato un errore',
  custom_bld_prebuild_upsell: 'Oppure scegli preassemblati facili e veloci',
  no_item_selected: 'Nessun Elemento Selezionato',
  build_change_label: 'Build Modificata',
  invalid_action_label: 'Errore',
  success_label: 'Successo',
  build_info_message: 'Informazioni',
  reset_label: 'Ripristina Build',
  complete_build_label: 'Completa la tua Build',
  back_to_build_label: 'Torna alla tua Build',
  complete_build_sub_label: 'Extra e Periferiche',
  back_to_build_sub_label: 'Componenti principali della tua build',
  capacity_label: 'Capacità',
  option_label: 'Opzione',
  options_label: 'Opzioni',
  max_speed: 'Massima velocità',
  modular_label: 'Modulare',
  midtower_label: 'Mid-Tower',
  streaming_label: 'Streaming',
  save_and_exit: 'Salvare e uscire',
  exit: 'Uscire',
  view_cart_label: 'Visualizza Carrello',
  cart_subtotal_label: 'Subtotale del carrello',
  start_build_label: 'Nuova build',
  new_build_label: 'Nuova build',
  continue_saved_build_label: 'Continua la tua build salvata',
  continue_progress_build_label: 'Continua la costruzione che hai in corso',
  continue_cart_build_label: 'Rivedi la build nel tuo carrello',
  core_label: 'Base',
  extras_label: 'Add-ons',
  summary_label: 'Riepilogo',
  share_build_pc: 'PC personalizzato',
  share_build_kb: 'Tastiera personalizzata',
  build_summary_label: 'Panoramica della configurazione di tastiera.',
  pc_hub_card_heading: 'PC da gioco personalizzati',
  pc_hub_card_subheading:
    'Crea il tuo PC da sogno in base al tuo budget e ottimizzato per i giochi che ami.',
  kb_hub_card_heading: 'Tastiere personalizzate',
  kb_hub_card_subheading:
    'Crea una tastiera e un mouse personalizzati da abbinare al tuo spazio e stile di gioco.',
};

const keyboard = {
  keyboard_label: 'Tastiera',
  keyboard_size: 'Dimensione della tastiera',
  keyboard_base: 'Base tastiera',
  keyboard_primay_base_color: 'Colore di base',
  keyboard_layout: 'Layout tastiera',
  keyboard_summary: 'Riepilogo tastiera',
  accent_keycaps: 'Tasti colorati',
  cabletype: 'Cavo colorato',
  additional_cable_color: 'Colore del cavo',
  cable: 'Cavo',
  additional_cable: 'Cavo aggiuntivo',
  primary_keycaps: 'Tasti di base',
  switches: 'Switch',
  size: 'Dimensione',
  layout: 'Layout',
  language: 'Lingua',
  mouse_pad_label: 'Tappetino per mouse',
  desk_mat_label: 'Tappetino per scrivania',
  tools_label: 'Strumenti',
  mouse_label: 'Mouse',
  wrist_rest_label: 'Strumenti',
  cable_description:
    'Cavo colorato opzionale. Un cavo nero standard di 2 metri sarà incluso.',
  cable_color_description: 'Colore del cavo premium.',
  language_description: 'Determina la posizione dei tasti Q, W, Z e A. ',
  layout_description: 'Determina la dimensione e la posizione dei tasti.',
  keycap_accent_description:
    'Colore opzionale per i tasti Escape, Enter, Frecce e Enter del tastierino numerico',
  primary_keycap_description: 'PBT a sublimazione termica, profilo Cherry.',
  switch_description: `Definisce l'esperienza di digitazione di ogni tasto premuto.`,
  keyboard_base_color_description: 'Colore primario della base tastiera.',
  keyboard_base_size_description:
    'Determina il numero di tasti e la dimensione della tastiera.',
  mouse_description: 'Mouse ambidestro leggero.',
  summary_description: 'Panoramica della configurazione della tua tastiera',
  premium_label: 'Premium',
  base_label: 'Base',
  keyboard_warning:
    'Si prega di selezionare i seguenti elementi per procedere con la costruzione:',
  switch_reset_warning:
    'I tuoi interruttori sono stati resettati. Si prega di selezionare nuovi interruttori.',
  keycap_reset_warning:
    'I tuoi cappucci sono stati resettati. Si prega di selezionare nuovi keycaps.',
  base_type: 'Tipo di configurazione',
  build_type_description: `Seleziona il tipo di tastiera che vuoi configurare`,
  full_custom_label: 'Personalizzazione completa',
  barebones_disclaimer: 'Cambia la tua build per selezionare questo elemento',
  none_label: 'Nessuno',
};

const camCta = {
  cam_description:
    'Gestisci Performance, Temperature e Dispositivi con un’unica applicazione.',
  cam_windows_10: 'Windows 10 64BIT/Windows 11',
};

const helpCta = {
  help_description:
    'Ricevi guide approfondite e assistenza su tutti i prodotti NZXT, oltre che sulle garanzie, i resi e qualsiasi questione di natura tecnica.',
};

const emailSignUp = {
  email_stay_in_touch: 'Resta in Contatto',
  email_sub_copy:
    'Rimani aggiornato sulle ultime uscite, offerte e notizie riguardanti NZXT',
  email_legal:
    "Iscrivendoti accetti l'Informativa sulla privacy e i Termini e condizioni di NZXT e CAM. ",
  email_notify: 'Avvisami',
  email_notify_stock:
    'Ricevi una notifica quando questo articolo è disponibile.',
  estimated_performance_label: 'Prestazioni stimate',
  see_estimated_performance_label: 'Visualizza le prestazioni stimate',
};

const generic = {
  prebuild_fan_label: 'Aggiornamenti del ventilatore',
  fps_disclaimer: 'Testato con impostazioni Ultra High',
  prebuild_specs_disclaimer: 'Specifiche predefinite',
  explore: 'Esplora',
  cam_title: 'NZXT CAM',
  free_download: 'Download Gratuito',
  details: 'Dettagli',
  help_amp_support: 'Aiuto e Assistenza',
  help_and_support: 'Aiuto e Assistenza',
  read_more: 'Continua a leggere',
  privacy_policy: 'Informativa sulla privacy',
  terms_conditions: 'Termini e Condizioni',
  and: 'e',
  or: 'o',
  send: 'Spedire',
  return_to: 'Ritornare a',
  previous: 'Precedente',
  next: 'Successivo',
  home: 'Home',
  keep_browsing: 'Continua a navigare',
  create_build: 'Crea Build',
  learn_more: 'Scopri di più',
  add_to_cart: 'Aggiungi al Carrello',
  share_build: 'Condividi Build',
  view: 'Visualizza',
  close: 'Chiudi',
  price: 'Prezzo',
  cancel: 'Annulla',
  here: 'Qui',
  ok: 'Ok',
  button_start_bld: 'Inizia Build',
  message_on_sale: 'In Sconto',
  button_buy: 'Compra',
  button_start_build: 'Inizia Build',
  view_build: 'Visualizza Build',
  button_back: 'Indietro',
  performance: 'Prestazione',
  pending: 'In attesa di',
  build: 'Build',
  error_generic: 'Qualcosa è andato storto, riprova più tardi.',
  label_support: 'Servizio clienti',
  contact_support: 'Contatta il supporto',
  browse_articles: 'Sfoglia Gli Articoli',
  search_support_articles: 'Cerca articoli della guida',
  message_error: 'Uh-oh, qualcosa è andato storto',
  title_related_items: 'Potrebbe piacerti anche',
  estimated_performance_label: 'Prestazioni Stimate',
  see_estimated_performance_label: 'Vedi Prestazioni Stimate',
  estimated_fps: 'FPS (fotogrammi al secondo) stimati',
  qty: 'QTY',
  category_from: 'Da',
  reviews_label: 'Recensioni clienti',
  faqs_label: 'FAQs',
  message_invalid: 'Temporaneamente non disponibile',
  message_out_of_stock: 'Esaurito',
  message_low_stock: 'Disponibile in quantità limitate',
  message_new_item: 'Nuovo',
  message_coming_soon: 'Prossimamente',
  message_best_seller: 'Il più venduto',
  message_unavailable: 'Non disponibile',
  message_clearance: 'Svendita',
  message_is_limited_availability: 'Limitado',
  message_featured_items: `Prodotti in evidenza nell'immagine`,
  message_colors: 'Colori',
  message_color: 'Colore',
  label_return_policy: 'Politica di ritorno',
  label_warranty_policy: 'Politica di garanzia',
  label_shop: 'Acquista',
  label_days: 'Giorni',
  label_hours: 'Ore',
  label_minutes: 'Minuti',
  label_seconds: 'Secondi',
  label_promotion: 'Vedi promozione',
  label_promotions: 'Promozioni',
  monthly_payments: 'Pagamenti mensili disponibili.',
  message_promotion: 'Promozione',
  discount_label: 'di sconto',
  off_label: 'Inattivo',
  on_label: 'acceso',
  choose_label: 'Scegliere',
  move_to_previous_label: 'Vai alla diapositiva precedente',
  move_to_next_label: 'Passa alla prossima diapositiva',
  active_slide_label: 'Diapositiva attiva',
  move_to_inactive_slide: 'Passa alla diapositiva inattiva',
  color_options: 'Opzioni di colore',
  of: 'di',
  product_images: 'Immagini del prodotto',
  for: 'per',
  carousel: 'Carosello',
  go_to_image: `Vai all'immagine`,
  button_label_add: 'Aggiungi',
  button_label_remove: 'Rimuovi',
  increase_price_label: 'Aumentare il prezzo',
  decrease_price_label: 'Diminuire il prezzo',
  select_video_label: 'Seleziona il video',
  play_label: 'Gioca',
  pause_label: 'Pausa',
  buttons: 'Pulsanti',
  open_modal_label: `Aprire la modalità di zoom dell'immagine`,
  close_modal_label: 'Chiudi immagine Zoom modale',
  image_zoom_modal: `Zoom modale dell'immagine`,
  vr_ready_label: 'VR Ready',
  vr_not_ready_label: 'Non VR Ready',
  streaming_ready_label: 'Stream Ready',
  streaming_not_ready_label: 'Non Stream Ready',
  has_wifi_label: 'Dispone di WiFi',
  no_wifi_label: 'No WiFi',
  has_bluetooth_label: 'Dispone di Bluetooth',
  no_bluetooth_label: 'No Bluetooth',
  has_usbc_label: 'Dispone di USB-C',
  no_usbc_label: 'No USB-C',
  rgb_included_label: 'Illuminazione RGB inclusa',
  rgb_not_included_label: 'Illuminazione RGB non inclusa',
  products_label: 'Prodotti',
  upsell_modal_label: 'Acquistati spesso insieme',
  no_thanks_label: 'No grazie',
  label_deal_ends: `L'offerta termina in`,
  label_ending_soon: 'L’offerta sta per scadere',
  label_show_less: 'Mostra meno',
  label_show_more: 'Mostra di più',
  label_case: 'Case',
  label_software: 'Software',
  label_services: 'Servizi',
  label_ram: 'RAM',
  label_cpu: 'Processore',
  label_gpu: 'GPU',
  label_motherboard: 'Scheda madre',
  label_storage: 'Supporto di memoria',
  label_cooling: 'Raffreddamento',
  label_psu: 'Alimentatore',
  label_warranty: 'Garanzia',
  label_accessories: 'Accessori',
  label_fps: 'FPS',
  label_feature: 'Caratteristica',
  see_full_details_label: 'Visualizza maggiori dettagli',
  label_more_videos: 'Altri video',
  label_now_playing: 'In riproduzione',
  label_quickshop: 'Acquisto Rapido',
  label_add_to_cart_short: 'Acquista',
  label_added_to_cart: 'Aggiunto al carrello',
  view_cart_label: 'Visualizza Carrello',
  button_label_edit: 'Modifica',
  label_cart_extend_heading: 'Protect Your Purchase',
  label_cart_extend_subheading:
    'Keep your NZXT products protected after the manufacturer warranty expires. Extend covers product replacements, power surges, failure protection, and more.',
  label_cart_affirm_heading: 'Pay Later with Affirm',
  label_cart_affirm_heading_0_apr: '0% APR Financing',
  label_cart_affirm_subheading:
    'Buy now, pay over time. Quick and easy. No hidden fees. Select "Affirm monthly payments" during checkout.',
  label_cta_cart_heading: 'Purchase With Confidence',
  label_deal_sold_out: 'Offerta terminata',
  label_explore: 'Scopri tutti',
  label_ship_date: 'Estimated Ship Date',
  message_shipping_date: 'Ships by',
};

const cart = {
  cart_heading: 'Il tuo carrello',
  cart_heading_empty: 'Il tuo carrello è vuoto',
  cart_subheading_empty: 'Trova prodotti di tuo gusto',
  cart_free_shipping_cta:
    'Ti mancano 25 $ per usufruire della spedizione gratuita!',
  cart_free_shipping_exclusion:
    '(Esclude i PC personalizzati e pre-assemblati)',
  cart_free_shipping_promo_cta: `Spedizione gratuita su tutti gli ordini`,
  cart_heading_error: 'Si è verificato un errore. Ritenta.',
  cart_heading_success: 'Grazie!',
  cart_label_subtotal: 'Totale parziale',
  cart_label_taxes: 'IVA',
  cart_value_taxes: 'Calcolata al momento del pagamento',
  cart_label_shipping: 'Spedizione',
  cart_value_shipping_free: 'GRATIS',
  cart_value_disclaimer:
    '*I prodotti nuovi o ricondizionati potrebbero essere lavorati e spediti con tempistiche diverse.',
  cart_label_total: 'Totale',
  cart_button_checkout: 'Vai alla cassa',
  cart_terms_message: 'Accetto i',
  cart_terms_link_text: 'termini e le condizioni di servizio',
  cart_value_taxes_non_us: 'Inclusa',
  cart_value_shipping_calculated: 'Calcolato al momento del pagamento',
  cart_consent_i_have_read: 'Ho letto il',
  cart_consent_privacy_policy: 'politica sulla riservatezza',
  cart_consent_privacy_policy_after: ' ',
  cart_au_terms_message: 'Accetto il',
  cart_au_terms_link: 'Termini di Servizio',
  cart_au_consent_message:
    "Acconsento alla raccolta e all'utilizzo delle mie informazioni personali in conformità con il",
  cart_au_consent_link: 'Politica Sulla Riservatezza',
  cart_au_terms_text_1:
    'NZXT Pty Ltd (ABN 47 645 981 559) fornisce i beni e servizi che hai ordinato ai sensi della sua',
  cart_au_terms_text_terms_link: 'Termini e Condizioni',
  cart_au_terms_text_2:
    'e raccoglie, utilizza e divulga le informazioni personali che raccoglie da te in conformità con le sue',
  cart_au_terms_text_privacy_link: 'Politica Sulla Riservatezza',
  cart_label_ssd: 'SSD',
  cart_label_hdd: 'HDD',
  cart_label_service: 'Servizi',
  cart_label_software: 'Software',
  cart_label_case: 'Case',
  cart_label_motherboard: 'Scheda madre',
  cart_label_cpu: 'CPU',
  cart_label_gpu: 'GPU',
  cart_label_power_supply: 'Alimentatore',
  cart_label_cpu_cooler: 'CPU Raffreddamento',
  cart_label_rgb: 'RGB',
  cart_label_fans: 'Ventole',
  cart_label_ram: 'RAM',
  cart_label_monitors: 'Monitor',
  cart_label_peripherals: 'Periferiche',
  cart_label_extras: 'Elementi Supplementari',
  message_colors: 'Colori',
  cart_label_keycaps: 'Copritasti',
  cart_label_keycap_accents: 'Accenti dei tasti',
  cart_label_switches: 'Interruttori',
  cart_label_keyboard_base: 'Base della tastiera',
  cart_label_keycap_accent: 'Tasti accentati',
  cart_label_accent_cable: "Cavo d'accento",
  cart_label_mouse: 'Mouse',
  cart_message_item_fallback: 'Questo oggetto',
  cart_message_build_item_oos_before: 'Un articolo della tua',
  cart_message_build_item_oos_after:
    'è esaurito. Fai clic qui per aggiornare la tua build e aggiungerla al carrello.',
  cart_message_build_oos_v2:
    'Un articolo che hai scelto è esaurito. Clicca qui per aggiornare la tua selezione.',
  cart_message_oos:
    'è attualmente esaurito. Si prega di rimuovere questo articolo per procedere al pagamento.',
};

const category = {
  explore_refurbished: 'Esplora le Build',
  explore_bulk: 'Esplora quantità all’ingrosso',
  bulk: 'Quantità all’ingrosso',
  refurbished: 'Ricondizionata',
  refurbished_cta:
    'Abbiamo contribuito a fornire build in grandi quantità ad aziende di videogiochi, università, organizzazioni di sport elettronici e altri..',
  bulk_cta:
    'Abbiamo contribuito a fornire build in grandi quantità ad aziende di videogiochi, università, organizzazioni di sport elettronici e altri.',
};

const geoipBanner = {
  currently_browsing: 'Navigazione attuale',
  banner_region_text:
    'Passa alla tua regione per acquistare o visualizzare contenuti specifici per la tua località.',
  banner_button: 'Passa a',
  banner_country_text_shop: 'Negozio',
  banner_counrty_text:
    'o seleziona un altro paese specifico per la tua posizione.',
  country_us: 'Stati Uniti',
  country_canada: 'Canada',
  country_belgium: 'Belgio',
  country_germany: 'Germania',
  country_luxembourg: 'Lussemburgo',
  country_netherlands: 'Olanda',
  country_austria: 'Austria',
  country_france: 'Francia',
  country_italy: 'Italia',
  country_spain: 'Spagna',
  country_uk: 'Regno Unito',
  country_australia: 'Australia',
  country_nz: 'Nuova Zelanda',
  country_japan: 'Giappone',
  region_na: 'Nord America',
  region_europe: 'Europa',
  region_oceania: 'Oceania',
  region_asia: 'Asia',
};

const ui = {
  loading: 'Caricamento in corso',
};

const footer = {
  label_language: 'Lingua',
  label_country: 'Paese',
  manage_cookie_preferences: 'Gestisci le preferenze sui cookie',
  all_rights_reserved: 'Tutti i diritti sono riservati',
};

const search = {
  label_suggested_queries: 'Ricerche suggerite',
  label_suggested_results: 'Risultati suggeriti',
  label_serp_heading: 'Cerca i risultati per',
  label_serp_not_found: 'Nessun risultato trovato per',
  label_serp_total: 'tutti i risultati',
  label_serp_suggested: 'Prova a cercare',
};

const about = {
  label_customer_support_hours: 'Orari dell’assistenza clienti',
  label_headquarters: 'Sede centrale',
};

const product = {
  overview: 'Panoramica',
  tech_specs: 'Specifiche tecniche',
  downloads: 'Download',
  message_invalid: 'Temporaneamente non disponibile',
  message_out_of_stock: 'Esaurito',
  message_low_stock: 'Disponibile in quantità limitate',
  message_new_item: 'Nuova',
  message_coming_soon: 'Prossimamente',
  message_unavailable: 'Non disponibile',
  title_highlights: 'Punti di forza',
  title_shipping: 'Spedizione',
  title_in_the_box: 'Cosa contiene la scatola',
  button_buy_now: 'Compra ora',
  message_colors: 'Colori',
  message_color: 'Colore',
  message_size: 'Dimensione',
  message_series: 'Serie',
  overview_anchor: 'Panoramica',
  tech_specs_anchor: 'Specifiche tecniche',
  downloads_anchor: 'Download',
  related_anchor: 'Correlato/a',
  customize_build_header: 'Personalizza',
  customize_build_subheader: 'Inizia una build con questo articolo',
  customize_kb_build_subheader: 'Costruire una tastiera personalizzata',
  buy_product_header: 'Fai da te',
  buy_product_subheader: 'Sognala. Costruiscila. Creala.',
  buy_label: 'Compra',
  retailers_subheader: 'Acquista dai nostri partner.',
  link_all_retailers: 'Visualizza tutti i rivenditori',
  series_label: 'Serie',
  label_header_bundle: 'Acquista insieme',
  label_subheader_bundle:
    'Porta la tua build al livello successivo con i prodotti consigliati dagli esperti',
  help_button_label: 'Chatta adesso',
  help_button_heading: 'Hai domande?',
  help_button_subheading: 'I nostri esperti sono pronti ad aiutare.',
  configure_label: 'Configura',
  documentation_label: 'Documentazione',
  drivers_label: 'Driver',
  miscellaneous_label: 'Varie',
  resources_label: 'Risorse',
  everything_you_need_label: 'Everything you need',
  good_rating_label: 'Buono',
  better_rating_label: 'Ottimo',
  best_rating_label: 'Il migliore',
  key_specs_label: 'Specifiche Principali',
  prebuild_upgrade_preproduct: 'Selecting the',
  prebuild_upgrade_postproduct: 'will upgrade your build to a',
  prebuild_socket_cpu: 'CPU',
  prebuild_socket_gpu: 'GPU',
  prebuild_socket_memory: 'Memory',
  prebuild_socket_motherboard: 'Motherboard',
  prebuild_socket_storage: 'Storage',
  prebuild_socket_psu: 'PSU',
  prebuild_heading_specs: 'Specifications',
  prebuild_subheading_specs: 'Current Configuration',
  prebuild_downgrade_preproduct: 'Configure a',
  prebuild_downgrade_postproduct: 'to select the',
  prebuild_no: 'No',
};

const collection = {
  message_invalid: 'Temporaneamente non disponibile',
  message_out_of_stock: 'Esaurito',
  message_low_stock: 'Disponibile in quantità limitate',
  message_new_item: 'Nuova',
  message_coming_soon: 'Prossimamente',
  message_unavailable: 'Non disponibile',
  button_buy_now: 'Compra ora',
  message_colors: 'Colori',
  learn_more: 'Scopri di più',
  message_color: 'Colore',
  message_size: 'Dimensione',
  help_button_label: 'Chatta adesso',
  help_button_heading: 'Hai domande?',
  help_button_subheading: 'I nostri esperti sono pronti ad aiutare.',
};

const news = {
  label_all_news: 'All News',
};

const retailers = {
  label_regions: 'Regioni',
  label_locations: 'Località',
  label_reseller_type: 'Tipo',
  option_label_all_regions: 'Tutte le regioni',
  option_label_all_locations: 'Tutte le località',
  option_label_all_reseller_type: 'Tutte le opzioni',
  message_empty_state: 'Nessun rivenditore corrisponde ai criteri selezionati.',
  hero_text:
    'I prodotti NZXT possono essere acquistati dai nostri partner in tutte le regioni',
};

const searchResults = {
  message_invalid: 'Temporaneamente non disponibile',
  message_out_of_stock: 'Esaurito',
  message_low_stock: 'Disponibile in quantità limitate',
  message_new_item: 'Nuovo',
  message_coming_soon: 'Prossimamente',
  message_unavailable: 'Non disponibile',
};

const orderTracking = {
  title_order_status: "Stato dell'ordine",
  label_cancelled_on: 'Annullato il',
  label_shipping_date: 'data di spedizione stimata',
  label_case: 'Case',
  label_software: 'Software',
  label_services: 'Servizi',
  label_ram: 'RAM',
  label_cpu: 'Processore',
  label_gpu: 'GPU',
  label_motherboard: 'Scheda madre',
  label_storage: 'Supporto di memoria',
  label_cooling: 'Raffreddamento',
  label_psu: 'Alimentatore',
};

const orderTrackingRoot = {
  title_order_tracking_root: 'Inserisci il tuo ID ordine',
  label_build_id: 'ID ordine',
  button_track: 'Ordine di tracciamento',
  message_not_found: 'non trovato, riprova',
};

const refurb = {
  label_all_products: 'Tutti i prodotti',
  label_relevance: 'Rilevanza',
  label_price_low_high: 'Prezzo dal più basso al più alto',
  label_price_high_low: 'Prezzo dal più alto al più basso',
  label_name_a_z: 'Nome A-Z',
  label_name_z_a: 'Nome Z-A',
  label_case: 'Case',
  label_software: 'Software',
  label_services: 'Servizi',
  label_ram: 'RAM',
  label_cpu: 'Processore',
  label_gpu: 'GPU',
  label_motherboard: 'Scheda madre',
  label_storage: 'Supporto di memoria',
  label_cooling: 'Raffreddamento',
  label_psu: 'Alimentatore',
  label_other: 'Altro',
  label_accessories: 'Accessori',
  label_fan: 'Ventole',
  label_any_price: 'Qualsiasi prezzo',
  label_no_products:
    'Al momento non ci sono componenti ricondizionate disponibili.',
  label_no_builds: `Al momento non ci sono build ricondizionate disponibili.`,
};

const orderStatus = {
  confirmed_title: 'Ordine effettuato',
  step_label: 'Passo',
  confirmed_sub: 'Il tuo ordine è stato ricevuto.',
  processed_title: 'Ordine confermato',
  processed_sub:
    'Il tuo ordine è stato ricevuto e il tuo pagamento è stato elaborato.',
  in_progress_title: 'In corso',
  in_progress_sub:
    'Il prelievo e l’assemblaggio del tuo ordine sono iniziati. Il tuo ordine è ora bloccato e modifiche/cancellazioni non possono essere garantite.',
  shipped_title: 'Spedito',
  shipped_sub: 'Il tuo ordine è in arrivo!',
  cancelled_title: 'Annullato',
  cancelled_sub: 'Il tuo ordine è stato annullato con successo.',
  enh_ordered_sub: 'Il tuo ordine è stato ricevuto ed è in coda.',
  enh_picking_title_bld: 'Preparazione della build',
  enh_picking_title: 'Elaborazione del tuo ordine',
  enh_picking_sub_bld:
    "Il ritiro e l'assemblaggio del tuo ordine sono iniziati. Le modifiche al tuo ordine non possono essere garantite.",
  enh_picking_sub:
    'Stiamo preparando il tuo ordine. Le modifiche al tuo ordine non possono essere garantite.',
  enh_build_title: 'Costruzione',
  enh_build_sub: 'Il tuo ordine è in fase di creazione e test.',
  enh_packing_title: 'Preparazione alla spedizione',
  enh_packing_sub_bld:
    'Il tuo ordine è stato creato e testato. Viene imballato per la consegna.',
  enh_packing_sub:
    'Il tuo ordine è stato elaborato e sta per essere imballato per la consegna.',
  enh_shipping_title: 'Spedizione',
  enh_shipping_sub:
    'Il tuo ordine è stato inviato al corriere per la consegna. Il tuo numero di tracciamento verrà aggiornato e inviato via email entro 1 giorno lavorativo.',
};

const universal = {
  ...cart,
  ...userAccount,
  ...auth,
  ...formErrors,
  ...camCta,
  ...generic,
  ...helpCta,
  ...emailSignUp,
  ...geoipBanner,
  ...ui,
  ...footer,
  ...search,
  ...about,
  ...orderStatus,
};

// eslint-disable-next-line import/prefer-default-export
export const it = {
  '/': {
    ...universal,
  },
  '/product/[slug]': {
    ...product,
    ...universal,
  },
  '/collection/[slug]': {
    ...collection,
    ...universal,
  },
  '/partner/[slug]': {
    ...collection,
    ...universal,
  },
  '/sale/[slug]': {
    ...collection,
    ...universal,
  },
  '/about': {
    ...about,
    ...universal,
  },
  '/about/[slug]': {
    ...about,
    ...universal,
  },
  '/legal': {
    ...universal,
  },
  '/legal/[slug]': {
    ...universal,
  },
  '/news/[slug]': {
    ...news,
    ...universal,
    ...category,
  },
  '/news/page/[page]': {
    ...universal,
    ...category,
  },
  '/news/category/[category]': {
    ...universal,
    ...category,
  },
  '/news/category/[category]/page/[page]': {
    ...universal,
    ...category,
  },
  '/user/[slug]': {
    ...universal,
    ...build,
    ...keyboard,
  },
  '/software/[slug]': {
    ...universal,
  },
  '/category/[slug]': {
    ...category,
    ...universal,
  },
  '/category/[slug]/[subcategory-slug]': {
    ...category,
    ...universal,
  },
  '/build': {
    ...universal,
    ...build,
  },
  '/build/[slug]': {
    ...universal,
    ...build,
  },
  '/build/pc': {
    ...universal,
    ...build,
  },
  '/build/pc/[slug]': {
    ...universal,
    ...build,
  },
  '/build/keyboard/[slug]': {
    ...universal,
    ...build,
    ...keyboard,
  },
  '/build/[slug]/summary': {
    ...universal,
    ...build,
  },
  '/support': {
    ...universal,
  },
  '/refurbished/[slug]': {
    ...universal,
    ...refurb,
  },
  '/careers': {
    ...universal,
  },
  '/reviews/page/[page]': {
    ...universal,
  },
  '/careers/available-positions': {
    ...universal,
  },
  '/order-tracking/[slug]': {
    ...orderTracking,
    ...orderTrackingRoot,
    ...universal,
  },
  '/order-tracking': {
    ...orderTrackingRoot,
    ...universal,
  },
  '/retailers': {
    ...retailers,
    ...universal,
  },
  '/search': {
    ...searchResults,
    ...universal,
  },
  '/auth/[slug]': {
    ...universal,
  },
  universal,
};

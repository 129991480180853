/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DEFAULT_COUNTRY_CODE } from '@constants';
import { setShopifyCartCookie } from '@framework/api/utils/shopify-cart-cookie';
import { useCallback } from 'react';
import useCart from './use-cart';

export function extendHook() {
  const useCartId = () => {
    const { mutate } = useCart();

    return useCallback(
      async function getCartId(country: string = DEFAULT_COUNTRY_CODE) {
        if (typeof window !== 'undefined') {
          const cartId = await fetch('/api/maxify/cart', {
            method: 'PATCH',
          }).then(r => r.json());
          const { data } = cartId;
          setShopifyCartCookie(country, data);
          await mutate();
          return data;
        }
        return null;
      },
      [mutate]
    );
  };

  useCartId.extend = extendHook;

  return useCartId;
}

export default extendHook();

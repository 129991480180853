/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    adoric?: any;
  }
}

const Adoric: FC = () => {
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined' && !window?.adoric) {
      (function (a, d, o, r, i, c, u, p, w, m) {
        // eslint-disable-next-line no-unused-expressions, prefer-destructuring, no-param-reassign, no-sequences
        (m = d.getElementsByTagName(o)[0]),
          (a[c] = a[c] || {}),
          (a[c].trigger =
            a[c].trigger ||
            function () {
              (a[c].trigger.arg = a[c].trigger.arg || []).push(arguments);
            }),
          (a[c].on =
            a[c].on ||
            function () {
              (a[c].on.arg = a[c].on.arg || []).push(arguments);
            }),
          (a[c].off =
            a[c].off ||
            function () {
              (a[c].off.arg = a[c].off.arg || []).push(arguments);
            }),
          (w = d.createElement(o)),
          (w.id = i),
          (w.src = r),
          (w.async = 1),
          w.setAttribute(p, u),
          m.parentNode.insertBefore(w, m),
          (w = null);
      })(
        window,
        document,
        'script',
        'https://69581448.adoric-om.com/adoric.js',
        'Adoric_Script',
        'adoric',
        'b1867aadcc131329438ea9d05eac0418',
        'data-key'
      );
    }
  }, [router.asPath]);

  return null;
};

export default Adoric;

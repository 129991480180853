module.exports = [
  'en-US',
  'en-CA',
  'en-BE',
  'en-FR',
  'en-IT',
  'en-DE',
  'en-LU',
  'en-NL',
  'en-AU',
  'en-AT',
  'de-BE',
  'de-FR',
  'de-IT',
  'de-DE',
  'de-LU',
  'de-NL',
  'de-AT',
  'fr-BE',
  'fr-FR',
  'fr-IT',
  'fr-DE',
  'fr-LU',
  'fr-NL',
  'fr-AT',
  'en-GB',
  'en-NZ',
  'en-ES',
  'de-ES',
  'fr-ES',
  'it-BE',
  'es-BE',
  'it-FR',
  'es-FR',
  'it-IT',
  'es-IT',
  'it-DE',
  'es-DE',
  'it-LU',
  'es-LU',
  'it-NL',
  'es-NL',
  'it-AT',
  'es-AT',
  'it-ES',
  'es-ES',
  ...(process.env.NEXT_PUBLIC_FEATURE_ENABLE_JAPAN === 'true' ? ['ja-JP'] : []),
  ...(process.env.NEXT_PUBLIC_FEATURE_LANG_DEPRECATION === 'true'
    ? []
    : [
        'de-US',
        'nl-US',
        'fr-US',
        'it-US',
        'es-US',
        'de-CA',
        'nl-CA',
        'fr-CA',
        'it-CA',
        'es-CA',
        'nl-AT',
        'nl-BE',
        'nl-FR',
        'nl-IT',
        'nl-DE',
        'nl-LU',
        'nl-NL',
        'nl-ES',
        'de-GB',
        'nl-GB',
        'fr-GB',
        'it-GB',
        'es-GB',
        'de-AU',
        'nl-AU',
        'fr-AU',
        'it-AU',
        'es-AU',
        'de-NZ',
        'nl-NZ',
        'fr-NZ',
        'it-NZ',
        'es-NZ',
      ]),
];

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CUSTOMER_TOKEN_COOKIE,
  NEXTAUTH_COOKIE_SESSION_TOKEN,
  NEXTAUTH_COOKIE_CALLBACK_URL,
  NEXTAUTH_COOKIE_CSRF_TOKEN,
} from './auth';
import {
  MAXIFY_TOKEN_COOKIE_NAME,
  SHOPIFY_CART_ID_COOKIE_NAME,
  ZENDESK_ID_COOKIE_NAME,
  SYSTEM_LOCALE_COOKIE_NAME,
  SYSTEM_GEOIP_COOKIE_NAME,
  SYSTEM_AMPLITUDE_COOKIE_NAME,
  SYSTEM_IMPACT_COOKIE_NAME,
  SYSTEM_IMPACT_ID_COOKIE_NAME,
  SYSTEM_IMPACT_EMAIL_COOKIE_NAME,
  SYSTEM_IS_PARTNER_COOKIE_NAME,
  USER_EMAIL_COOKIE_NAME,
  ADORIC_POPUP_COOKIE_NAME,
  EKO_USER_ID_COOKIE_NAME,
  EKO_SESSION_ID_COOKIE_NAME,
} from './system';
import {
  GERMAN_LANGUAGE_CODE,
  DUTCH_LANGUAGE_CODE,
  FRENCH_LANGUAGE_CODE,
  ITALIAN_LANGUAGE_CODE,
  SPANISH_LANGUAGE_CODE,
  JAPANESE_LANGUAGE_CODE,
} from './language';
import {
  DEFAULT_LOCALE,
  DEFAULT_COUNTRY_CODE,
  GERMANY_COUNTRY_CODE,
  AUSTRALIA_COUNTRY_CODE,
  UNITED_KINGDOM_COUNTRY_CODE,
  NEW_ZEALAND_COUNTRY_CODE,
  CANADA_COUNTRY_CODE,
  BELGIUM_COUNTRY_CODE,
  AUSTRIA_COUNTRY_CODE,
  FRANCE_COUNTRY_CODE,
  ITALY_COUNTRY_CODE,
  LUXEMBOURG_COUNTRY_CODE,
  NETHERLANDS_COUNTRY_CODE,
  SPAIN_COUNTRY_CODE,
  JAPAN_COUNTRY_CODE,
} from './location';

// system
export const LOCALHOST_OVERRIDE_SRC =
  'https://apikeys.civiccomputing.com/c/v?d=localhost&';
export const LOCALHOST_OVERRIDE_DEST =
  'https://apikeys.civiccomputing.com/c/v?d=nzxt.com&';
export const CONSENT_COOKIE_EXPIRY = 1825;

const STATEMENT_UPDATED = '30/09/2024';
const COOKIE_POLICY_URL = 'legal/cookie-policy';

// cookie configuration
export const NECESSARY_COOKIES = [
  CUSTOMER_TOKEN_COOKIE,
  NEXTAUTH_COOKIE_SESSION_TOKEN,
  NEXTAUTH_COOKIE_CALLBACK_URL,
  NEXTAUTH_COOKIE_CSRF_TOKEN,
  MAXIFY_TOKEN_COOKIE_NAME,
  `${SHOPIFY_CART_ID_COOKIE_NAME}_${DEFAULT_COUNTRY_CODE}`,
  `${SHOPIFY_CART_ID_COOKIE_NAME}_${CANADA_COUNTRY_CODE}`,
  `${SHOPIFY_CART_ID_COOKIE_NAME}_${GERMANY_COUNTRY_CODE}`,
  `${SHOPIFY_CART_ID_COOKIE_NAME}_${UNITED_KINGDOM_COUNTRY_CODE}`,
  `${SHOPIFY_CART_ID_COOKIE_NAME}_${BELGIUM_COUNTRY_CODE}`,
  `${SHOPIFY_CART_ID_COOKIE_NAME}_${AUSTRIA_COUNTRY_CODE}`,
  `${SHOPIFY_CART_ID_COOKIE_NAME}_${FRANCE_COUNTRY_CODE}`,
  `${SHOPIFY_CART_ID_COOKIE_NAME}_${ITALY_COUNTRY_CODE}`,
  `${SHOPIFY_CART_ID_COOKIE_NAME}_${LUXEMBOURG_COUNTRY_CODE}`,
  `${SHOPIFY_CART_ID_COOKIE_NAME}_${NETHERLANDS_COUNTRY_CODE}`,
  `${SHOPIFY_CART_ID_COOKIE_NAME}_${SPAIN_COUNTRY_CODE}`,
  `${SHOPIFY_CART_ID_COOKIE_NAME}_${AUSTRALIA_COUNTRY_CODE}`,
  `${SHOPIFY_CART_ID_COOKIE_NAME}_${NEW_ZEALAND_COUNTRY_CODE}`,
  `${SHOPIFY_CART_ID_COOKIE_NAME}_${JAPAN_COUNTRY_CODE}`,
  ZENDESK_ID_COOKIE_NAME,
  SYSTEM_AMPLITUDE_COOKIE_NAME,
  SYSTEM_IMPACT_COOKIE_NAME,
  SYSTEM_IMPACT_ID_COOKIE_NAME,
  SYSTEM_IMPACT_EMAIL_COOKIE_NAME,
  SYSTEM_LOCALE_COOKIE_NAME,
  SYSTEM_GEOIP_COOKIE_NAME,
  SYSTEM_IS_PARTNER_COOKIE_NAME,
  USER_EMAIL_COOKIE_NAME,
  ADORIC_POPUP_COOKIE_NAME,
  'FPID',
  '_vercel_jwt',
  EKO_USER_ID_COOKIE_NAME,
  EKO_SESSION_ID_COOKIE_NAME,
];

// text/verbiage
export const TEXT = {
  notifyTitle: `NZXT uses cookies to enhance your user experience, improve our websites, and deliver ads that may interest you.`,
  notifyDescription: `We use cookies to optimize site functionality and give you the best possible experience.`,
  accept: `Accept All Cookies`,
  necessaryTitle: `Essential`,
  necessaryDescription: `Essential cookies are necessary for our services to function properly and to help identify and prevent security risks. We may also use these cookies to remember your settings and preferences.`,
  acceptRecommended: `Accept All Cookies`,
};

const STATEMENT = {
  description: 'Learn More',
  name: 'in our Cookie Policy',
  url: COOKIE_POLICY_URL,
  updated: STATEMENT_UPDATED,
};

const STATEMENT_DE = {
  description: 'Erfahre mehr über',
  name: 'unsere Cookie-Richtlinien',
  url: COOKIE_POLICY_URL,
  updated: STATEMENT_UPDATED,
};

const STATEMENT_FR = {
  description: 'Pour en savoir plus, ss',
  name: 'consultez notre politique en matière de cookies',
  url: COOKIE_POLICY_URL,
  updated: STATEMENT_UPDATED,
};

const STATEMENT_IT = {
  description: 'Per maggiori informazioni, ',
  name: 'consulta la nostra Informativa sui cookie',
  url: COOKIE_POLICY_URL,
  updated: STATEMENT_UPDATED,
};

const STATEMENT_NL = {
  description: 'Kom meer te weten',
  name: 'in ons cookiebeleid',
  url: COOKIE_POLICY_URL,
  updated: STATEMENT_UPDATED,
};

const STATEMENT_ES = {
  description: 'Más información',
  name: 'en nuestra Política de Cookies',
  url: COOKIE_POLICY_URL,
  updated: STATEMENT_UPDATED,
};
const STATEMENT_JA = {
  description: 'Japanese More Info',
  name: 'Japanese Cookie Policy',
  url: COOKIE_POLICY_URL,
  updated: STATEMENT_UPDATED,
};

type Statement = {
  description: string;
  name: string;
  url: string;
  updated: string;
};

export const getStatement = (
  locale: string,
  statement?: Statement
): Statement => {
  const statementObj = statement || STATEMENT;

  return {
    ...statementObj,
    url:
      locale && locale !== DEFAULT_LOCALE
        ? `/${locale}/${COOKIE_POLICY_URL}`
        : `/${COOKIE_POLICY_URL}`,
  };
};

export const LABEL_ANALYTICS = `Analytics and Performance`;
export const DESCRIPTION_ANALYTICS = `Analytics cookies collect information about how you interact with our services to help us improve them.`;

export const LABEL_MARKETING = `Social Media and Marketing`;
export const DESCRIPTION_MARKETING = `Social Media and Marketing cookies let you connect to your social networks and share content from our websites through social media.  We also use these to deliver ads, make ads more relevant, and track ad campaigns.`;

const LOCALE_DE = {
  locale: GERMAN_LANGUAGE_CODE,
  text: {
    title: `Diese Seite verwendet Cookies, um Informationen auf deinem Computer zu speichern.`,
    intro: `Einige dieser Cookies sind unerlässlich, während uns andere helfen, deine Nutzererfahrung zu verbessern, indem sie uns Einblicke gewähren, wie die Seite genutzt wird.`,
    settings: `Einstellungen`,
    notifyTitle: `NZXT verwendet Cookies, um die Nutzererfahrung und unsere Website zu verbessern und um dir Werbung anzubieten, die für dich interessant sein könnte.`,
    notifyDescription: `Wir verwenden Cookies, um die Website-Funktionen zu optimieren und dir die beste Benutzererfahrung zu ermöglichen.`,
    accept: `Alle Cookies akzeptieren`,
    reject: `Ablehnen`,
    necessaryTitle: `Unerlässlich`,
    necessaryDescription: `Unerlässliche Cookies sind notwendig, damit unsere Dienste richtig funktionieren können und um dabei zu helfen, Sicherheitslücken zu erkennen und zu verhindern. Wir nutzen diese Cookies gegebenenfalls auch, um deine Einstellungen und Vorlieben zu speichern.`,
    acceptRecommended: `Alle Cookies akzeptieren`,
    statement: STATEMENT_DE,
    optionalCookies: [
      {
        label: `Analysen und Leistung`,
        description: `Analyse-Cookies sammeln Informationen darüber, wie du mit unseren Angeboten interagierst und helfen uns dabei, diese zu verbessern.`,
      },
      {
        label: `Social Media und Marketing`,
        description: `Mit Social Media- und Marketing-Cookies kannst du dich mit deinen sozialen Netzwerken verbinden und Inhalte von unseren Websites über soziale Medien teilen. Wir verwenden diese Cookies auch, um Werbeanzeigen zu schalten, die Relevanz von Werbeanzeigen zu erhöhen und Werbekampagnen zu überwachen.`,
      },
    ],
  },
};

const LOCALE_FR = {
  locale: FRENCH_LANGUAGE_CODE,
  text: {
    title:
      'Ce site utilise des cookies pour stocker des informations sur votre ordinateur.',
    intro: `Certains de ces cookies sont essentiels, tandis que d'autres nous aident à améliorer votre expérience en nous donnant des informations sur la façon dont le site est utilisé.`,
    settings: 'Paramètres',
    notifyTitle: `NZXT utilise des cookies pour améliorer votre expérience utilisateur, améliorer nos sites Web et diffuser des publicités susceptibles de vous intéresser.`,
    notifyDescription: `Nous utilisons des cookies pour optimiser la fonctionnalité du site et vous offrir la meilleure expérience possible.`,
    accept: 'Accepter tous les cookies',
    reject: `Je refuse`,
    necessaryTitle: 'Essentiels',
    necessaryDescription: `Les cookies essentiels sont nécessaires au bon fonctionnement de nos services et à l'identification et à la prévention des risques de sécurité. Nous pouvons également utiliser ces cookies pour mémoriser vos paramètres et préférences.`,
    acceptRecommended: 'Accepter tous les cookies',
    statement: STATEMENT_FR,
    optionalCookies: [
      {
        label: 'Analyse et performance',
        description: `Les cookies d'analyse collectent des informations sur la façon dont vous interagissez avec nos services pour nous aider à les améliorer.`,
      },
      {
        label: 'Médias sociaux et marketing',
        description: `Les cookies de réseaux sociaux et de marketing vous permettent de vous connecter à vos réseaux sociaux et de partager le contenu de nos sites Web via les réseaux sociaux. Nous utilisons également ces cookies pour placer des publicités, augmenter la pertinence des publicités et surveiller les campagnes publicitaires.`,
      },
    ],
  },
};

const LOCALE_IT = {
  locale: ITALIAN_LANGUAGE_CODE,
  text: {
    title:
      'Questo sito utilizza i cookie per memorizzare informazioni sul tuo computer.',
    intro: `Alcuni di questi cookie sono essenziali, altri ci aiutano a migliorare la tua esperienza fornendoci indicazioni su come si utilizza il sito.`,
    settings: 'Impostazioni',
    notifyTitle: `NZXT utilizza i cookie per valorizzare la tua esperienza di utente, migliorare i nostri siti web e proporre annunci che potrebbero essere di tuo interesse.`,
    notifyDescription: `Utilizziamo i cookie per ottimizzare la funzionalità del sito e offrirti la miglior esperienza possibile.`,
    accept: 'Accetta tutti i cookie',
    reject: 'Non accetto',
    necessaryTitle: 'Essenziali',
    necessaryDescription: `I cookie essenziali sono necessari per il corretto funzionamento dei nostri servizi e al fine di individuare e prevenire rischi per la sicurezza. Possiamo utilizzare questi cookie anche per ricordare le tue impostazioni e preferenze.`,
    acceptRecommended: 'Accetta tutti i cookie',
    statement: STATEMENT_IT,
    optionalCookies: [
      {
        label: 'Analitici e di performance',
        description: `I cookie analitici raccolgono informazioni sulle modalità in cui interagisci con i nostri servizi e questo ci aiuterà a migliorarli.`,
      },
      {
        label: 'Social media e marketing',
        description: `I cookie di social media e marketing ti consentono di connetterti ai tuoi social network e condividere contenuti dai nostri siti Web attraverso i social media. Utilizziamo questi cookie anche per inserire annunci pubblicitari, aumentare la rilevanza degli annunci e monitorare le campagne pubblicitarie.`,
      },
    ],
  },
};

const LOCALE_NL = {
  locale: DUTCH_LANGUAGE_CODE,
  text: {
    title:
      'Deze site maakt gebruik van cookies om informatie op je computer op te slaan.',
    intro: `Enkele van deze cookies zijn essentieel, terwijl andere ons helpen om je ervaring te verbeteren door inzicht te bieden in hoe de site wordt gebruikt.`,
    settings: 'Instellingen',
    notifyTitle: `NZXT maakt gebruik van cookies die je gebruikerservaring verbeteren, onze websites verbeteren en advertenties weergeven die je mogelijk interessant vindt.`,
    notifyDescription: `We maken gebruik van cookies voor een optimaal werkende website en om je de beste ervaring te bieden.`,
    accept: 'Alle cookies accepteren',
    reject: 'Weigeren',
    necessaryTitle: 'Essentieel',
    necessaryDescription: `Essentiële cookies zijn noodzakelijk voor onze diensten om correct te werken en om veiligheidsrisico's te identificeren en te voorkomen. We kunnen deze cookies ook gebruiken om je instellingen en voorkeuren te onthouden.`,
    acceptRecommended: 'Alle cookies accepteren',
    statement: STATEMENT_NL,
    optionalCookies: [
      {
        label: 'Analyses en prestaties',
        description: `Analytische cookies verzamelen informatie over hoe je interactie hebt met onze diensten zodat wij deze kunnen verbeteren.`,
      },
      {
        label: 'Sociale media en marketing',
        description: `Met sociale media- en marketingcookies kunt u verbinding maken met uw sociale netwerken en inhoud van onze websites delen via sociale media. We gebruiken deze cookies ook om advertenties te plaatsen, de relevantie van advertenties te vergroten en advertentiecampagnes te monitoren.`,
      },
    ],
  },
};

const LOCALE_ES = {
  locale: SPANISH_LANGUAGE_CODE,
  text: {
    title:
      'Este sitio utiliza cookies para almacenar información en su ordenador.',
    intro: `Algunas de estas cookies son esenciales, otras nos ayudan a mejorar su experiencia al proporcionarnos información sobre cómo utiliza el sitio.`,
    settings: 'Ajustes',
    notifyTitle: `NZXT utiliza cookies para mejorar su experiencia de usuario, mejorar nuestros sitios web y publicar anuncios que puedan ser de su interés.`,
    notifyDescription: `Utilizamos cookies para optimizar la funcionalidad del sitio y ofrecerle la mejor experiencia posible.`,
    accept: 'Aceptar todas las cookies',
    reject: 'No acepto',
    necessaryTitle: 'Esenciales',
    necessaryDescription: `Las cookies esenciales son necesarias para el correcto funcionamiento de nuestros servicios y para detectar y prevenir riesgos de seguridad. También podemos utilizar estas cookies para recordar sus ajustes y preferencias.`,
    acceptRecommended: 'Aceptar todas las cookies',
    statement: STATEMENT_ES,
    optionalCookies: [
      {
        label: 'Análisis y rendimiento',
        description: `Las cookies analíticas recogen información sobre cómo interactúa usted con nuestros servicios, lo que nos ayudará a mejorarlos.`,
      },
      {
        label: 'Redes sociales y marketing',
        description: `Las cookies de redes sociales y de marketing le permiten conectarse a sus redes sociales y compartir el contenido de nuestros sitios web a través de las redes sociales. También utilizamos estas cookies para colocar anuncios, aumentar la relevancia de los anuncios y supervisar las campañas publicitarias.`,
      },
    ],
  },
};
const LOCALE_JA = {
  locale: JAPANESE_LANGUAGE_CODE,
  text: {
    title:
      'このサイトでは、コンピューターに情報を保存するためにクッキーを使用しています。',
    intro: `これらのクッキーの一部は不可欠であり、その他のクッキーは、サイトの使用方法に関する情報を提供することにより、ユーザーエクスペリエンスの向上に役立ちます。`,
    settings: '設定',
    notifyTitle: `NZXTは、ユーザーエクスペリエンスの向上、ウェブサイトの改善、および関心のある広告の掲載のためにクッキーを使用しています。`,
    notifyDescription: `当社は、サイトの機能を最適化し、最高のエクスペリエンスを提供するためにクッキーを使用しています。`,
    accept: 'すべてのクッキーを受け入れる',
    reject: '受け入れない',
    necessaryTitle: '必須',
    necessaryDescription: `必須のクッキーは、当社のサービスを正常に機能させるために必要であり、セキュリティリスクを検出して防止するために使用されます。また、これらのクッキーを使用して、設定と嗜好を記憶することもできます。`,
    acceptRecommended: 'すべてのクッキーを受け入れる',
    statement: STATEMENT_JA,
    optionalCookies: [
      {
        label: '分析とパフォーマンス',
        description: `分析クッキーは、当社のサービスとのやり取りに関する情報を収集し、サービスの改善に役立ちます。`,
      },
      {
        label: 'ソーシャルメディアとマーケティング',
        description: `ソーシャルメディアとマーケティングのクッキーにより、ソーシャルネットワークに接続し、ソーシャルメディアを通じてウェブサイトのコンテンツを共有することができます。また、これらのクッキーを使用して広告を配置し、広告の関連性を高め、広告キャンペーンを監視します。`,
      },
    ],
  },
};

type LocaleObj = {
  locale: string;
  text: {
    title: string;
    intro: string;
    settings: string;
    notifyTitle: string;
    notifyDescription: string;
    accept: string;
    reject: string;
    necessaryTitle: string;
    necessaryDescription: string;
    acceptRecommended: string;
    statement: Statement;
    optionalCookies: Array<any>;
  };
};

export const getLocales = (locale: string): LocaleObj[] => {
  const de = LOCALE_DE;
  de.text.statement = getStatement(locale, STATEMENT_DE);

  const fr = LOCALE_FR;
  fr.text.statement = getStatement(locale, STATEMENT_FR);

  const it = LOCALE_IT;
  it.text.statement = getStatement(locale, STATEMENT_IT);

  const nl = LOCALE_NL;
  nl.text.statement = getStatement(locale, STATEMENT_NL);

  const es = LOCALE_ES;
  es.text.statement = getStatement(locale, STATEMENT_ES);

  const ja = LOCALE_JA;
  ja.text.statement = getStatement(locale, STATEMENT_JA);

  return [
    de,
    fr,
    it,
    nl,
    es,
    ...(process.env.NEXT_PUBLIC_FEATURE_ENABLE_JAPAN === 'true' ? [ja] : []),
  ];
};

// style configuration
export const BRANDING_DEFAULT = {
  fontFamily:
    'Gotham SSm,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif',
  fontColor: '#F8F8F8',
  fontSizeTitle: '22px',
  fontSizeIntro: '12px',
  backgroundColor: 'rgba(50, 50, 50, 0.9)',
  toggleColor: 'rgba(50, 50, 50, 0.9)',
  buttonIcon: null,
  buttonIconWidth: '64px',
  buttonIconHeight: '64px',
  removeIcon: true,
  removeAbout: true,
  acceptBackground: '#F8F8F8',
  acceptText: '#4a4a4a',
};

/* eslint-disable */
/* tslint:disable */
import { useState, useEffect } from 'react';
import mediaQueries from './mediaQueries';

const useMedia = (query: string): boolean => {
  if (typeof window !== 'object') return;
  if (!window.matchMedia) return;

  const queryToMatch = mediaQueries[query];
  const [match, setMatch] = useState(false);

  useEffect(() => {
    let mounted = true;
    const mediaQueryWindow = window.matchMedia(queryToMatch);

    const onChange = (): void => {
      if (!mounted) {
        return;
      }
      setMatch(Boolean(mediaQueryWindow.matches));
    };

    mediaQueryWindow.hasOwnProperty('addEventListener')
      ? mediaQueryWindow.addEventListener('change', onChange)
      : mediaQueryWindow.addListener(onChange);

    setMatch(mediaQueryWindow.matches);

    return () => {
      mounted = false;
      mediaQueryWindow.hasOwnProperty('removeEventListener')
        ? mediaQueryWindow.removeEventListener('change', onChange)
        : mediaQueryWindow.removeListener(onChange);
    };
  }, [queryToMatch]);

  return match;
};

export default useMedia;

/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

export const CUSTOMER_TOKEN_COOKIE = 'customerToken';
export const NEXTAUTH_COOKIE_SESSION_TOKEN = 'next-auth.session-token';
export const NEXTAUTH_COOKIE_CALLBACK_URL = 'next-auth.callback-url';
export const NEXTAUTH_COOKIE_CSRF_TOKEN = 'next-auth.csrf-token';

export const USERNAME_PATTERN_REGEX =
  /(?=^.{6,30}$)^(?!\.)(?!.*\.$)(?!.*?\.\.)[a-zA-Z0-9.]+$/g;

const getSessionStorage = (key: string): string | null => {
  const storage = globalThis?.sessionStorage;
  if (!storage) return null;
  try {
    return JSON.parse(storage.getItem(key));
  } catch (err) {
    return null;
  }
};
const setSessionStorage = (
  key: string,
  value: string | Record<string, unknown>
): void => {
  const storage = globalThis?.sessionStorage;
  if (!storage) return;

  storage.setItem(key, JSON.stringify(value));
};

export { getSessionStorage, setSessionStorage };

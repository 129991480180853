/* eslint-disable max-len */

import {
  DEFAULT_LANGUAGE_CODE,
  DUTCH_LANGUAGE_CODE,
  FRENCH_LANGUAGE_CODE,
  GERMAN_LANGUAGE_CODE,
  ITALIAN_LANGUAGE_CODE,
  SPANISH_LANGUAGE_CODE,
} from './language';

// TODO - clean up these placeholders, for the header/footer component
export const TITLE_HOME = 'Home';
export const TITLE_BLOG = 'Blog';
export const TITLE_CONTACT = 'Contact';
export const TITLE_PC_BUILDING = 'PC Building';
export const TITLE_GAMING_PC = 'Gaming PCs';
export const TITLE_PERIPHERALS = 'Peripherals';
export const TITLE_PC_SOFTWARE = 'Software';
export const TITLE_SUPPORT = 'Support';
export const TITLE_COMMUNITY = 'Community';
export const TITLE_PRIVACY = 'PRIVACY & LEGAL';

export const MINIMAL_PROFILE_LINKS = [
  { title: 'user_settings_title', href: '/user/settings' },
  { title: 'user_saved_builds_heading', href: '/user/saved-bld' },
];

export const PROFILE_LINKS = [
  { title: 'user_settings_title', href: '/user/settings' },
  { title: 'user_saved_builds_heading', href: '/user/saved-bld' },
  { title: 'user_order_history_heading', href: '/user/order-history' },
  { title: 'user_support_heading', href: '/user/support' },
];

export const SUPPORT_URL_MAP = {
  [DEFAULT_LANGUAGE_CODE]: 'https://support.nzxt.com/hc/en-us',
  [GERMAN_LANGUAGE_CODE]: 'https://support.nzxt.com/hc/de',
  [DUTCH_LANGUAGE_CODE]: 'https://support.nzxt.com/hc/nl',
  [FRENCH_LANGUAGE_CODE]: 'https://support.nzxt.com/hc/fr',
  [ITALIAN_LANGUAGE_CODE]: 'https://support.nzxt.com/hc/it',
  [SPANISH_LANGUAGE_CODE]: 'https://support.nzxt.com/hc/es',
};
export const FALLBACK_NAV_SLUG = 'main';

export const CONFIG_NAV_LINKS = [
  { title: 'All', href: '/build' },
  { title: 'PC', href: '/build/pc' },
  {
    title: 'Keyboard',
    href: '/build/keyboard',
  },
];

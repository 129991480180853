import { setCookie } from 'nookies';
import { v4 as uuidv4 } from 'uuid';
import { MAXIFY_TOKEN_COOKIE_NAME, SYSTEM_COOKIE_MAX_AGE } from '@constants';

const setToken = (locale: string, prevToken?: string): void => {
  setCookie(
    null,
    MAXIFY_TOKEN_COOKIE_NAME,
    `${prevToken || uuidv4()}-${locale}`,
    {
      maxAge: SYSTEM_COOKIE_MAX_AGE,
      expires: new Date(Date.now() + SYSTEM_COOKIE_MAX_AGE * 1000),
      secure: process.env.NODE_ENV === 'production',
      path: '/',
      sameSite: 'lax',
    }
  );
};

export default setToken;

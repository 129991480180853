/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import {
  ReactNode,
  MutableRefObject,
  createContext,
  useContext,
  useMemo,
  useRef,
} from 'react';
import * as React from 'react';
import { Fetcher } from '../utils/types';

const Commerce = createContext<CommerceContextValue | {}>({});

export type CommerceProps = {
  children?: ReactNode;
  config: CommerceConfig;
};

export type CommerceConfig = { fetcher: Fetcher<any> } & Omit<
  CommerceContextValue,
  'fetcherRef'
>;

export type CommerceContextValue = {
  fetcherRef: MutableRefObject<Fetcher<any>>;
  locale: string;
  cartCookie: string;
  cartCookieValue: string;
  shopifyCartCookie: string;
};

export function CommerceProvider({ children, config }: CommerceProps) {
  if (!config) {
    throw new Error('CommerceProvider requires a valid config object');
  }

  const fetcherRef = useRef(config.fetcher);

  const cfg = useMemo(
    () => ({
      fetcherRef,
      locale: config.locale,
      cartCookie: config.cartCookie,
      cartCookieValue: config.cartCookieValue,
      shopifyCartCookie: config.shopifyCartCookie,
    }),
    [
      config.locale,
      config.cartCookie,
      config.cartCookieValue,
      config.shopifyCartCookie,
    ]
  );

  return <Commerce.Provider value={cfg}>{children}</Commerce.Provider>;
}

export function useCommerce<T extends CommerceContextValue>() {
  return useContext(Commerce) as T;
}

'use client';

import Script from 'next/script';
import { FRAGILE_SCRIPT_URL } from '../../constants/fragile';

function FragileDynamicPriceLoader(): JSX.Element {
  // if on dev, load the script later to avoid hydration error modal
  const strategy =
    process.env.NODE_ENV === 'development'
      ? 'afterInteractive'
      : 'beforeInteractive';

  return <Script src={FRAGILE_SCRIPT_URL} strategy={strategy} />;
}

export default FragileDynamicPriceLoader;

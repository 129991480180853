// supported config options: https://github.com/garmeeh/next-seo
// eslint-disable-next-line import/prefer-default-export
export const SEO_DEFAULTS = {
  titleTemplate: '%s | NZXT',
  additionalMetaTags: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1',
    },
  ],
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://nzxt.com/',
    site_name: 'NZXT',
  },
  twitter: {
    handle: '@NZXT',
    site: '@NZXT',
    cardType: 'summary_large_image',
  },
};

/* eslint-disable no-nested-ternary */
import getShopifyProductByHandle from '@utils/get-shopify-product-by-handle';

const getExtendPricePoint = async (planDetails): Promise<any> => {
  // divide plan price in cents
  const planPriceDollar = planDetails.price / 100;

  // create warrantyHandle to fetch extend-protection-plans-1 2 or 3 from shopify catalog
  const warrantyHandle = `extend-protection-plans-${
    planPriceDollar <= 49.99 ? '1' : planPriceDollar <= 4999.0 ? '2' : '3'
  }`;

  // Create specific SKU variant we look for within the warrantyHandle
  const sku = `extend-protection-plan-${
    planPriceDollar.toString().endsWith('.99')
      ? planPriceDollar
      : `${planPriceDollar}.00`
  }`;

  const { data } = (await getShopifyProductByHandle(warrantyHandle)) as any;

  // Fetch specific SKU variant matching the SKU variable defined above
  const itemToAdd = data.product.variants.edges.filter(
    variant => variant.node.sku.toLowerCase() === sku
  )[0].node.id;

  // Convert warranty ItemId and get Shopify variantId
  const selectedWarranty = itemToAdd.split('/ProductVariant/')[1];

  return selectedWarranty;
};

export default getExtendPricePoint;

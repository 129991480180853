import { MAXIFY_REGION_NORTHERN_AMERICA } from '@constants';
import getLang from './get-lang';
import getRegion from './get-region';
import getToken from './get-token';

type MaxifyHeaders = {
  locale: string;
  region: string;
  token: string;
};

export default function getMaxifyHeaders(locale: string): MaxifyHeaders {
  const lang = getLang(locale);

  let region = getRegion(locale);

  if (region === 'asia') {
    region = MAXIFY_REGION_NORTHERN_AMERICA;
  }

  const token = getToken() || ' ';

  const headers: MaxifyHeaders = {
    locale: lang,
    region,
    token,
  };

  return headers;
}

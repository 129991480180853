import waitForGlobal from '@utils/wait-for-global';

const onAccept = (): void => {
  waitForGlobal('zE', () => {
    if (process.env.NEXT_PUBLIC_FEATURE_FLOWBUILDER === 'true') {
      window.zE('messenger:set', 'cookies', true);
    } else {
      window.zE('webWidget', 'updateSettings', {
        cookies: true,
      });
    }
  });
};

export default onAccept;

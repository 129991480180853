import { parseCookies } from 'nookies';
import { SYSTEM_LOCALE_COOKIE_NAME } from '@constants';

type GetLocaleCookie = () => string | undefined;

const getToken: GetLocaleCookie = () => {
  const cookies = parseCookies();

  return cookies[SYSTEM_LOCALE_COOKIE_NAME];
};

export default getToken;

import create from 'zustand';
import { devtools } from 'zustand/middleware';
import type { SetState } from 'zustand';
import type { State } from './types';

const initialState = {
  lastProduct: null,
  setLastProduct: () => null,
};

const cartStore = (set: SetState<State>): State => ({
  ...initialState,
  // Cart Actions
  setLastProduct: (id: number) =>
    set(() => ({
      lastProduct: id,
    })),
  clearLastProduct: () => set(() => ({ lastProduct: null })),
});

const useCartStore =
  process.env.NODE_ENV === 'development'
    ? create<State>(devtools(cartStore))
    : create<State>(cartStore);

export default useCartStore;

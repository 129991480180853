import {
  DEFAULT_LOCALE,
  MAXIFY_LOCALE_MAP,
  MAXIFY_LOCALE_MAP_MULTIPLE_LOCALES_PER_LANG,
} from '@constants';

export default function getLang(locale: string): string {
  // these are special cases specific to English and Chinese
  if (MAXIFY_LOCALE_MAP_MULTIPLE_LOCALES_PER_LANG[locale]) {
    return MAXIFY_LOCALE_MAP_MULTIPLE_LOCALES_PER_LANG[locale];
  }

  const parsedLocale = locale
    ? locale.split('-')[0]
    : DEFAULT_LOCALE.split('-')[0];

  return MAXIFY_LOCALE_MAP[parsedLocale.toLowerCase()];
}

// eslint-disable-next-line import/prefer-default-export
export const COOKIES = [
  'AMP_',
  'amp_',
  'intercom-device-id-',
  '_gcl_au',
  '_ga',
  '_gid',
  '__utmzzses',
  '__utmzz',
  'access_token_production',
  'org_login_production',
  '_mkto_trk',
  'intercom-session-',
  'AMP_*',
  'amp_*',
  'amp*',
  'AMP*',
];

import { track } from '@amplitude/analytics-browser';
import type {
  ShopifyCart,
  ShopifyLineEdges,
  ItemBody,
} from '@framework/api/cart';
import type {
  AmplitudeProduct,
  AddToCartProperties,
  Placement,
  CartSource,
} from '../types';
import getCartCount from '../get-cart-count';

type TrackAddToCartArgs = {
  cart: ShopifyCart;
  referrer?: string;
  remove?: boolean;
  removeId?: string | string[];
  addedIds?: ItemBody[];
  lastCart?: ShopifyCart;
  source?: CartSource;
};

type CreateProductsArgs = {
  products: ShopifyLineEdges[];
  placement: Placement;
};

// TODO: @Tatiana these were throwing errors whilst testing
const createProducts = ({
  products,
  placement,
}: CreateProductsArgs): AmplitudeProduct[] => {
  const productArray = products.map((product, i) => {
    const variantName = `${product?.node?.merchandise?.product?.title} - ${product?.node?.merchandise?.title}`;

    const comparePrice = parseFloat(
      product?.node?.merchandise?.compareAtPriceV2?.amount
    );
    const regPrice = parseFloat(product?.node?.merchandise?.priceV2?.amount);
    const discount = parseFloat(
      product?.node?.discountAllocations[0]?.discountedAmount?.amount
    );

    const productQuant = product?.node.quantity || 1;
    const discountPrice = regPrice - discount / productQuant;

    const ampProduct: AmplitudeProduct = {
      'product id': parseInt(
        // atob(product?.node.merchandise.product.id).split('/Product/')[1],
        product?.node.merchandise.product.id.split('/Product/')[1],
        10
      ),
      'product variant id': parseInt(
        // atob(product?.node.merchandise.id).split('/ProductVariant/')[1],
        product?.node.merchandise.id.split('/ProductVariant/')[1],
        10
      ),
      'product sku': product?.node?.merchandise?.sku,
      'product category': product?.node?.merchandise?.product?.productType,
      'product name': variantName,
      'product price': Number.isNaN(comparePrice) ? regPrice : comparePrice,
      'discount price': Number.isNaN(discountPrice) ? regPrice : discountPrice,
      'product availability': null,
      'is inventory countdown enabled': 'no',
      'is timer countdown enabled': 'no',
      // not available in cart payload
      'service item shown': null,
      // not available in cart payload
      'is warranty shown': 'no',
      // this will always be empty on product details view, should be populated on cart when product is a warranty
      'warranty selected':
        product?.node?.merchandise?.title?.indexOf('Extend Protection Plan') >
        -1
          ? parseInt(product?.node?.merchandise?.title?.split('/')[1], 10)
          : null,
      // rank will be null on primary product, but should be populated for products in arrays
      rank: i,
      placement,
    };

    return ampProduct;
  });

  return productArray.filter(Boolean);
};

const trackAddToCart = ({
  cart,
  referrer,
  remove = false,
  removeId,
  addedIds,
  lastCart,
  source,
}: TrackAddToCartArgs): void => {
  if (cart) {
    let productArray = createProducts({
      products: cart.lines.edges,
      placement: source,
    });

    if (addedIds) {
      const ids = addedIds.map(
        i => `gid://shopify/ProductVariant/${i.shopify_variants_id}`
      );

      const addedLines = cart.lines.edges.filter(e =>
        ids.includes(e.node.merchandise.id)
      );

      productArray = createProducts({
        products: addedLines,
        placement: source,
      });
    }

    if (remove) {
      const removedItem = lastCart.lines.edges.find(
        e => e.node.id === removeId
      );

      productArray = createProducts({
        products: [removedItem],
        placement: 'cart',
      });
    }

    const eventProperties: AddToCartProperties = {
      'cart id': cart.id,
      'total cart size': getCartCount(cart),
      'total cart value': parseFloat(cart.estimatedCost.subtotalAmount.amount),
      'total discounts': cart.lines.edges.reduce(
        (prev, curr) =>
          prev +
          curr.node.discountAllocations.reduce(
            (p, c) => p + parseFloat(c.discountedAmount.amount),
            0
          ),
        0
      ),
      'page url': window.location.href,
      'referrer source': referrer,
      'product array': productArray,
    };

    const eventName = remove ? 'cart product removed' : 'clicks add to cart';

    track(eventName, eventProperties);
  }
};

export default trackAddToCart;

import { parseCookies } from 'nookies';
import { SHOPIFY_CART_ID_COOKIE_NAME, DEFAULT_COUNTRY_CODE } from '@constants';

type GetShopifyCartCookie = (country: string) => string | undefined;

const getShopifyCartCookie: GetShopifyCartCookie = (
  country: string = DEFAULT_COUNTRY_CODE
) => {
  const cookies = parseCookies();

  return cookies[`${SHOPIFY_CART_ID_COOKIE_NAME}_${country}`];
};

export default getShopifyCartCookie;

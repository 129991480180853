declare global {
  interface Window {
    blocker: boolean;
  }
}

const checkForAdBlocker = async (): Promise<boolean> => {
  let blocked;

  async function Request(): Promise<boolean> {
    try {
      return fetch(
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
        {
          method: 'HEAD',
          mode: 'no-cors',
        }
      )
        .then(_ => {
          // There is no AdBlocker
          blocked = false;
          return blocked;
        })
        .catch(_ => {
          // Failed, Because of an AdBlocker
          blocked = true;
          return blocked;
        });
    } catch (error) {
      console.log(error);
      blocked = true;
      return blocked;
    }
  }

  // eslint-disable-next-line no-return-await
  return blocked !== undefined ? blocked : await Request();
};

export default checkForAdBlocker;

import type {
  ShopifyCart,
  ShopifyLineEdges,
  ItemBody,
} from '@framework/api/cart';
import type { CartSource } from '../../amplitude/types';

type TrackAddToCartArgs = {
  cart: ShopifyCart;
  remove?: boolean;
  removeId?: string | string[];
  addedIds?: ItemBody[];
  lastCart?: ShopifyCart;
  source?: CartSource;
};

type KlaviyoCartItem = {
  ProductID: string;
  SKU: string;
  ProductName: string;
  Quantity: number;
  ItemPrice: number;
  RowTotal: number;
  ProductURL: string;
  ImageURL: string;
  ProductCategories: string[];
};

const createKlaviyoProducts = (
  products: ShopifyLineEdges[]
): KlaviyoCartItem[] => {
  const klaviyoProducts = products.map(product => {
    const { node } = product;
    const { merchandise } = node;
    const regPrice = parseFloat(merchandise.priceV2.amount);
    const discount = parseFloat(
      node.discountAllocations[0]?.discountedAmount?.amount ?? '0'
    );
    const productQuant = node.quantity || 1;
    const discountPrice = regPrice - discount / productQuant;

    const klaviyoProduct = {
      ProductID: merchandise.product.id?.replace('gid://shopify/Product/', ''),
      VariantID: merchandise.id?.replace('gid://shopify/ProductVariant/', ''),
      SKU: merchandise.sku,
      ProductName: merchandise.product.title,
      Quantity: productQuant,
      ItemPrice: discountPrice,
      RowTotal: discountPrice * productQuant,
      ProductURL: typeof window !== 'undefined' ? window.location.href : '',
      ImageURL: merchandise.image?.url ?? '',
      ProductCategories: [merchandise.product.productType],
    };
    return klaviyoProduct;
  });
  return klaviyoProducts;
};

const trackAddToCartKlaviyo = ({
  cart,
  remove = false,
  removeId,
  addedIds,
  lastCart,
}: TrackAddToCartArgs): void => {
  if (cart) {
    let items = createKlaviyoProducts(cart.lines.edges);

    const allCartItems = createKlaviyoProducts(cart.lines.edges);

    if (addedIds) {
      const ids = addedIds.map(
        i => `gid://shopify/ProductVariant/${i.shopify_variants_id}`
      );
      const addedLines = cart.lines.edges.filter(e =>
        ids.includes(e.node.merchandise.id)
      );
      items = createKlaviyoProducts(addedLines);
    }

    if (remove) {
      const removedItem = lastCart.lines.edges.find(
        e => e.node.id === removeId
      );
      items = createKlaviyoProducts([removedItem]);
    }

    const event = remove ? 'Removed from Cart' : 'Added to Cart';

    const klaviyoEvent = {
      $value: parseFloat(cart.estimatedCost.subtotalAmount.amount),
      AddedItemProductName: items[0].ProductName,
      AddedItemProductID: items[0].ProductID,
      AddedItemSKU: items[0].SKU,
      AddedItemCategories: items[0].ProductCategories,
      AddedItemImageURL: items[0].ImageURL,
      AddedItemURL: items[0].ProductURL,
      AddedItemPrice: items[0].ItemPrice,
      AddedItemQuantity: items[0].Quantity,
      ItemNames: allCartItems.map(item => item.ProductName),
      CheckoutURL: cart.checkoutUrl,
      Items: allCartItems,
    };

    if (typeof window !== 'undefined' && window.klaviyo) {
      window.klaviyo.push(['track', event, klaviyoEvent]);
    }
  }
};

export default trackAddToCartKlaviyo;

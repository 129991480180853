import { ShopifyCart } from '@framework/api/cart';

const getCartCount = (cart: ShopifyCart): number => {
  const noBuildItems =
    cart?.lines?.edges?.filter(
      i => !i?.node?.attributes?.some(a => a.key === '_build_number')
    ) ?? [];

  const warrItems =
    cart?.lines?.edges?.filter(
      i =>
        i?.node?.attributes?.some(a => a.key === '_build_number') &&
        i?.node?.merchandise?.title?.includes('Extend Protection Plan')
    ) ?? [];

  const items = [...noBuildItems, ...warrItems];

  const itemQtys = items.map(n => n?.node?.quantity ?? 0);

  const itemCount =
    itemQtys.length > 0
      ? itemQtys.reduce((prev, current) => prev + current, 0)
      : 0;

  const buildItems =
    cart?.lines?.edges?.filter(i =>
      i?.node?.attributes?.some(a => a.key === '_build_number')
    ) ?? [];

  const buildNumbers = [
    ...new Set(
      buildItems.map(
        i =>
          i?.node?.attributes?.find(a => a.key === '_build_number').value ??
          null
      )
    ),
  ];

  const buildQtys = {};

  buildNumbers.forEach(buildNo => {
    const bld = buildItems.find(b =>
      b?.node?.attributes?.find(
        a => a.key === '_build_number' && a.value === buildNo
      )
    );

    buildQtys[buildNo] = bld?.node?.quantity ?? 0;
  });

  const buildCount = Object.keys(buildQtys)
    .map(k => buildQtys[k])
    .reduce((prev, current) => prev + current, 0);

  return itemCount + buildCount;
};

export default getCartCount;

import { useMemo } from 'react';
import regionFeatures from '../../config/regionFeatures';

type Props = {
  cartButton: boolean;
  signIn: boolean;
  support: boolean;
  zendeskChat: boolean;
};

const useRegionFeatures = (region: string): Props => {
  const visibility = useMemo(() => {
    const defaultVisibility = regionFeatures.default;
    const regionVisibility = regionFeatures[region] || {};

    return {
      ...defaultVisibility,
      ...regionVisibility,
    };
  }, [region]);

  return visibility;
};

export default useRegionFeatures;
